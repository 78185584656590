const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAIL = 'AUTH_FAIL';
const AUTH_LOGOUT: string = 'AUTH_LOGOUT';

const SET_AUTH_REDIRECT_PATH: string = 'SET_AUTH_REDIRECT_PATH';

const PRODUCERS_LIST: string = 'GET_PRODUCERS';
const PRODUCERS_FETCH_START: string = 'PRODUCERS_LIST_START';
const PRODUCERS_FETCH_SUCCESS: string = 'PRODUCERS_LIST_SUCCESS';
const PRODUCERS_FETCH_FAIL: string = 'PRODUCERS_LIST_FAIL';
const PRODUCER_SELECTED: string = 'PRODUCER_SELECTED';
const PRODUCER_REMOVE_SELECTED: string = 'PRODUCER_REMOVE_SELECTED';

const PRODUCERS_SYNC_START: string = 'PRODUCERS_SYNC_START';
const PRODUCERS_SYNC_SUCCESS: string = 'PRODUCERS_SYNC_SUCCESS';
const PRODUCERS_SYNC_FAIL: string = 'PRODUCERS_SYNC_FAIL';
const PRODUCER_SYNC_OFFLINE: string = 'PRODUCER_SYNC_OFFLINE';
const COASCHEMA_SYNC_OFFLINE: string = 'COASCHEMA_SYNC_OFFLINE';
const LATEST_MARKETPRICE_SYNC_OFFLINE: string =
	'LATEST_MARKETPRICE_SYNC_OFFLINE';
const DELETE_OFFLINE_VERSIONS: string = 'DELETE_OFFLINE_VERSIONS';
const PRODUCER_SYNC_PROGRESS_OFFLINE: string = 'PRODUCER_SYNC_PROGRESS_OFFLINE';

const COA_FETCH_START: string = 'COA_FETCH_START';
const COA_FETCH_FAIL: string = 'COA_FETCH_FAIL';
const COA_FETCH_SUCCESS: string = 'COA_FETCH_SUCCESS';
const COA_SCHEMA_SELECTED: string = 'COA_SCHEMA_SELECTED';
const COA_SCHEMA_REMOVE_SELECTED: string = 'COA_SCHEMA_REMOVE_SELECTED';
const COA_SAVE_DATA: string = 'COA_SAVE_DATA';
const COA_RESET_RM: string = 'COA_RESET_RM';
const COA_RESET_COP: string = 'COA_RESET_COP';
const COA_SAVE_DATA_OFFLINE: string = 'COA_SAVE_DATA_OFFLINE';
const COA_SAVE_DATA_OFFLINE_UPDATE: string = 'COA_SAVE_DATA_OFFLINE_UPDATE';
const COA_RESET_DATA: string = 'COA_RESET_DATA';
const COA_UPDATE_RESET: string = 'COA_UPDATE_RESET';
const COA_SAVE_DATA_TO_DB: string = 'COA_SAVE_DATA_TO_DB';
const COA_SAVE_DATA_TO_DB_START: string = 'COA_SAVE_DATA_TO_DB_START';
const COA_ERROR_SAVE_DATA_TO_DB: string = 'COA_ERROR_SAVE_DATA_TO_DB';
const COA_SCHEMA_MAAPED_WITH_DATA: string = 'COA_SCHEMA_MAAPED_WITH_DATA';
const COA_DATA_CHANGED: string = 'COA_DATA_CHANGED';
const ENTRY_FORM_ERROR: string = 'ENTRY_FORM_ERROR';
const COA_SCHEMA_CALCULATION_CHANGED: string = 'COA_SCHEMA_CALCULATION_CHANGED';

const MARKET_PRICE_REMOVE_SELECTED: string = 'MARKET_PRICE_REMOVE_SELECTED';
const MARKET_PRICE_LIST: string = 'MARKET_PRICE_LIST';
const MARKET_FETCH_SUCCESS: string = 'MARKET_FETCH_SUCCESS';
const MARKET_FETCH_FAIL: string = 'MARKET_FETCH_FAIL';
const MARKET_FETCH_START: string = 'MARKET_FETCH_START';

const PREPARE_DATAFRAME_MARKET_PRICE = 'PREPARE_DATAFRAME_MARKET_PRICE';
const EDIT_MARKET_PRICE = 'EDIT_MARKET_PRICE';
const ADD_MARKET_PRICE = 'ADD_MARKET_PRICE';
const EDIT_MARKET_FINALSYMBOL_STATUS = 'EDIT_MARKET_FINALSYMBOL_STATUS';
const EDIT_MARKET_SETTLEMENT_DATE_MONTH = 'EDIT_MARKET_SETTLEMENT_DATE_MONTH';

const SAVE_MARKET_PRICE_STARTS = 'SAVE_MARKET_PRICE_STARTS';
const SAVE_MARKET_PRICE_COMPLETE = 'SAVE_MARKET_PRICE_COMPLETE';
const SAVE_MARKET_PRICE_STAUS_RESET = 'SAVE_MARKET_PRICE_STAUS_RESET';
const SAVE_MARKET_PRICE_ERROR = 'SAVE_MARKET_PRICE_ERROR';

const SHOW_MESSAGE: string = 'SHOW_MESSAGE';
const HIDE_MESSAGE: string = 'HIDE_MESSAGE';

export const REHYDRATE_STORE = 'REHYDRATE_STORE';
export const SIDEBAR_COLLAPSE = 'SIDEBAR_COLLAPSE';
export const SIDEBAR_EXPAND = 'SIDEBAR_EXPAND';

const COP_SAVE_PDF_PRINTDATA = 'SAVE_PDF_PRINTDATA';
const COP_GET_PDF_PRINTDATA = 'GET_PDF_PRINTDATA';

const SELECT_PRODUCER_YEAR = 'SELECT_PRODUCER_YEAR';
const CLEAR_PRODUCER_YEAR = 'CLEAR_PRODUCER_YEAR';

const MULTI_YEAR_API_FETCH = 'MULTI_YEAR_API_FETCH';
const MULTI_YEAR_API_SUCCESS = 'MULTI_YEAR_API_SUCCESS';
const MULTI_YEAR_API_FAIL = 'MULTI_YEAR_API_FAIL';
const MULTI_YEAR_API_ADD_DATA = 'MULTI_YEAR_API_ADD_DATA';
const MULTI_YEAR_API_ADD_RESET = 'MULTI_YEAR_API_ADD_RESET';

const MAD_CAP_FETCH_START = 'MADCAP_FETCH_START';
const MAD_CAP_API_SUCCESS = 'MADCAP_API_SUCCESS';
const MAD_CAP_API_RESET = 'MAD_CAP_API_RESET';

export const RM_POSITION_DATA_FROM_MADCAP = 'RM_POSITION_DATA_FROM_MADCAP';
export const RM_POSITION_DATA_UPDATE = 'RM_POSITION_DATA_UPDATE';
export const RM_POSTION_DATA_COLLAR_COUNT = 'RM_POSTION_DATA_COLLAR_COUNT';
export const RM_POSITION_UPDATE_MARKETPRICE = 'RM_POSTION_UPDATE_MARKETPRICE';
export const Position_DICTIONARY = 'Position_DICTIONARY';
export const RM_POSITION_RECALCULATE_MONTH_YEAR =
	'RM_POSITION_RECALCULATE_MONTH_YEAR';
export const RM_POSITION_CALCULATE_ALL = 'RM_POSITION_CALCULATE_ALL';
export const RM_POSITION_DELETE = 'RM_POSITION_DELETE';
export const RM_ADD_NEW_POSITION = 'RM_ADD_NEW_POSITION';
export const RM_MOVE_POSITION_DICTIONARY_DATA =
	'RM_MOVE_POSITION_DICTIONARY_DATA';
export const RM_UPDATEVALIDATION_POSITION = 'RM_UPDATEVALIDATION_POSITION';
export const RM_POSITION_HEADER_UPDATE = 'RM_POSITION_HEADER_UPDATE';

export {
	MAD_CAP_API_SUCCESS,
	MAD_CAP_FETCH_START,
	MAD_CAP_API_RESET,
	MULTI_YEAR_API_ADD_RESET,
	MULTI_YEAR_API_ADD_DATA,
	MULTI_YEAR_API_FETCH,
	MULTI_YEAR_API_SUCCESS,
	MULTI_YEAR_API_FAIL,
	AUTH_START,
	AUTH_SUCCESS,
	AUTH_FAIL,
	AUTH_LOGOUT,
	SET_AUTH_REDIRECT_PATH,
	PRODUCERS_LIST,
	PRODUCERS_FETCH_START,
	PRODUCERS_FETCH_SUCCESS,
	PRODUCERS_FETCH_FAIL,
	PRODUCER_SELECTED,
	PRODUCER_REMOVE_SELECTED,
	PRODUCERS_SYNC_START,
	PRODUCERS_SYNC_SUCCESS,
	PRODUCER_SYNC_OFFLINE,
	PRODUCERS_SYNC_FAIL,
	COA_FETCH_START,
	COA_FETCH_FAIL,
	COA_SCHEMA_SELECTED,
	COA_SCHEMA_REMOVE_SELECTED,
	COA_SAVE_DATA,
	COA_RESET_DATA,
	COA_RESET_RM,
	COA_RESET_COP,
	COA_UPDATE_RESET,
	COA_SAVE_DATA_TO_DB,
	COA_SAVE_DATA_TO_DB_START,
	COA_ERROR_SAVE_DATA_TO_DB,
	COA_SCHEMA_MAAPED_WITH_DATA,
	COA_DATA_CHANGED,
	COA_FETCH_SUCCESS,
	MARKET_PRICE_REMOVE_SELECTED,
	MARKET_PRICE_LIST,
	MARKET_FETCH_SUCCESS,
	MARKET_FETCH_FAIL,
	MARKET_FETCH_START,
	COASCHEMA_SYNC_OFFLINE,
	COA_SAVE_DATA_OFFLINE,
	COA_SAVE_DATA_OFFLINE_UPDATE,
	LATEST_MARKETPRICE_SYNC_OFFLINE,
	ENTRY_FORM_ERROR,
	DELETE_OFFLINE_VERSIONS,
	SHOW_MESSAGE,
	HIDE_MESSAGE,
	PRODUCER_SYNC_PROGRESS_OFFLINE,
	COA_SCHEMA_CALCULATION_CHANGED,
	COP_GET_PDF_PRINTDATA,
	COP_SAVE_PDF_PRINTDATA,
	SELECT_PRODUCER_YEAR,
	CLEAR_PRODUCER_YEAR,
	PREPARE_DATAFRAME_MARKET_PRICE,
	EDIT_MARKET_PRICE,
	EDIT_MARKET_FINALSYMBOL_STATUS,
	EDIT_MARKET_SETTLEMENT_DATE_MONTH,
	SAVE_MARKET_PRICE_STARTS,
	SAVE_MARKET_PRICE_COMPLETE,
	SAVE_MARKET_PRICE_STAUS_RESET,
	SAVE_MARKET_PRICE_ERROR,
	ADD_MARKET_PRICE,
};
