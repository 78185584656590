import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import '../../../styles/components/loader.scss';

const loader = () => (
	<Grid
		container
		spacing={0}
		direction="column"
		alignItems="center"
		justifyContent="center"
	>
		<Grid item xs={6}>
			<Box display="flex" m={1} p={3}>
				<div data-testid="loader">
					<div className="lds-ripple">
						<div></div>
						<div></div>
					</div>
				</div>
			</Box>
		</Grid>
	</Grid>
);

export default loader;
