const DROPDOWN_TYPE = {
	DEFAULT: 'DEFAULT',
	ROUNDED: 'ROUNDED',
};
const DROPDOWN_ARROW_TYPE = {
	ARRROW: 'DEFAULT',
	NOARROW: 'NOARROW',
	ROUNDED_ARROW: 'ROUNDEDARROW',
};
export { DROPDOWN_TYPE, DROPDOWN_ARROW_TYPE };
