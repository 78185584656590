import { SHOW_MESSAGE, HIDE_MESSAGE } from './actions';

import { ActionCreator } from 'redux';
import { GenericSchema } from '../../schemas';

interface IShowMessageAction {
	type: string;
	payload: GenericSchema;
}
interface IHideMessageAction {
	type: string;
}

const hideMessage: ActionCreator<IHideMessageAction> = () => {
	return {
		type: HIDE_MESSAGE,
	};
};

const showMessage: ActionCreator<IShowMessageAction> = (
	msg: string,
	type: string = 'error',
	duration: number | null = 3000
) => {
	return {
		type: SHOW_MESSAGE,
		payload: { msg, type, duration },
	};
};

export { hideMessage, showMessage };
