import React, { useState, memo } from 'react';
import cx from 'classnames';
import './list-producer.scss';
import { StylesProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../schemas';
import { compareValues } from '../../utils';

interface IProp {
	defaultContent?: any;
	moreContent?: any;
	type?: string;
}

type Props = IProp;
function ListProducer(props: Props) {
	let [isMenuOpen, setMenu] = useState(false);
	const { defaultContent, moreContent, type } = props;

	const isOnline: Boolean = useSelector(
		(state: GlobalState) => state.offline.online
	);

	const toggleMenu = () => {
		const isMenuOpened = !isMenuOpen;
		setMenu(isMenuOpened);
	};

	return (
		<div className="tabcontainer" data-testid="producer-list">
			<div className={cx('defaultsection', { hoversection: isMenuOpen })}>
				<span
					className="menu-icon"
					data-testid="card-menu"
					onClick={toggleMenu}
				>
					{isOnline || type == 'PRODUCER_YEAR' ? (
						isMenuOpen ? (
							<CloseIcon className="closeIconColor"></CloseIcon>
						) : (
							<MoreVertIcon className="iconcolor"></MoreVertIcon>
						)
					) : (
						''
					)}
				</span>
				{defaultContent}
			</div>
			<div className={cx('more-section', { show: isMenuOpen })}>
				<span
					className="menu-icon"
					data-testid="card-menu1"
					onClick={toggleMenu}
				>
					{isOnline || type == 'PRODUCER_YEAR' ? (
						isMenuOpen ? (
							<CloseIcon className="closeIconColor"></CloseIcon>
						) : (
							<MoreVertIcon className="iconcolor"></MoreVertIcon>
						)
					) : (
						''
					)}
				</span>
				{moreContent}
			</div>
		</div>
	);
}

export default memo(ListProducer, compareValues);
