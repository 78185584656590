import { COP_GET_PDF_PRINTDATA, COP_SAVE_PDF_PRINTDATA } from './actions';

// Save PDF data
const copsavepdfdata = (data: any) => {
	return {
		type: COP_SAVE_PDF_PRINTDATA,
		data: data,
	};
};

const copgetpdfdata = () => {
	return {
		type: COP_GET_PDF_PRINTDATA,
	};
};

export { copsavepdfdata, copgetpdfdata };
