import { Select } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import {
	createTheme,
	MuiThemeProvider,
	Theme,
	withStyles,
} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classes from '../../../../styles/components/dropdown.module.scss';
import { ReactComponent as roundedDropdown } from './../../../../assets/images/arrow.svg';
import { DROPDOWN_ARROW_TYPE, DROPDOWN_TYPE } from './../../../constants';

declare var document: any;

const BootstrapInput = withStyles(theme => ({
	input: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #808078',
		fontSize: '15px',
		fontFamily: `Inter`,
		color: ' #38383B',
		padding: '14px 26px 15px 12px',
		minHeight: '60px',
		boxSizing: 'border-box',
		boxShadow: ' 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			borderRadius: '4px!important',
			border: '2px solid #004c73',
			// boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			color: ' #38383B  ',
			backgroundColor: '#fff',
		},
	},
}))(InputBase);
const BootstrapInput2 = withStyles(theme => ({
	root: {
		width: '100%',
		lineHeight: '1.61876em',
	},
	input: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #989898',
		borderRadius: '4px!important',
		color: ' #38383B',
		maxHeight: '30px',
		boxSizing: 'border-box',
		fontFamily: `Inter`,
		fontSize: '15px',
		'&:focus': {
			borderRadius: '4px!important',
			border: '2px solid #004c73',
			// boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			color: ' #38383B  ',
			backgroundColor: '#fff',
		},
		'&:acive': {
			border: '2px solid #004c73',
		},
	},
}))(InputBase);

const SearchBootstrapInput = withStyles(theme => ({
	root: {
		width: '100%',
		lineHeight: '1.61876em',
	},
	input: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		// border: '1px solid rgba(0,76,115, 0.3)',
		color: ' #38383B',
		minHeight: '40px',
		paddingTop: '7px!important',
		boxSizing: 'border-box',
		clipPath: 'polygon(0% 0%, 84% 0, 100% 50%, 84% 100%, 0% 100%)',
		fontFamily: `Inter`,
		fontSize: '15px',
		'&:focus': {
			border: '2px solid #004c73',
			// boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			color: ' #38383B  ',
			backgroundColor: '#fff',
		},
		'&:acive': {
			border: '2px solid #004c73',
		},
	},
}))(InputBase);
const SortBootstrapInput = withStyles(theme => ({
	root: {
		width: '100%',
		lineHeight: '1.61876em',
	},
	input: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid rgba(0,76,115, 0.3)',
		color: ' #38383B',
		minHeight: '42px',
		borderRadius: '4px!important',
		paddingTop: '7px!important',
		boxSizing: 'border-box',
		fontFamily: `Inter`,
		fontSize: '15px',
		'&:focus': {
			border: '2px solid #004c73',
			borderRadius: '4px!important',
			// boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			color: ' #38383B  ',
			backgroundColor: '#fff',
		},
		'&:acive': {
			border: '2px solid #004c73',
		},
	},
}))(InputBase);
const BootstrapInputdisabled = withStyles(theme => ({
	root: {
		width: '100%',
		lineHeight: '1.61876em',
	},
	input: {
		position: 'relative',
		backgroundColor: '#dbdbdb',
		border: '1px solid #808078',
		borderRadius: '4px!important',
		color: '#808078',
		maxHeight: '30px',
		boxSizing: 'border-box',
		fontFamily: `URWDINCond-Black`,
		opacity: '0.8',
	},
}))(InputBase);
const theme: Theme = createTheme({
	typography: {
		// fontFamily: `URWDINCond-Black`,
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		body2: {
			fontSize: 14,
		},
	},
	overrides: {
		MuiInputBase: {
			root: {
				width: '100%',
				lineHeight: '1.61876em',
			},
			input: {
				padding: 'unset!important',
			},
		},
		MuiSelect: {
			icon: {
				top: '50%',
				transform: 'translateY(-50%)',
				transition: 'all 0.4s ease',
			},
			iconOpen: {
				transform: 'translateY(-50%) rotate(180deg) !important',
			},
			select: {
				'&.MuiSelect-select': {
					paddingRight: '15px!important',
				},
			},
		},

		MuiPopover: {
			paper: {
				marginTop: '10px',
				overflow: 'visible !important',
				borderRadius: '20px',
				'&::after': {
					content: "''",
					position: 'absolute',
					top: '-12px',
					left: '40%',
					width: '0',
					height: '0',
					borderLeft: '12px solid transparent',
					borderRight: '12px solid transparent',
					borderBottom: '12px solid #ffffff	',
					transition: 'all 0.2s ease',
				},
				'&.first-selected': {
					'&::after': {
						borderBottom: '12px solid #004c73',
					},
				},
			},
		},

		MuiList: {
			padding: {
				paddingTop: '0px',
				paddingBottom: '0px',
			},
			root: {
				overflow: 'auto !important',
				maxHeight: '200px !important',
				borderRadius: '8px !important',
				backgroundColor: '#fff !important',
				boxShadow: '0px 3px 13px 0px rgb(0 0 0 / 17%)',
			},
		},
		MuiMenuItem: {
			root: {
				fontSize: '15px',
				fontFamily: 'Inter',
			},
		},
		MuiListItem: {
			gutters: {
				paddingLeft: '22px',
			},

			root: {
				'&:last-child': {
					borderBottomLeftRadius: '8px',
					borderBottomRightRadius: '8px',
				},
				'&:first-child': {
					borderTopLeftRadius: '8px',
					borderTopRightRadius: '8px',
				},
				minHeight: '35px !important',
				overflow: 'visible !important',
				'&:hover': {
					backgroundColor: 'rgba(152, 152, 152, 0.3)  ',
				},
				'&$selected': {
					backgroundColor: '#004c73 ',
					color: '#fff',
					'&:first-child': {
						position: 'relative',
						'&::after': {
							content: "''",
							position: 'absolute',
							borderBottom: '12px solid #004c73',
							transition: 'all 0.2s ease',
						},
					},
				},
				'&$selected:hover': {
					backgroundColor: '#004c73 ',
				},
				selected: {},
			},
		},
	},
});

const getRandomID = () => {
	return '_' + Math.random().toString(36).substr(2, 9);
};

interface IProp extends RouteComponentProps<any> {
	options: Array<any>;
	selected: any;
	onSelected(val: string): string;
	arrow: any;
	disabled?: boolean;
	isSearchDropDown?: boolean;
	isSortDropDown?: boolean;
}

interface IState {
	label: '';
	value: '';
	selected: '';
}

type Props = IProp & any;
class DropDown extends PureComponent<Props, any> {
	state = {
		options: [],
		selected: null,
	};

	// make tool tip active
	makeTooltipActive = () => {
		const activeOption = document
			?.querySelector('.MuiPopover-paper  li:first-child')
			?.classList.contains('Mui-selected');
		if (activeOption) {
			document
				?.querySelector('.MuiPopover-paper')
				?.classList.add('first-selected');
		} else {
			document
				?.querySelector('.MuiPopover-paper')
				?.classList.remove('first-selected');
		}
	};

	componentDidMount() {
		const options = this.props.options;
		if (options.length > 0) {
			this.setState({ options: options, selected: options[0] });
		}
	}

	componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
		if (this.props?.selected && this.props.selected !== prevState.selected) {
			this.setState({ selected: this.props.selected });
			window.setTimeout(() => this.makeTooltipActive(), 200);
		}
	}
	changeDropDown = (event: React.ChangeEvent<any>) => {
		const selVal = event.target['value'];
		this.setState({ selected: selVal });
		if (this.props?.onSelected) {
			this.props.onSelected(selVal);
		}
	};

	render() {
		const { selected, options } = this.state;
		const optionList = options.map((item: any, key: number) => {
			return (
				<MenuItem key={item} value={item}>
					{item}
				</MenuItem>
			);
		});
		return (
			<>
				<MuiThemeProvider theme={theme}>
					<div className={classes.dropdowncontainer}>
						<Select
							id={getRandomID()}
							data-testid="selectComp"
							IconComponent={
								this.props.arrow === DROPDOWN_ARROW_TYPE.NOARROW
									? 'a'
									: this.props.arrow === DROPDOWN_ARROW_TYPE.ROUNDED_ARROW
									? roundedDropdown
									: KeyboardArrowDownIcon
							}
							MenuProps={{
								getContentAnchorEl: null,
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left',
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left',
								},
							}}
							disabled={this.props.disabled}
							onChange={this.changeDropDown.bind(this)}
							value={selected || ''}
							onOpen={() => {
								window.setTimeout(() => this.makeTooltipActive(), 0);
							}}
							input={
								this.props.type === DROPDOWN_TYPE.ROUNDED ? (
									this.props.disabled ? (
										<BootstrapInputdisabled />
									) : !this.props.isSearchDropDown ? (
										<BootstrapInput2 />
									) : this.props.isSortDropDown ? (
										<SortBootstrapInput />
									) : (
										<SearchBootstrapInput />
									)
								) : !this.props.isSearchDropDown ? (
									<BootstrapInput2 />
								) : this.props.isSortDropDown ? (
									<SortBootstrapInput />
								) : (
									<SearchBootstrapInput />
								)
							}
						>
							{optionList}
						</Select>
					</div>
				</MuiThemeProvider>
			</>
		);
	}
}
export default DropDown;
