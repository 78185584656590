export interface UserAccess {
	function: string;
	authorization: string;
}
export interface UserAuthorization {
	milkshed: string;
	access: UserAccess;
}
export class User {
	name: string = '';
	fname: string = '';
	lname: string = '';
	mail: string = '';
	role: string = '';
}
