import React, { FC, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps {
	children: ReactNode | Element;
}

const CardContent: FC<Props> = (props: Props) => {
	return <>{props.children}</>;
};

export default withRouter(CardContent);
