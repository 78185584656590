import React, { memo } from 'react';
import './list-producer.scss';
import { StylesProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import { compareValues } from '../../utils';

const theme: Theme = createTheme({
	overrides: {
		MuiGrid: {
			item: {
				backgroundColor: 'transparent',
			},
		},
		MuiSvgIcon: {
			root: {
				fill: '#808078',
			},
		},
	},
});

function ListProducerLoader() {
	return (
		<MuiThemeProvider theme={theme}>
			<StylesProvider>
				<div
					className="tabcontainer"
					id="list-producer-loader"
					data-testid="producer-list-loader"
				>
					<div className="defaultsection">
						<span className="menu-icon">
							<MoreVertIcon className="iconcolor"></MoreVertIcon>
						</span>
						<>
							<div className="loadercontainer">
								<div className="cardcontent">
									<div>
										<Skeleton animation="wave" variant="text" width="50%" />
									</div>
									<div>
										<Skeleton animation="wave" variant="text" width="70%" />
									</div>
									<div>
										<Skeleton animation="wave" variant="text" />
									</div>
									<div>
										<Skeleton animation="wave" variant="rect" height={130} />
									</div>
								</div>
							</div>
						</>
					</div>
				</div>
			</StylesProvider>
		</MuiThemeProvider>
	);
}

export default memo(ListProducerLoader, compareValues);
