import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { User } from '../../schemas';
import { AUTH_API, AUTH_CONFIG } from './../../common/configs';
import {
	AUTH_EXPIRATION,
	AUTH_TOKEN,
	MSG_TYPE,
	REFRESH_TOKEN,
	USER_INFO,
} from './../../common/constants/constants';
import { http, localStore } from './../../common/services';
import {
	decodeJWT,
	parseJSON,
	stringifyJSON,
} from './../../common/utils/utility';
import {
	AUTH_FAIL,
	AUTH_LOGOUT,
	AUTH_START,
	AUTH_SUCCESS,
	SET_AUTH_REDIRECT_PATH,
} from './actions';
import { showMessage } from './message-notification';

interface IAuthAction {
	type: string;
}
interface IAuthSuceessAction extends IAuthAction {
	idToken: string;
	userInfo: User;
}

interface IAuthErrorAction extends IAuthAction {
	error: AxiosError;
}

interface IAuthRedirectAction extends IAuthAction {
	path: string;
}

const authStart: ActionCreator<IAuthAction> = () => {
	return {
		type: AUTH_START,
	};
};

const authSuccess: ActionCreator<IAuthSuceessAction> = (
	token: string,
	user: User
) => {
	return {
		type: AUTH_SUCCESS,
		idToken: token,
		userInfo: user,
	};
};

const authFail: ActionCreator<IAuthErrorAction> = (error: AxiosError) => {
	return {
		type: AUTH_FAIL,
		error: error,
	};
};

const authLogout: ActionCreator<IAuthAction> = () => {
	return {
		type: AUTH_LOGOUT,
	};
};

// logout
const logout = () => {
	//logout from SSO
	const AUTH_TOKEN_VAL = localStore.get(AUTH_TOKEN) || '';

	if (AUTH_TOKEN_VAL) {
		http
			.get(AUTH_API.LOGOUT())
			.then((response: AxiosResponse) => {})
			.catch((err: AxiosError) => {});
		window.setTimeout(() => window.open(AUTH_CONFIG.LOGOUT, '_self'), 500);
	}

	localStore.remove(AUTH_TOKEN);
	localStore.remove(AUTH_EXPIRATION);
	localStore.remove(USER_INFO);
	localStore.remove(REFRESH_TOKEN);

	return {
		type: AUTH_LOGOUT,
	};
};

// auth time out after given second
const checkAuthTimeout: ActionCreator<any> = (expirationTime: number) => {
	return (dispatch: Dispatch) => {
		updateRefreshToken(dispatch);
		window.setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	};
};

let intervalID: number | null = null;
const FETCH_REFRESH_TOKEN_IN_MIN = 60; // time after fetch refresh token
const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 15; // 15 min
const updateRefreshToken = (dispatch: Dispatch) => {
	if (intervalID) {
		clearInterval(intervalID);
		intervalID = null;
	}
	intervalID = window.setInterval(() => {
		const expiration = localStore.get(AUTH_EXPIRATION);
		if (expiration) {
			const expirationDate = new Date(
				localStore.get(AUTH_EXPIRATION)
			).getTime();
			const currentDate = new Date().getTime();
			const diff: number = expirationDate - currentDate;
			const diffinMin = Math.round(diff / 1000 / 60);
			if (diffinMin <= FETCH_REFRESH_TOKEN_IN_MIN) {
				const msg =
					diffinMin > 0
						? `Your session will expire in ${diffinMin} minutes.Please save your work and log back in to continue.`
						: `Your session is expired. Please login again to continue.`;
				dispatch(showMessage(msg, MSG_TYPE.ERROR, null));
			}
		}
	}, REFRESH_TOKEN_INTERVAL);
};

// get token
const auth: ActionCreator<ThunkAction<Promise<any>, any, any, any>> = (
	code: string
) => {
	return (dispatch: Dispatch) => {
		dispatch(authStart());

		// SSO Authorization Token
		const AUTH_URL = AUTH_API.AUTHORIZATION(code);
		return http
			.get(AUTH_URL)
			.then((response: AxiosResponse) => {
				const res = response.data;
				const expirationDate = new Date(
					new Date().getTime() + res.expires_in * 1000
				);

				localStore.set(AUTH_TOKEN, res.access_token);
				localStore.set(REFRESH_TOKEN, res.refresh_token);
				localStore.set(AUTH_EXPIRATION, expirationDate);

				const user = decodeJWT(res.access_token);
				const userInfo: User = {
					name: `${user.fname} ${user.lname}`,
					fname: user.fname,
					lname: user.lname,
					mail: user.mail,
					role: res.role,
				};

				localStore.set(USER_INFO, stringifyJSON(userInfo));

				dispatch(authSuccess(res.access_token, userInfo));
				dispatch(checkAuthTimeout(res.expires_in));
			})
			.catch((err: AxiosError) => {
				dispatch(authFail(err));
			});
	};
};

// set redirect path
const setAuthRedirectPath: ActionCreator<IAuthRedirectAction> = (
	path: string
) => {
	return {
		type: SET_AUTH_REDIRECT_PATH,
		path: path,
	};
};

//check auth state for token is valid
const authCheckState = () => {
	return (dispatch: Dispatch) => {
		const token = localStore.get(AUTH_TOKEN);
		if (!token) {
			dispatch(logout());
		} else {
			const expirationDate = new Date(localStore.get(AUTH_EXPIRATION));
			if (expirationDate <= new Date()) {
				dispatch(logout());
			} else {
				const userInfo = localStore.get(USER_INFO)
					? parseJSON(localStore.get(USER_INFO))
					: '';
				dispatch(authSuccess(token, userInfo));

				dispatch(
					checkAuthTimeout(
						(expirationDate.getTime() - new Date().getTime()) / 1000
					)
				);
			}
		}
	};
};

export {
	authStart,
	authSuccess,
	authFail,
	logout,
	checkAuthTimeout,
	auth,
	setAuthRedirectPath,
	authCheckState,
};
