import {
	PRODUCER_SYNC_OFFLINE,
	COASCHEMA_SYNC_OFFLINE,
	PRODUCERS_SYNC_START,
	PRODUCERS_SYNC_SUCCESS,
	PRODUCERS_SYNC_FAIL,
	COA_SAVE_DATA_OFFLINE,
	COA_SAVE_DATA_OFFLINE_UPDATE,
	LATEST_MARKETPRICE_SYNC_OFFLINE,
	DELETE_OFFLINE_VERSIONS,
	PRODUCER_SYNC_PROGRESS_OFFLINE,
} from '../actions/actions';
import {
	updateObject,
	formatDate,
	sortArray,
} from '../../common/utils/utility';
import {
	ActionSchema,
	SyncOffline,
	Producer,
	GenericSchema,
	ProducerYear,
	CostOfProduction,
	RiskManagement,
} from '../../schemas';
import * as _ from 'lodash';
import { SCHEMA_TYPE } from '../../common/constants';
import { store } from './../index';

const initialState: SyncOffline = {};
const reducer = (state = initialState, action: ActionSchema) => {
	switch (action.type) {
		case PRODUCERS_SYNC_START:
			return updateObject(state, { loading: true, syncProgress: '' });
		case PRODUCERS_SYNC_SUCCESS:
			return updateObject(state, { loading: false, syncProgress: '' });
		case PRODUCERS_SYNC_FAIL:
			return updateObject(state, { loading: false });
		case PRODUCER_SYNC_OFFLINE:
			return updateObject(state, syncProducer(state, action));
		case COASCHEMA_SYNC_OFFLINE:
			return updateObject(state, syncCOASchema(state, action));
		case COA_SAVE_DATA_OFFLINE:
			return updateObject(state, saveDataOffline(state, action));
		case COA_SAVE_DATA_OFFLINE_UPDATE:
			return updateObject(state, updateDataOffline(state, action));
		case LATEST_MARKETPRICE_SYNC_OFFLINE:
			return updateObject(state, syncMarketPrice(state, action));
		case DELETE_OFFLINE_VERSIONS:
			return updateObject(state, deleteOfflineVersions(state, action));
		case PRODUCER_SYNC_PROGRESS_OFFLINE:
			return updateObject(state, { syncProgress: action.payload });
		default:
			return state;
	}
};

//delete all offline versions
function deleteOfflineVersions(state = initialState, action: ActionSchema) {
	const { producerIdentifier, year, key } = action.payload;

	const list = state[key]?.list || [];
	// delete offline record to existing producer and year
	list.forEach((producer: Producer) => {
		producer.versions.forEach((pyear: ProducerYear, index: number) => {
			if (
				String(producer.producerIdentifier) == String(producerIdentifier) &&
				String(pyear.year) == String(year)
			) {
				pyear.costOfProductionVersions = [];
				pyear.riskMarketingVersions = [];
				//delete year
				_.remove(producer.versions, { year: year });
			}
		});
	});

	// delete producer
	list.forEach((producer: Producer, index: number) => {
		if (
			producer?.versions?.length === 0 &&
			String(producer.producerIdentifier) == String(producerIdentifier)
		) {
			_.remove(list, { producerIdentifier: producerIdentifier });
		}
	});

	//assiging to user state
	state[key] = updateObject(state[key], {
		list: _.compact(aggregateProducer(list)),
	});

	return state;
}

//sync Market Price
function syncMarketPrice(state = initialState, action: ActionSchema) {
	const { key, marketPriceList } = action.payload;
	//assiging to user state
	state[key] = updateObject(state[key], {
		marketPriceList: marketPriceList,
	});

	return state;
}

//sync COA schema
function syncCOASchema(state = initialState, action: ActionSchema) {
	const { versionIdentifier } = action.payload;

	const list = state?.schemaList || [];
	let updateList = state?.schemaList || [];

	// checking if it is new schema
	const isSchemaExist = list.filter(
		(item: GenericSchema) =>
			item?.showChartOfAccounts?.versionIdentifier == versionIdentifier
	);

	if (isSchemaExist?.length > 0) {
		//  adding and getting merged with existing schema
		const incoming = action.payload;
		updateList = list.map((existingItem: GenericSchema) => {
			// check the schema and update it
			if (
				existingItem?.showChartOfAccounts?.versionIdentifier ===
				incoming?.showChartOfAccounts?.versionIdentifier
			) {
				existingItem = incoming;
			}

			return existingItem;
		});
	} else {
		// add if it is a new schema
		updateList = _.concat(list, action.payload);
	}

	//assiging to user state
	state = updateObject(state, {
		schemaList: _.compact(updateList),
	});

	return state;
}

// sync producer
function syncProducer(state = initialState, action: ActionSchema) {
	const { key, producerIdentifier } = action.payload;
	state[key] = state[key] ? state[key] : { list: [] };

	const list = state[key]?.list || [];
	let updateList = state[key]?.list || [];

	// checking if it is new producer
	const isProducerExist = _.filter(list, {
		producerIdentifier: producerIdentifier,
	});
	if (isProducerExist?.length > 0) {
		//  adding and getting merged with existing producer
		const incoming = action.payload;
		const updateProducer = list.map((existingItem: Producer) => {
			// check the producer and update it
			if (existingItem.producerIdentifier === incoming.producerIdentifier) {
				existingItem = incoming;
			}

			return existingItem;
		});

		// filter producer which have  selected any one of the version(years)
		updateList = updateProducer.filter(
			(item: Producer) => item.versions?.length > 0
		);
	} else {
		// add if it is a new producer
		updateList = _.concat(list, action.payload);
	}
	//assiging to user state
	state[key] = updateObject(state[key], {
		list: _.compact(updateList),
	});

	return state;
}

// sync producer
function saveDataOffline(state = initialState, action: ActionSchema) {
	const { year, producerIdentifier, type, key, record } = action.payload;
	state[key] = state[key] ? state[key] : { list: [] };

	const list = state[key]?.list;
	let isYearAvailable: boolean = false;

	// add record to existing producer and year
	list.forEach((producer: Producer) => {
		producer.versions.forEach((pyear: ProducerYear) => {
			if (
				String(producer.producerIdentifier) == String(producerIdentifier) &&
				String(pyear.year) == String(year)
			) {
				if (type === SCHEMA_TYPE.COP) {
					const version = _.concat(pyear.costOfProductionVersions, [record]);
					pyear.costOfProductionVersions = version;
				} else if (type === SCHEMA_TYPE.RM) {
					const version = _.concat(pyear.riskMarketingVersions, [record]);
					pyear.riskMarketingVersions = version;
				}
				isYearAvailable = true;
			}
		});
	});

	// if it is new year add a new year
	if (!isYearAvailable) {
		list.forEach((producer: Producer) => {
			if (String(producer.producerIdentifier) == String(producerIdentifier)) {
				const yearObj: any = {
					year: year,
					costOfProductionVersions: type === SCHEMA_TYPE.COP ? [record] : [],
					riskMarketingVersions: type === SCHEMA_TYPE.RM ? [record] : [],
				};
				const version = _.concat(producer.versions, [yearObj]);
				producer.versions = version;
			}
		});
	}

	//assiging to user state
	state[key] = updateObject(state[key], {
		list: _.compact(aggregateProducer(list)),
	});

	return state;
}

function aggregateProducer(list: Array<Producer>): Array<Producer> {
	list.forEach((producer: Producer) => {
		producer.versions.forEach((p: ProducerYear) => {
			const coplastupdatedval = sortArray(
				p?.costOfProductionVersions,
				'modifiedDate',
				true
			);
			const rmlastupdatedval = sortArray(
				p?.riskMarketingVersions,
				'modifiedDate',
				true
			);

			if (coplastupdatedval.length > 0) {
				p.coplastupdated = formatDate(coplastupdatedval[0]?.modifiedDate);
				p.copstatus = coplastupdatedval[0]?.status;
				p.lastupdatedType = 'cop';
				p.rmlastCopVersion = coplastupdatedval[0]?.version;
				p.rmlastCoaVersion = coplastupdatedval[0]?.chartOfAccountsVersion;
				p.rmlastUpdatedVersion = 0;
			}

			if (rmlastupdatedval.length > 0) {
				p.rmlastupdated = formatDate(
					rmlastupdatedval[0]?.modifiedDate
				).toLocaleString();
				p.rmstatus = rmlastupdatedval[0]?.status;
				p.rmlastCopVersion =
					coplastupdatedval.length > 0 ? coplastupdatedval[0]?.version : 0;
				p.rmlastCoaVersion =
					coplastupdatedval.length > 0
						? coplastupdatedval[0]?.chartOfAccountsVersion
						: 0;
				p.rmlastUpdatedVersion = rmlastupdatedval[0]?.version;
				p.lastupdatedType = 'rm';
			}

			const allversionsConcated = _.chain(coplastupdatedval)
				.concat(rmlastupdatedval)
				.value();

			const allversions = sortArray(allversionsConcated, 'modifiedDate', true);

			if (allversions?.length > 0) {
				p.lastupdated = formatDate(allversions[0]?.modifiedDate);
				p.lastupdatedVersion = allversions[0]?.version;
				p.lastupdatedVersionIdentifier = allversions[0]?.version;
				p.lastupdatedSchemaIdentifier = allversions[0]?.chartOfAccountsVersion;
				p.status = allversions[0]?.status;
			}
		});
	});
	return list;
}

// update offline
function updateDataOffline(state = initialState, action: ActionSchema) {
	const {
		year,
		producerIdentifier,
		type,
		versionIdentifier,
		serverIdentifier,
		key,
	} = action.payload;

	const list = state[key]?.list || [];
	// add record to existing producer and year
	list.forEach((producer: Producer) => {
		producer.versions.forEach((pyear: ProducerYear) => {
			if (
				String(producer.producerIdentifier) == String(producerIdentifier) &&
				String(pyear.year) == String(year)
			) {
				if (type === SCHEMA_TYPE.COP) {
					pyear.costOfProductionVersions = [];
					pyear.riskMarketingVersions.forEach(
						(rm: RiskManagement, index: number) => {
							if (
								Number(rm.costOfProductionVersion) == Number(versionIdentifier)
							) {
								rm.costOfProductionVersion = serverIdentifier;
							}
						}
					);
				} else if (type === SCHEMA_TYPE.RM) {
					pyear.riskMarketingVersions = [];
				}
			}
		});
	});

	// remove year
	list.forEach((producer: Producer) => {
		producer.versions.forEach((pyear: ProducerYear, index: number) => {
			if (
				String(producer.producerIdentifier) == String(producerIdentifier) &&
				String(pyear.year) == String(year) &&
				pyear.costOfProductionVersions?.length === 0 &&
				pyear.riskMarketingVersions?.length === 0
			) {
				_.remove(producer.versions, { year: year });
			}
		});
	});

	// remove producer
	list.forEach((producer: Producer, index: number) => {
		if (
			producer?.versions?.length === 0 &&
			String(producer.producerIdentifier) == String(producerIdentifier)
		) {
			_.remove(list, { producerIdentifier: producerIdentifier });
		}
	});

	//assiging to user state
	state[key] = updateObject(state[key], {
		list: _.compact(aggregateProducer(list)),
	});

	return state;
}

export default reducer;
