class DocumentReference {
	typeCode: string = '';
	identifier: string;
	constructor(typeCode: string, identifier: string) {
		this.typeCode = typeCode;
		this.identifier = identifier;
	}
}

class Data {
	content: number = 0;
	typeCode: string = '';
	constructor(content: number, typeCode: string) {
		this.content = content;
		this.typeCode = typeCode;
	}
}

class Code {
	content: string = '';
	typeCode: string = '';
	constructor(content: string, typeCode: string) {
		this.content = content;
		this.typeCode = typeCode;
	}
}

class Identifier {
	identifier: number;
	constructor(id: number) {
		this.identifier = id;
	}
}

class ExtensionSchema {
	typeCode: string = '';
	amount: Array<Data> = [];
	code: Array<Code> = [];

	constructor(typeCode: string, amount: Array<Data>, code: Array<Code>) {
		this.typeCode = typeCode;
		this.amount = amount;
		this.code = code;
	}
}

export class DRP {
	typeCode: string = 'DRP';
	identifierSet: Identifier;
	quantity: Array<Data>;
	extension: ExtensionSchema;
	percentage: Data;

	constructor(typeCode: string, subTypeCode: string, identifier: number) {
		this.typeCode = typeCode;
		this.identifierSet = { identifier: identifier };
		this.quantity = [];
		this.extension = new ExtensionSchema(
			subTypeCode,
			[{ content: 0, typeCode: 'Amount' }],
			[]
		);

		this.percentage = new Data(0, 'Allocation Percent');
	}
}
