import * as _ from 'lodash';
import { GenericSchema, MarketPrice, PeriodSummary } from '../../schemas';
import { PositionContainer } from '../../schemas/Model/position.model';
import {
	ACTION_CODE,
	DEFAULT_LOCALE,
	ENVOIRMENT,
	FULL_MONTHS,
	INDICATOR_KEYS,
	INPUT_TYPE,
	MONTHS,
	RM_OPERATING_FORECAST_SECTION_CODES,
	RM_SECTION_CODES,
	RM_TYPECODES,
	START_YEAR,
	STORED_MEASURE_KEYS,
} from '../constants';

export const formatdata = (data: any, type: any) => {
	const getval = (value: any, precision: any) => {
		return Number(value).toLocaleString(DEFAULT_LOCALE, {
			useGrouping: true,
			maximumFractionDigits: precision,
			minimumFractionDigits: precision,
		});
	};
	let val = '';
	switch (type) {
		case INPUT_TYPE.NUMBER:
			val = getval(data, 0);
			break;
		case INPUT_TYPE.DECIMAL:
			val = getval(data, 2);
			break;
		case INPUT_TYPE.DECIMAL_4_PLACES:
			val = getval(data, 4);
			break;
		case INPUT_TYPE.DECIMAL_5_PLACES:
			val = getval(data, 5);
			break;
		case INPUT_TYPE.DECIMAL_3_PLACES:
			val = getval(data, 3);
			break;
		case INPUT_TYPE.DECIMAL_2_PLACES:
			val = getval(data, 2);
			break;
		case INPUT_TYPE.DECIMAL_1_PLACE:
			val = getval(data, 1);
			break;
		case INPUT_TYPE.PERCENT:
			val = getval(data, 2);
			break;
		default:
			val = '0';
	}
	return val.indexOf('-') === 0 ? val.replace('-', '(') + ')' : val;
};

const updateObject = (oldObject: any, updatedProperties: any) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

const getDayInMonth = (month: any, dayinyear: any) => {
	let dayinmonthdata = 30;
	if (month === 'February') {
		dayinmonthdata = 28;
		if (dayinyear === 366) {
			dayinmonthdata = 29;
		}
	} else if (['April', 'June', 'September', 'November'].includes(month)) {
		dayinmonthdata = 30;
	} else {
		dayinmonthdata = 31;
	}
	return dayinmonthdata;
};

const reducePositionListInfoDictionary = (positionList: []) => {
	return positionList.reduce((prev: any, item: any) => {
		var symbol = item.extension.code.find(
			(item1: any) => item1.typeCode == 'Commodity'
		).content;
		const key = `${item.extension.accountingPeriod[0].identifier}_${item.extension.accountingPeriod[0].year}`;
		if (prev[symbol]) {
			var pc = new PositionContainer();
			pc.fromJson(item);
			prev[symbol][key] = pc;
		} else {
			var pc1 = new PositionContainer();
			pc1.fromJson(item);
			prev[symbol] = {};
			prev[symbol][key] = pc1;
		}
		if (prev[symbol]['keys']) {
			prev[symbol]['keys'].push(key);
		} else {
			prev[symbol]['keys'] = [key];
		}
		return prev;
	}, {});
};

const getDayInYear = (year: any) => {
	year = parseInt(year);
	let dayinyear = 365;
	if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
		dayinyear = 366;
	}
	return dayinyear;
};

const mergeArray = (
	oldArr: Array<any>,
	updateArr: Array<any>,
	uniqueKey: string
): Array<any> => {
	const arr = _.unionBy(oldArr, updateArr, uniqueKey);
	return arr;
};

const stringifyJSON = (value: Object): string => {
	return JSON.stringify(value);
};
const parseJSON = (value: string) => {
	return JSON.parse(value);
};

const getRandomID = (): string => {
	return '_' + Math.random().toString(36).substr(2, 9);
};

const getQueryVariable = (queryString: string, variable: string): string => {
	const query = queryString.substring(1);
	const vars = query.split('&');
	let value = '';
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (pair[0] === variable) {
			value = pair[1];
		}
	}
	return value;
};

const formatDate = (date: string | Date): string => {
	const months = MONTHS;
	const d = new Date(date);
	const datestr = `${
		months[d.getMonth()]
	} ${d.getDate()}, ${d.getFullYear()} ${String(d.getHours()).padStart(
		2,
		'0'
	)}:${String(d.getMinutes()).padStart(2, '0')}`;
	return datestr;
};

const formatDatewithTimeFirst = (date: string | Date): string => {
	const months = MONTHS;
	const d = new Date(date);
	const datestr = `${String(d.getHours()).padStart(2, '0')}:${String(
		d.getMinutes()
	).padStart(2, '0')} on ${
		months[d.getMonth()]
	} ${d.getDate()}, ${d.getFullYear()}`;
	return datestr;
};

const formatDatewithFullMonth = (date: string | Date): string => {
	const months = FULL_MONTHS;
	const d = new Date(date);
	const datestr = `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
	return datestr;
};

const formatDatewithMMDDYYYY = (date: string | Date): string => {
	const d = new Date(date);
	const datestr = `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
	return datestr;
};
const formatDatewithMMDDYYYYTimeLast = (date: string | Date): string => {
	const d = new Date(date);
	const datestr = `${
		d.getMonth() + 1
	}/${d.getDate()}/${d.getFullYear()} @${String(d.getHours()).padStart(
		2,
		'0'
	)}:${String(d.getMinutes()).padStart(2, '0')}`;
	return datestr;
};
const formatDateForPriceCurve = (date: Date = new Date()): string => {
	const d = new Date(date);
	const month = String(d.getMonth() + 1).padStart(2, '0');
	const day = String(d.getDate()).padStart(2, '0');
	const year = d.getFullYear();

	return `${month}${day}${year}`;
};

const decodeJWT = (token: string) => {
	return JSON.parse(atob(token.split('.')[1]));
};

const getEnvoirment = (): string => {
	const host = window.location.host;
	let envoirment = '';
	if (host.indexOf('dairyrmi') > -1) {
		envoirment = ENVOIRMENT.PROD;
	} else if (host.indexOf('-dv') > -1) {
		envoirment = ENVOIRMENT.DEV;
	} else if (host.indexOf('-qa') > -1) {
		envoirment = ENVOIRMENT.QA;
	} else {
		envoirment = ENVOIRMENT.LOCAL;
	}
	return envoirment;
};

const minTwoDigits = (n: number): string => {
	return (n < 10 ? '0' : '') + n;
};

const toDecimalPlaces = (
	num: number,
	decimalPlaces: number,
	formatNegativeValue?: boolean
) => {
	const commas = num.toLocaleString(DEFAULT_LOCALE, {
		useGrouping: true,
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	});
	if (formatNegativeValue) {
		return commas.indexOf('-') === 0 ? commas.replace('-', '(') + ')' : commas;
	}
	return commas;
};

const toMaxDecimalPlaces = (
	num: number,
	decimalPlaces: number,
	formatNegativeValue?: boolean
) => {
	const commas = num.toLocaleString(DEFAULT_LOCALE, {
		useGrouping: true,
		maximumFractionDigits: decimalPlaces,
	});
	if (formatNegativeValue) {
		return commas.indexOf('-') === 0 ? commas.replace('-', '(') + ')' : commas;
	}
	return commas;
};

const upto2DecimalPlaces = (number: any, formatNegativeValue?: boolean) => {
	return isNaN(number)
		? toDecimalPlaces(0, 2, formatNegativeValue)
		: toDecimalPlaces(Number(number), 2, formatNegativeValue);
};

const upto4DecimalPlaces = (number: any, formatNegativeValue?: boolean) => {
	return isNaN(number)
		? toDecimalPlaces(0, 4, formatNegativeValue)
		: toDecimalPlaces(Number(number), 4, formatNegativeValue);
};

const uptoZeroDecimalPlaces = (number: any, formatNegativeValue?: boolean) => {
	return isNaN(number)
		? toDecimalPlaces(0, 0, formatNegativeValue)
		: toDecimalPlaces(Number(number), 0, formatNegativeValue);
};

const upto5DecimalPlaces = (number: any, formatNegativeValue?: boolean) => {
	return isNaN(number)
		? toDecimalPlaces(0, 5, formatNegativeValue)
		: toDecimalPlaces(Number(number), 5, formatNegativeValue);
};

const formatNegativeValues = (num: number) => {
	return num
		? num.toString().indexOf('-') === 0
			? num.toString().replace('-', '(') + ')'
			: num
		: num;
};

const updateRefrence = (data: any): any => {
	return _.cloneDeep(data);
};

const removeComma = (val: string) => {
	return val ? Number(val.split(',').join('')) : 0;
};

const getNumber = (val: any) => {
	return typeof val === 'number' && !isNaN(val)
		? val
		: val && !isNaN(Number(val.split(',').join('')))
		? Number(val.split(',').join(''))
		: 0;
};

const createRMSummaryData = (copMonth: string, copYear: number) => {
	let summaryArray = [];
	let monthStart = FULL_MONTHS.indexOf(copMonth);
	let year = monthStart === 0 ? copYear + 1 : copYear;
	let distributionArray: any = [];
	let typeCodes = [
		RM_TYPECODES.PROD_COVERAGE,
		RM_TYPECODES.PERCENT_COVERAGE,
		RM_TYPECODES.POSITION_COST,
		RM_TYPECODES.NET_POSITION_UPLIFT_DRAG,
	];
	let extTypeCodes = [
		RM_TYPECODES.CLASS_3,
		RM_TYPECODES.CLASS_4,
		RM_TYPECODES.CHEESE,
		RM_TYPECODES.DMC,
		RM_TYPECODES.DRP_CLASS,
		RM_TYPECODES.DRP_COMPONENT,
	];
	const addtodistributionArray = (typecode: string, exttypecode: string) => {
		distributionArray.push(new PeriodSummary(typecode, exttypecode, 0));
	};

	addtodistributionArray(
		RM_TYPECODES.PROD_COVERAGE,
		RM_TYPECODES.FORECAST_MILK_VOLUME_LBS
	);

	for (let i = 0; i < typeCodes.length; i++) {
		for (let j = 0; j < extTypeCodes.length; j++) {
			addtodistributionArray(typeCodes[i], extTypeCodes[j]);
		}
		if (
			typeCodes[i] === RM_TYPECODES.PROD_COVERAGE ||
			typeCodes[i] === RM_TYPECODES.PERCENT_COVERAGE
		) {
			addtodistributionArray(typeCodes[i], RM_TYPECODES.NET_OPEN_MILK);
			addtodistributionArray(typeCodes[i], RM_TYPECODES.NET_OPEN_MILK_WO_DRP);

			addtodistributionArray(typeCodes[i], RM_TYPECODES.CLASS3_WORKING);
			addtodistributionArray(typeCodes[i], RM_TYPECODES.CLASS4_WORKING);
			addtodistributionArray(typeCodes[i], RM_TYPECODES.CHEESE_WORKING);
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.NET_OPEN_MILK_INC_WORKING
			);
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.NET_OPEN_MILK_WO_DRP_WORKING
			);
			// Add the new Working Order Typecodes for "Position Coverage" and "% Coverage Table here"
		} else if (typeCodes[i] === RM_TYPECODES.POSITION_COST) {
			addtodistributionArray(typeCodes[i], RM_TYPECODES.TOTAL_POSITION_COST);
			addtodistributionArray(typeCodes[i], RM_TYPECODES.COST_PER_CWT_COVERED);
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.COST_PER_CWT_EST_PRODUCTION
			);
		} else if (typeCodes[i] === RM_TYPECODES.NET_POSITION_UPLIFT_DRAG) {
			addtodistributionArray(typeCodes[i], RM_TYPECODES.NET_UPLIFT_DRAG);
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.NET_UPLIFT_DRAG_PER_CWT_COVERED
			);
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.NET_UPLIFT_DRAG_PER_CWT_EST_PRODUCTION
			);
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.FORECASTED_NET_MILK_REVENUE
			);
			// Add Estimated Net Income /CWT and Total
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.ESTIMATED_NET_INCOME_CWT
			);
			addtodistributionArray(
				typeCodes[i],
				RM_TYPECODES.ESTIMATED_NET_INCOME_TOTAL
			);
		}
	}

	for (let i = 0; i < 12; i++) {
		summaryArray.push({
			typeCode: RM_SECTION_CODES.SUMMARY,
			extension: {
				code: [],
				distribution: JSON.parse(JSON.stringify(distributionArray)),
				accountingPeriod: [
					{
						identifier: FULL_MONTHS[monthStart],
						periodIdentifier: String(Math.ceil((monthStart + 1) / 3)),
						year: String(year),
					},
				],
			},
		});
		if (monthStart === 11) {
			monthStart = 0;
			year++;
		} else {
			monthStart++;
		}
	}
	return {
		[RM_OPERATING_FORECAST_SECTION_CODES.MONTHS]: summaryArray,
		[RM_OPERATING_FORECAST_SECTION_CODES.YEAR]: [
			{
				typeCode: RM_SECTION_CODES.SUMMARY,
				extension: {
					code: [],
					distribution: JSON.parse(JSON.stringify(distributionArray)),
					accountingPeriod: [
						{
							identifier: '',
							periodIdentifier: '',
							year: String(monthStart === 0 ? year - 1 : year),
						},
					],
				},
			},
		],
	};
};

// get number of days in a each month of the year
const getNumDaysInMonth = (month: string, year: number) => {
	switch (month) {
		case MONTHS[0]:
		case FULL_MONTHS[0]: //January
		case '1':
			return 31;

		case MONTHS[1]:
		case FULL_MONTHS[1]: //February
		case '2':
			return isLeapYear(year) ? 29 : 28;

		case MONTHS[2]:
		case FULL_MONTHS[2]: //March
		case '3':
			return 31;

		case MONTHS[3]:
		case FULL_MONTHS[3]: //April
		case '4':
			return 30;

		case MONTHS[4]:
		case FULL_MONTHS[4]: //May
		case '5':
			return 31;

		case MONTHS[5]:
		case FULL_MONTHS[5]: //June
		case '6':
			return 30;

		case MONTHS[6]:
		case FULL_MONTHS[6]: //July
		case '7':
			return 31;

		case MONTHS[7]:
		case FULL_MONTHS[7]: //August
		case '8':
			return 31;

		case MONTHS[8]:
		case FULL_MONTHS[8]: //September
		case '9':
			return 30;

		case MONTHS[9]:
		case FULL_MONTHS[9]: //October
		case '10':
			return 31;

		case MONTHS[10]:
		case FULL_MONTHS[10]: //November
		case '11':
			return 30;

		case MONTHS[11]:
		case FULL_MONTHS[11]: //December
		case '12':
			return 31;
	}
};

const getFirstDate = (year: string, month: string) => {
	if (getMonthFromString(month) > 0) {
		let YY = getMonthFromString(month) === 1 ? Number(year) : Number(year) - 1;
		let MM = ('0' + getMonthFromString(month)).slice(-2);
		let DD = '01';
		return MM + '/' + DD + '/' + YY;
	}
	return '';
};

const getLastDate = (year: string, month: string, duration: string) => {
	if (getMonthFromString(month) > 0) {
		let mon = getMonthFromString(month) - 1 + +Number(duration);
		let addInYear = mon > 12 ? mon / 12 : 0;
		let endingMonth = mon > 12 ? mon % 12 : mon;
		let MM = ('0' + endingMonth).slice(-2);
		let endingYear = +year + Math.floor(+addInYear);
		let DD = getNumDaysInMonth(String(endingMonth), Number(year));
		return MM + '/' + DD + '/' + year;
	}
	return '';
};

function getMonthFromString(mon: string) {
	var d = Date.parse(`${mon} 1, ${new Date().getFullYear()}`);
	if (!isNaN(d)) {
		return new Date(d).getMonth() + 1;
	}
	return -1;
}

const verifyAccess = (
	accessList: Array<string>,
	allowedType: string
): boolean => {
	return _.indexOf(accessList, allowedType) !== -1;
};

const isLeapYear = (year: number) => {
	return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
};

const monthDiff = (dateFrom: Date, dateTo: Date): number => {
	return (
		dateTo.getMonth() -
		dateFrom.getMonth() +
		12 * (dateTo.getFullYear() - dateFrom.getFullYear())
	);
};

const addMonths = (date: Date, months: number): Date => {
	var d = date.getDate();
	date.setMonth(date.getMonth() + +months);
	if (date.getDate() != d) {
		date.setDate(0);
	}
	return date;
};

const getPropertyIndex = (arr: Array<any>, value: string) => {
	let index: number = -1;
	(arr || []).forEach((item: GenericSchema, i: number) => {
		if (item?.typeCode === value) {
			index = i;
		}
	});
	return index;
};

const getPropertyIndexByExtension = (arr: Array<any>, value: string) => {
	let index: number = -1;
	(arr || []).forEach((item: GenericSchema, i: number) => {
		if (item?.extension?.typeCode === value) {
			index = i;
		}
	});
	return index;
};

const scrollToBottom = (className: string) => {
	const nodes = document.getElementsByClassName(className);
	for (let i = 0; i < nodes?.length; i++) {
		nodes[i].scrollTop = nodes[i].scrollHeight;
	}
};

const getYearsList = (): Array<string> => {
	let list = [];
	const curYear = new Date().getFullYear() + 1; // added one for handling bugNo. 67604 //Adjust Year handling to align fiscal year
	for (let i = START_YEAR; i <= curYear; i++) {
		list.push(i.toString());
	}
	return list.reverse();
};

const getOptions = (
	start: number,
	end: number,
	difference: number,
	precision: number = 0
) => {
	let list = [];
	for (
		let i = start;
		Number(toDecimalPlaces(i, precision)) <= end;
		i = i + difference
	) {
		list.push(toDecimalPlaces(i, precision));
	}
	return list;
};

const getAmountByIdentifier = (items: GenericSchema, identifier: string) => {
	let amount = 0;
	Object.keys(items || []).map((topLevelKey: string) => {
		const item = items[topLevelKey];
		Object.keys(item).map((key: string) => {
			const innerItem = item[key];
			Object.keys(innerItem).map((innerKey: string) => {
				if (innerItem[innerKey][0].identifier === identifier) {
					amount = innerItem[innerKey][0]?.amount;
				}
			});
		});
	});

	return amount;
};

const setAmountByIdentifier = (
	items: GenericSchema,
	identifier: string,
	amount: number
) => {
	Object.keys(items || []).map((topLevelKey: string) => {
		const item = items[topLevelKey];
		Object.keys(item).map((key: string) => {
			const innerItem = item[key];
			Object.keys(innerItem).map((innerKey: string) => {
				if (innerItem[innerKey][0].identifier === identifier) {
					innerItem[innerKey][0].amount = amount;
				}
			});
		});
	});
};

const getMeasureDataByIdentifier = (
	items: GenericSchema,
	identifier: string,
	storedKey: string
) => {
	let amount = 0;

	Object.keys(items || []).map((topLevelKey: string) => {
		const item = items[topLevelKey];
		Object.keys(item).map((key: string) => {
			const innerItem = item[key];
			Object.keys(innerItem).map((innerKey: string) => {
				if (innerItem[innerKey][0].identifier === identifier) {
					let row = innerItem[innerKey][0];
					let index = getPropertyIndex(row.extension?.measure, storedKey);
					amount = row.extension?.measure[index]?.content;
				}
			});
		});
	});
	return amount;
};

const getIndicatorKey = (storedKey: string) => {
	switch (storedKey) {
		case STORED_MEASURE_KEYS.PER_CWT:
			return INDICATOR_KEYS.PER_CWT;
		case STORED_MEASURE_KEYS.PER_COW_PER_YEAR:
			return INDICATOR_KEYS.PER_COW_PER_YEAR;
		case STORED_MEASURE_KEYS.PER_COW_PER_DAY:
			return INDICATOR_KEYS.PER_COW_PER_DAY;
		case STORED_MEASURE_KEYS.PER_ACRE:
			return INDICATOR_KEYS.PER_ACRE;
		default:
			return '';
	}
};

const setMeasureDataByIdentifier = (
	items: GenericSchema,
	identifier: string,
	storedKey: string,
	amount: number
) => {
	Object.keys(items || []).map((topLevelKey: string) => {
		const item = items[topLevelKey];
		Object.keys(item).map((key: string) => {
			const innerItem = item[key];
			Object.keys(innerItem).map((innerKey: string) => {
				let row = innerItem[innerKey][0];
				const perCWTIndIndex = getPropertyIndex(
					row.extension?.indicator,
					getIndicatorKey(storedKey)
				);
				if (
					row.identifier === identifier &&
					row.extension?.indicator[perCWTIndIndex]?.content === 'True'
				) {
					let index = getPropertyIndex(row.extension?.measure, storedKey);
					row.extension.measure[index].content = amount;
				}
			});
		});
	});
};

const getAmountByActionCode = (amount: number, action: string) => {
	if (action === ACTION_CODE.CREDIT) return amount * -1;
	return amount;
};

const abbreviateNumber = (value: number): string | string[] => {
	if (value >= 1e12) return toDecimalPlaces(+(value / 1e12), 1, true) + 'T';
	if (value >= 1e9) return toDecimalPlaces(+(value / 1e9), 1, true) + 'B';
	if (value >= 1e6) return toDecimalPlaces(+(value / 1e6), 1, true) + 'M';
	if (value >= 1e3) return toDecimalPlaces(+(value / 1e3), 1, true) + 'K';
	if (value <= -1e12) return toDecimalPlaces(+(value / 1e12), 1, true) + 'T';
	if (value <= -1e9) return toDecimalPlaces(+(value / 1e9), 1, true) + 'B';
	if (value <= -1e6) return toDecimalPlaces(+(value / 1e6), 1, true) + 'M';
	if (value <= -1e3) return toDecimalPlaces(+(value / 1e3), 1, true) + 'K';
	if (value < 1e3) return toMaxDecimalPlaces(value, 2, true);
	//TODO: Need default return statement
	return toMaxDecimalPlaces(value, 2, true);
};

const abbreviateNumberBarChart = (value: number): string | string[] => {
	if (value >= 1e12) return toDecimalPlaces(+(value / 1e12), 1, true) + 'T';
	if (value >= 1e9) return toDecimalPlaces(+(value / 1e9), 1, true) + 'B';
	if (value >= 1e6) return toDecimalPlaces(+(value / 1e6), 1, true) + 'M';
	if (value >= 1e3) return toDecimalPlaces(+(value / 1e3), 1, true) + 'K';
	if (value <= -1e12) return toDecimalPlaces(+(value / 1e12), 1, true) + 'T';
	if (value <= -1e9) return toDecimalPlaces(+(value / 1e9), 1, true) + 'B';
	if (value <= -1e6) return toDecimalPlaces(+(value / 1e6), 1, true) + 'M';
	if (value <= -1e3) return toDecimalPlaces(+(value / 1e3), 1, true) + 'K';
	if (value < 1e3) return '$' + toMaxDecimalPlaces(value, 2, true);
	//TODO: Need default return statement
	return '$' + toMaxDecimalPlaces(value, 2, true);
};

const compareValues = (value1: any, value2: any) => {
	return _.isEqual(value1, value2);
};

const annualizeAmount = (amount: number, numMonthIncluded: number) => {
	return numMonthIncluded > 0 ? (amount / numMonthIncluded) * 12 : 0;
};

const sortArray = (
	data: Array<any>,
	fieldName: string,
	sortDesc: boolean = false,
	type: string = 'date'
): Array<any> => {
	return data.sort((a: any, b: any) => {
		const a1: any = _.chain(a[fieldName])
			.split('.')
			.map(val => val.padStart(2, '0'))
			.join('.')
			.value();
		const b1: any = _.chain(b[fieldName])
			.split('.')
			.map(val => val.padStart(2, '0'))
			.join('.')
			.value();
		if (sortDesc) {
			if (a1 > b1) return -1;
			if (a1 < b1) return 1;
			return b1 - a1;
		} else {
			if (a1 < b1) return -1;
			if (a1 > b1) return 1;
			return a1 - b1;
		}
	});
};

const checkNote = (type: any) => {
	if (type != null && type.replace)
		return type
			.replace(/<p>/g, '')
			.replace(/<\/p>/g, '')
			.replace(/<br>/g, '')
			.replace(/ /g, '') === ''
			? 0
			: 1;
	else return 0;
};
const svgString2Image = async function (
	svgData: any,
	width: any,
	height: any,
	format: any
) {
	return new Promise(resolve => {
		// set default for format parameter
		format = format ? format : 'png';
		// SVG data URL from SVG string
		// create canvas in memory(not in DOM)
		var canvas = document.createElement('canvas');
		// get canvas context for drawing on canvas
		var context = canvas.getContext('2d');
		// set canvas size
		canvas.width = width;
		canvas.height = height;
		// create image in memory(not in DOM)
		var image = new Image();
		image.src = svgData;

		image.onload = e => {
			// clear canvas
			if (context) {
				context.clearRect(0, 0, width, height);
				// draw image with SVG data to canvas
				context.drawImage(image, 0, 0, width, height);
			}
			// snapshot canvas as png
			var pngData = canvas.toDataURL('image/' + format);
			resolve(pngData);
		};
	});
};

const getUniqueLatestMarketPriceFromList = (marketPriceList: MarketPrice[]) => {
	const newList: any[] = [];
	const testDictionary: any = {};
	marketPriceList.forEach((item: MarketPrice) => {
		if (testDictionary[item.month.toString() + '_' + item.year.toString()]) {
			const price =
				testDictionary[item.month.toString() + '_' + item.year.toString()];
			const priceDate = price.settlementDate;

			if (
				new Date(item.settlementDate).getTime() > new Date(priceDate).getTime()
			) {
				const index = newList.indexOf(price);

				if (index != -1) {
					newList.splice(index, 1);
					newList.push(item);
					testDictionary[
						item.month.toString() + '_' + item.year.toString()
					] = item;
				}
			}
		} else {
			newList.push(item);
			testDictionary[item.month.toString() + '_' + item.year.toString()] = item;
		}
	});
	return newList;
};

const toTitleCase = (str: string) =>
	str.replace(
		/(^\w|\s\w)(\S*)/g,
		(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
	);

export {
	getUniqueLatestMarketPriceFromList,
	svgString2Image,
	getPropertyIndex,
	updateObject,
	stringifyJSON,
	parseJSON,
	getRandomID,
	getQueryVariable,
	decodeJWT,
	formatDate,
	mergeArray,
	getEnvoirment,
	toDecimalPlaces,
	upto2DecimalPlaces,
	upto4DecimalPlaces,
	upto5DecimalPlaces,
	uptoZeroDecimalPlaces,
	formatDatewithTimeFirst,
	formatDatewithMMDDYYYY,
	removeComma,
	updateRefrence,
	getFirstDate,
	getLastDate,
	verifyAccess,
	isLeapYear,
	getNumber,
	monthDiff,
	addMonths,
	minTwoDigits,
	formatDateForPriceCurve,
	scrollToBottom,
	getMonthFromString,
	getYearsList,
	getAmountByIdentifier,
	getMeasureDataByIdentifier,
	setMeasureDataByIdentifier,
	setAmountByIdentifier,
	getAmountByActionCode,
	getPropertyIndexByExtension,
	getOptions,
	abbreviateNumber,
	abbreviateNumberBarChart,
	toMaxDecimalPlaces,
	compareValues,
	annualizeAmount,
	formatDatewithFullMonth,
	sortArray,
	getNumDaysInMonth,
	formatNegativeValues,
	checkNote,
	getDayInMonth,
	getDayInYear,
	createRMSummaryData,
	formatDatewithMMDDYYYYTimeLast,
	toTitleCase,
	reducePositionListInfoDictionary,
};
