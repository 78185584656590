export class MarketPriceAggregation {
	date: Date | null = new Date();
	avgHigh: number = 0;
	avgLow: number = 0;
	avgSettlement: number = 0;
	change: number = 0;
}

export class MarketPrice {
	volatilityMeasure: number = 0;
	contractHighDateTime: string = '';
	lowPriceAmount: number = 0;
	description: string = '';
	existingContracts: number = 0;
	differenceOpenInterestMeasure: number = 0;
	contractAverageRangePrice: number = 0;
	differenceSettlementPrice: number = 0;
	month: string = '';
	tradeQuantity: number = 0;
	symbolCode: string = '';
	contractHighPriceAmount: number = 0;
	tradingDateTime: string = '';
	differenceCurrentDayPrice: number = 0;
	settlementPriceAmount: number = 0;
	year: string = '';
	highPriceAmount: number = 0;
	openPriceAmount: number = 0;
	contractLowDateTime: string = '';
	contractLowPriceAmount: number = 0;
	settlementDate: string = '';
	date: Date = new Date();
	symbolFinalPriceIndicator: string = '';
	isModified = false;
}
