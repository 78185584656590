import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from '../../../../styles/components/number-stepper.module.scss';
import TextInput from '../text-input/text-input';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';

interface IProp extends RouteComponentProps<any> {
	value: string;
	automationId?: string;
	addNoBorderCss?: boolean;
}
type Props = IProp & any;
class TextStepper extends Component<Props, any> {
	constructor(props: IProp) {
		super(props);
		const { value } = props;
		this.state = {
			value: value,
		};
	}

	static defaultProps = {
		value: '',
		label: '',
	};

	static propTypes = {
		value: PropTypes.string,
		label: PropTypes.string,
		onChange: PropTypes.func,
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value });
		}
	}

	updateStepper(operation: string) {
		let { value } = this.state;
		let val = Number(value);
		if (operation === 'SUB' && val > 0) {
			val--;
		} else if (operation === 'ADD' && val > -1) {
			val++;
		}
		value = String(val);
		this.setState({ value });
		this.props.onValueUpdated(value);
	}
	render() {
		const { value } = this.state;
		const { label } = this.props;
		return (
			<>
				<Grid
					container
					className={`${classes.steppercontainer} ${
						this.props.addNoBorderCss ? classes.noBorder : ''
					}`}
				>
					{/* <Grid item xs={2} className={classes.stepperitem}>
						<button
							data-testid="minus"
							disabled={this.props?.disabled}
							draggable
							onDrag={this.updateStepper.bind(this, 'SUB')}
							onClick={this.updateStepper.bind(this, 'SUB')}
							tabIndex={-1}
						>
							<p className={classes.minussymbol}>-</p>
						</button>
					</Grid> */}
					<Grid item xs={12} className={classes.stepperitem}>
						<div className={classes.middlewrapper}>
							<span className={classes.labelPosition}>{label}</span>
							<TextInput
								{...this.props}
								value={value}
								onBlur={(val: string) => {
									this.setState({ value: val });
									this.props.onValueUpdated(val);
								}}
							></TextInput>
						</div>
					</Grid>
					{/* <Grid item xs={2} className={classes.stepperitem}>
						<button
							draggable
							onDrag={this.updateStepper.bind(this, 'ADD')}
							onClick={this.updateStepper.bind(this, 'ADD')}
							tabIndex={-1}
							disabled={this.props?.disabled}
						>
							+
						</button>
					</Grid> */}
				</Grid>
			</>
		);
	}
}
TextStepper.defaultProps = {
	value: '',
	label: '',
};
TextStepper.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
};

export default TextStepper;
