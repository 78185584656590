import { Error } from './error.schema';
import { MarketPrice } from './market-price.schema';

export enum APIStatus {
	IDLE = 'IDLE',
	LOADING = 'LOADING',
	COMPLETE = 'COMPLETE',
	ERROR = 'ERROR',
}
export interface MarketPriceInfo {
	list: Array<MarketPrice>;
	loading: boolean;
	error: Error;
	dataFrame: { [key: string]: any };
	modifiedDictionary: any;
	isModified: boolean;
	saveAPIStatus: APIStatus;
	saveResponseOrError: any;
	date: string;
}
