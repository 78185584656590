import React, { FC, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Header } from '../../common/components';
import { Footer } from '../../common/components/ui/footer/footer';
import classes from '../../styles/layout/layout.module.scss';

interface Props extends RouteComponentProps {
	children: ReactNode | Element;
}

const Layout: FC<Props> = ({ children }) => {
	// const ref = useRef<HTMLDivElement>(null);
	// useEffect(() => {
	// 	const observer = new IntersectionObserver(handleFooterVisibility, {
	// 		root: null,
	// 		rootMargin: '0px',
	// 		threshold: 0.1,
	// 	});
	// 	let w: any;
	// 	w = window;
	// 	w.observer = observer;
	// 	console.log(ref);

	// 	if (ref.current) {
	// 		observer.observe(ref.current);
	// 	}
	// }, [ref]);

	// function handleFooterVisibility(entry: any) {
	// 	console.log(entry);

	// 	if (entry[0].isIntersecting) {
	// 		document
	// 			.getElementById('leftContainer')
	// 			?.setAttribute('style', 'height: calc(100vh - 180px)');
	// 	} else {
	// 		document.getElementById('leftContainer')?.removeAttribute('style');
	// 	}
	// }
	return (
		<>
			<div className={classes.maincontainer}>
				<div>
					<Header />
					{/* <Navigation /> */}
				</div>
				<div className={classes.contentcontainer}>{children}</div>
				<div>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default withRouter(Layout);
