export const PositionSourceType = { MadCap: 'MadCap', Manual: 'Manual' };
export const lblMadCapLastUpdate = 'Last MadCap Update';
export const MadCapTypeCode = {
	PositionSource: 'Position Source',
	MadCapReference: 'MadCap Reference',
	OrderStatus: 'Order Status',
	PositionNote: 'Position Note',
	MadCapRefOID: 'MadCap Ref OID',
};

export const MADCAP_COMMODITY_NAME_MAP: any = {
	'@DA': 'CLASS III',
	'@DK': 'CLASS IV',
	'@CSC': 'CHEESE',
};

export const Dict_For_Sorting_Position: { [id: string]: number } = {
	Manual: 2,
	Filled: 1,
	Working: 0,
};

export const VolumeInOneContract = 200000;

export const ContractType = {
	Floor: 'Floor',
	Collar: 'Collar',
	Ceiling: 'Ceiling',
	Contract: 'Contract',
};

export const MadCapStatus = {
	Working: 'Working',
	Filled: 'Filled',
	Cancelled: 'Cancelled',
	Manual: 'Manual',
};

export const MapStatusCodeToString = (statusCode: string) => {
	switch (statusCode) {
		case '1':
		case '2':
		case '3':
		case '6':
		case '7':
			return MadCapStatus.Working;
		case '4':
		case '5':
			return MadCapStatus.Filled;
		case '8':
			return MadCapStatus.Cancelled;
		default:
			return MadCapStatus.Working;
	}
};

const mapping_for_contracttype_from_subtype: any = {
	'5': ContractType.Collar,
	'3': ContractType.Floor,
	'4': ContractType.Ceiling,
	'2': ContractType.Contract,
	'1': ContractType.Contract,
};

export const Get_ContractTypeFromSubType = (subtype: string): string => {
	return mapping_for_contracttype_from_subtype[subtype];
};

export const TrimSpaceFromMadCapID = (identifier: string) => {
	return identifier.replace('\\', '').replace('"', '').replace('"', '').trim();
};
