import { REHYDRATE_STORE } from '../actions/actions';
import { ActionSchema } from '../../schemas';

const reducer = (state = false, action: ActionSchema) => {
	switch (action.type) {
		case REHYDRATE_STORE:
			return true;
		default:
			return false;
	}
};
export default reducer;
