import { ClickAwayListener } from '@material-ui/core';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
} from 'react-router-dom';
import { Loader, Message } from './common/components';
import { ConfirmModal, Modal } from './common/components/ui';
import { AUTH_TOKEN } from './common/constants';
import { localStore } from './common/services';
import Error from './containers/error/error';
import AuthCallBack from './containers/login/auth/auth';
import Login from './containers/login/login';
import { Layout } from './hoc';
import { GlobalState } from './schemas';
import { authCheckState, copsavepdfdata } from './store/actions';
import {
	fetchreset,
	MADCAPISSUEERRORMSG,
	MADCAPNOTFOUNDMSG,
} from './store/actions/madcapintegration';
// “No MadCap data available for given period”
const Home = React.lazy(() => import('./containers/home/home'));
const PriceEditor = React.lazy(() =>
	import('./containers/price-editor/PriceEditor')
);

interface IProp extends RouteComponentProps {}
type Props = IProp & any;

// Modal component to show the modal when back button is clicked
function Confirm(props: Props) {
	const isOnline: Boolean = useSelector(
		(state: GlobalState) => state.offline.online
	);
	const [openModal, setOpenModal] = React.useState<Boolean>(isOnline);

	function allowTransition() {
		props.setConfirm(false);
		props.confirmCallback(true);
	}

	function blockTransition() {
		props.setConfirm(false);
		props.confirmCallback(false);
	}

	const modalBody = (
		<div>
			<ConfirmModal
				message="Changes have not been saved.Click CANCEL to return and save changes or CONTINUE to discard changes."
				closeModal={(choice: boolean) => {
					if (choice) {
						allowTransition();
					} else {
						blockTransition();
					}
				}}
				label="CANCEL"
			/>
		</div>
	);

	return (
		<Modal
			open={openModal}
			onClose={() => {
				setOpenModal(false);
			}}
			body={modalBody}
		></Modal>
	);
}

function App(props: Props) {
	// state
	const dispatch = useDispatch();
	const isAuthenticated = useSelector((state: GlobalState) => state.auth.token);
	const location = useLocation();
	const token = localStore.get(AUTH_TOKEN);

	// States for showing modal on back button click
	const [confirm, setConfirm] = React.useState(false);
	const [confirmCallback, setConfirmCallback] = React.useState(null);

	// callback function to stop the default browser dialog box
	function getConfirmation(message: String, callback: any) {
		setConfirmCallback(() => callback);
		setConfirm(true);
	}

	const redirectPath =
		token || location.pathname.indexOf('/auth') > -1
			? location.pathname
			: '/login';

	//show Message
	const MSG = useSelector((state: GlobalState) => state.message);
	const MADCAP = useSelector((state: GlobalState) => state.madcap);
	useEffect(() => {
		dispatch(
			copsavepdfdata({
				cop: {
					generalexpensedata: {},
					generalfeeddetails: {},
					generalfeedinventoryaccrual: {},
					generalincomedata: {},
					balancesheetlivestockinventory: {},
					balancesheetnonfeedcroparap_prepays: {},
					balancesheet_depreciation: {},
					balancesheet_totalprincipalpaymentsforperiod: {},
					balancesheet_copclass3class4basisinformation: {},

					operationalsummary: {},
					copsummary_financialinsights_cashplus_depreciation_and_principal_and_feedCost: {},
					copsummary_financialinsights: {},
					copsummary_accrualadjustment: {},
					notes: {},
				},
				rm: {
					summarytable: {},
					summarygraphs: {},
					dmc: {},
				},
			})
		);
		dispatch(fetchreset());
		dispatch(authCheckState());
	}, [dispatch]);

	let routes = (
		<>
			<Switch data-testid="app-switch">
				<Route exact path={['/', '/login']} component={Login} />
				<Route path={['/auth']} component={AuthCallBack} />
				<Redirect to={redirectPath} />
			</Switch>
		</>
	);

	if (isAuthenticated) {
		routes = (
			<Layout data-testid="app-layout">
				<Switch>
					<Route path="/home" component={Home} />
					<Route path="/priceedit" component={PriceEditor} />
					<Route path="/error" component={Error} />
					<Redirect to="/home" />
				</Switch>
			</Layout>
		);
	}

	return (
		<>
			{MSG && MSG?.showMessage && (
				<>
					{MSG.message === MADCAPNOTFOUNDMSG ||
					MSG.message === MADCAPISSUEERRORMSG ? (
						<ClickAwayListener
							onClickAway={() => {
								dispatch(fetchreset());
							}}
						>
							<div>
								<Message msg={MSG.message} type={'error'}></Message>
							</div>
						</ClickAwayListener>
					) : (
						<Message
							msg={MSG.message}
							type={MSG.type}
							duration={MSG.duration}
						></Message>
					)}
				</>
			)}
			{MADCAP && MADCAP.success && (
				<ClickAwayListener
					onClickAway={() => {
						dispatch(fetchreset());
					}}
				>
					<div>
						<Message msg={MADCAP.message} type={'success'}></Message>
					</div>
				</ClickAwayListener>
			)}
			<Suspense
				fallback={
					<div className="app-loader">
						<Loader />
					</div>
				}
			>
				<Router getUserConfirmation={getConfirmation}>
					{/* <Router> */}
					{routes}
					{confirm && (
						<Confirm
							confirmCallback={confirmCallback}
							setConfirm={setConfirm}
						/>
					)}
				</Router>
			</Suspense>
		</>
	);
}

export default App;
