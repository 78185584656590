import React, { FC, ReactNode, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { GlobalState } from '../../schemas';
import { useSelector } from 'react-redux';
import { Loader } from '../../common/components';

interface IProp extends RouteComponentProps {
	children: ReactNode | Element;
}
type Props = IProp & any;
const Rehydrated: FC<Props> = ({ children }) => {
	const [visible, setVisible] = useState<Boolean>(false);
	useEffect(() => {
		window.setTimeout(() => setVisible(true), 2000);
	});
	const rehydrated = useSelector((state: GlobalState) => state.reducer);
	return (
		<>
			<div className="rehydrated" data-testid="rehydrate">
				{rehydrated || visible ? (
					children
				) : (
					<div className="app-loader">
						<Loader />
					</div>
				)}
			</div>
		</>
	);
};

export default Rehydrated;
