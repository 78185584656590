import {
	PRODUCERS_SYNC_START,
	PRODUCERS_SYNC_FAIL,
	PRODUCERS_SYNC_SUCCESS,
	PRODUCER_SYNC_OFFLINE,
	COASCHEMA_SYNC_OFFLINE,
	COA_SAVE_DATA_OFFLINE,
	COA_SAVE_DATA_OFFLINE_UPDATE,
	LATEST_MARKETPRICE_SYNC_OFFLINE,
	DELETE_OFFLINE_VERSIONS,
	PRODUCER_SYNC_PROGRESS_OFFLINE,
} from './actions';
import {
	Producer,
	GenericSchema,
	ProducerYear,
	CostOfProduction,
	RiskManagement,
	MarketPrice,
} from '../../schemas';
import { Dispatch, ActionCreator } from 'redux';
import { AxiosResponse, AxiosError } from 'axios';
import { http } from '../../common/services';
import { COA_API, RM_API } from '../../common/configs';
import * as _ from 'lodash';
import { SCHEMA_TYPE } from '../../common/constants';
import { formatDateForPriceCurve } from '../../common/utils';
import { showMessage } from './message-notification';

interface IAuthAction {
	type: string;
}

interface IAuthSyncYearsOfflineAction extends IAuthAction {
	payload: Producer;
}

interface IAuthSyncCOASchemaOfflineAction extends IAuthAction {
	payload: GenericSchema;
}

interface IAuthSyncLatestMarketPriceAction extends IAuthAction {
	payload: GenericSchema;
}
interface IAuthOfflineSaveAction {
	type: string;
	payload: GenericSchema;
}
interface IAuthOfflineUpdateAction {
	type: string;
	payload: GenericSchema;
}

interface IAuthDeleteOfflineVersionAction {
	type: string;
	payload: GenericSchema;
}

interface IAuthSyncProgressOfflineVersionAction {
	type: string;
	payload: string;
}

const producerSyncStart: ActionCreator<IAuthAction> = () => {
	return {
		type: PRODUCERS_SYNC_START,
	};
};

const producerSyncFail: ActionCreator<IAuthAction> = () => {
	return {
		type: PRODUCERS_SYNC_FAIL,
	};
};

const producerSyncSuccess: ActionCreator<IAuthAction> = () => {
	return {
		type: PRODUCERS_SYNC_SUCCESS,
	};
};

const syncProducer: ActionCreator<IAuthSyncYearsOfflineAction> = (
	producer: Producer,
	key: string
) => {
	producerSyncStart();
	return {
		type: PRODUCER_SYNC_OFFLINE,
		payload: { ...producer, key },
	};
};

const syncProgress: ActionCreator<IAuthSyncProgressOfflineVersionAction> = (
	payload: string
) => {
	return {
		type: PRODUCER_SYNC_PROGRESS_OFFLINE,
		payload: payload,
	};
};

const saveOffline: ActionCreator<IAuthOfflineSaveAction> = (
	record: CostOfProduction | RiskManagement,
	producerIdentifier: string,
	year: string,
	type: string,
	key: string = ''
) => {
	return {
		type: COA_SAVE_DATA_OFFLINE,
		payload: { key, record, producerIdentifier, year, type },
	};
};

const updateOfflineData: ActionCreator<IAuthOfflineUpdateAction> = (
	producerIdentifier: string,
	year: string,
	type: string,
	versionIdentifier: number,
	serverIdentifier: number,
	key: string
) => {
	return {
		type: COA_SAVE_DATA_OFFLINE_UPDATE,
		payload: {
			versionIdentifier,
			producerIdentifier,
			serverIdentifier,
			year,
			type,
			key,
		},
	};
};

const syncCOASchema: ActionCreator<IAuthSyncCOASchemaOfflineAction> = (
	schema: GenericSchema,
	versionIdentifier: string
) => {
	return {
		type: COASCHEMA_SYNC_OFFLINE,
		payload: { ...schema, versionIdentifier },
	};
};

const syncLatestMarketPrice: ActionCreator<IAuthSyncLatestMarketPriceAction> = (
	marketPriceList: Array<MarketPrice>,
	key: string
) => {
	return {
		type: LATEST_MARKETPRICE_SYNC_OFFLINE,
		payload: { marketPriceList, key },
	};
};

// delete all offline versions
const deleteOfflineVersions: ActionCreator<IAuthDeleteOfflineVersionAction> = (
	producerIdentifier: string,
	year: string,
	key: string
) => {
	return {
		type: DELETE_OFFLINE_VERSIONS,
		payload: { producerIdentifier, year, key },
	};
};

// get market price by RM - MTM date to sync offline
const getMarketPriceByDate = async (
	date: string,
	month: string = '',
	year: string = ''
) => {
	return await http.get(RM_API.MARKET_PRICE_HISTORIC_LIST(date, month, year));
};

// download data and take offline selected year(s) of a specific producer
const takeProducerOffline = (producer: Producer, key: string) => {
	return (dispatch: Dispatch) => {
		dispatch(producerSyncStart());
		const promises: Array<GenericSchema> = [];
		const schemaVersion: Array<string> = [];
		producer.versions.forEach((version: ProducerYear) => {
			version.costOfProductionVersions.forEach((cop: CostOfProduction) => {
				let URL: string = COA_API.GET_COP_DATA(
					producer.producerIdentifier,
					version.year,
					String(cop.version),
					SCHEMA_TYPE.COP
				);
				promises.push(
					http.get(URL).then((response: AxiosResponse) => {
						cop.journalEntry = response.data;
						cop.isOffline = true;
					})
				);
				schemaVersion.push(String(cop.chartOfAccountsVersion));
			});

			version.riskMarketingVersions.forEach((rm: RiskManagement) => {
				let URL: string = COA_API.GET_COP_DATA(
					producer.producerIdentifier,
					version.year,
					String(rm.version),
					SCHEMA_TYPE.RM
				);
				promises.push(
					http.get(URL).then((response: AxiosResponse) => {
						rm.journalEntry = response.data;
						rm.isOffline = true;
						rm.marketPriceList = [];

						// market price
						const mtmDate =
							response?.data?.journalEntry?.journalEntryHeader
								?.documentReference[2]?.documentDateTime || '';
						const finacialYear =
							String(
								Number(
									response?.data?.journalEntry?.journalEntryLine[0]?.extension
										?.accountingPeriod[0]?.year
								) - 1
							) || '';
						const financialYearStartmonth =
							response?.data?.journalEntry?.journalEntryLine[0]?.extension
								?.accountingPeriod[0]?.identifier || 'January';
						if (mtmDate) {
							const date = formatDateForPriceCurve(mtmDate);
							const price = getMarketPriceByDate(
								date,
								financialYearStartmonth,
								finacialYear
							);
							price
								.then((response: AxiosResponse) => {
									rm.marketPriceList = response.data || [];
								})
								.catch((err: AxiosError) => {
									dispatch(showMessage(err?.message));
								});
						}
					})
				);
			});
		});

		const COAVersionToBeSynced = _.uniq(schemaVersion).join(',');
		let schemas: Array<GenericSchema> = [];
		promises.push(
			http
				.get(COA_API.GET_COP_SCHEMA(COAVersionToBeSynced))
				.then((response: AxiosResponse) => {
					schemas = _.isArray(response.data) ? response.data : [response.data];
				})
				.catch((err: AxiosError) => {
					dispatch(showMessage(err?.message));
				})
		);
		//current version COA schema
		promises.push(
			http
				.get(COA_API.GET_COP_SCHEMA(''))
				.then((response: AxiosResponse) => {
					schemas.push(response.data);
				})
				.catch((err: AxiosError) => {
					dispatch(showMessage(err?.message));
				})
		);

		//current price
		const date = formatDateForPriceCurve(new Date());
		promises.push(
			http
				.get(RM_API.MARKET_PRICE_HISTORIC_LIST(date))
				.then((response: AxiosResponse) => {
					const marketPriceList = response.data || [];

					dispatch(syncLatestMarketPrice(marketPriceList, key));
				})
				.catch((err: AxiosError) => {
					dispatch(showMessage(err?.message));
				})
		);

		let counter = 0;
		for (let i = 0; i < promises.length; i++) {
			promises[i].then((resp: AxiosResponse) => {
				counter++;
				const per = Math.floor((counter / promises?.length) * 100);
				dispatch(syncProgress(per));
				return resp;
			});
		}

		// fetch all ans save it Offline
		Promise.all(promises)
			.then(() => {
				schemas.forEach((schema: GenericSchema) => {
					dispatch(
						syncCOASchema(
							schema,
							schema?.showChartOfAccounts?.versionIdentifier
						)
					);
				});
				dispatch(syncProducer(producer, key));
				dispatch(producerSyncSuccess());
			})
			.catch(() => {
				dispatch(producerSyncFail());
				dispatch(showMessage('Something went wrong,Please try again.'));
			});
	};
};

export {
	producerSyncStart,
	producerSyncFail,
	producerSyncSuccess,
	takeProducerOffline,
	saveOffline,
	updateOfflineData,
	deleteOfflineVersions,
	syncProgress,
};
