import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { getQueryVariable } from '../../../common/utils';
import { Error, GlobalState } from '../../../schemas';
import { auth } from '../../../store/actions/index';
import classes from '../../../styles/layout/login.module.scss';

interface IProp extends RouteComponentProps {}
type Props = IProp;
function AuthCallBack(props: Props) {
	const { location } = props;
	const token = getQueryVariable(location.search, 'code');

	// state
	const dispatch = useDispatch();
	const isAuthenticating: Boolean = useSelector(
		(state: GlobalState) => state.auth.loading
	);
	const error: Error = useSelector((state: GlobalState) => state.auth.error);

	useEffect(() => {
		if (token) {
			dispatch(auth(token));
		}
	}, [dispatch, token]);

	return (
		<>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: '100vh' }}
			>
				<Grid item xs={6}>
					{!isAuthenticating && error ? (
						<div className={classes.authtext}>
							{error.message}
							<Box display="flex" m={1} p={3}>
								<div className={classes.backbtn}>
									<Link to="/login">
										<button className={classes.orangebtn}>Back to Login</button>
									</Link>
								</div>
							</Box>
						</div>
					) : (
						<div className={classes.authtext}>
							Please wait while we sign you in...
						</div>
					)}
				</Grid>
			</Grid>
		</>
	);
}
export default AuthCallBack;
