import { AxiosError, AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { ActionCreator, Dispatch } from 'redux';
import { RM_API } from '../../common/configs';
import { REGEX_VALUES } from '../../common/constants';
import { http } from '../../common/services';
import {
	AuthInfo,
	MarketPrice,
	Producer,
	ProducerYear,
	RiskManagement,
	SyncOffline,
} from '../../schemas';
import { store } from '../index';
import {
	ADD_MARKET_PRICE,
	EDIT_MARKET_FINALSYMBOL_STATUS,
	EDIT_MARKET_PRICE,
	EDIT_MARKET_SETTLEMENT_DATE_MONTH,
	MARKET_FETCH_FAIL,
	MARKET_FETCH_START,
	MARKET_FETCH_SUCCESS,
	MARKET_PRICE_LIST,
	MARKET_PRICE_REMOVE_SELECTED,
	PREPARE_DATAFRAME_MARKET_PRICE,
	SAVE_MARKET_PRICE_COMPLETE,
	SAVE_MARKET_PRICE_ERROR,
	SAVE_MARKET_PRICE_STARTS,
	SAVE_MARKET_PRICE_STAUS_RESET,
} from './actions';
import { showMessage } from './message-notification';

interface IAuthAction {
	type: string;
}
interface IAuthErrorAction {
	type: string;
	payload: AxiosError;
}

interface IAuthProducerLoadedAction {
	type: string;
	payload: Array<MarketPrice>;
}

const fetchStart = (payload: { date: string }) => {
	return {
		type: MARKET_FETCH_START,
		payload: payload,
	};
};

const fetchFail: ActionCreator<IAuthErrorAction> = (error: AxiosError) => {
	return {
		type: MARKET_FETCH_FAIL,
		payload: error,
	};
};

const fetchSuccess: ActionCreator<IAuthAction> = () => {
	return {
		type: MARKET_FETCH_SUCCESS,
	};
};

const marketPriceLoaded: ActionCreator<IAuthProducerLoadedAction> = (
	list: Array<MarketPrice> = []
) => {
	return {
		type: MARKET_PRICE_LIST,
		payload: list,
	};
};

const removeSelectedMarketPrice: ActionCreator<IAuthAction> = () => {
	return {
		type: MARKET_PRICE_REMOVE_SELECTED,
	};
};

const prepareDataFrameMarketPrice = (dataList: Array<MarketPrice>) => {
	return {
		type: PREPARE_DATAFRAME_MARKET_PRICE,
		payload: dataList,
	};
};

const editMarketPrice = (payloadData: {
	colKey: string;
	rowKey: string;
	price: string;
}) => {
	return {
		type: EDIT_MARKET_PRICE,
		payload: payloadData,
	};
};

const addMarketPriceForMonth = (payloadData: {
	colKey: string;
	rowKey: string;
	price: string;
	date: string;
}) => {
	return {
		type: ADD_MARKET_PRICE,
		payload: payloadData,
	};
};

const editSymbolFinalPriceIndicator = (payloadData: {
	colKey: string;
	rowKey: string;
	blnSymbolFinalPriceIndicator: boolean;
}) => {
	return {
		type: EDIT_MARKET_FINALSYMBOL_STATUS,
		payload: payloadData,
	};
};

const editDateForYearMonth = (payloadData: {}) => {
	return {
		type: EDIT_MARKET_SETTLEMENT_DATE_MONTH,
		payload: payloadData,
	};
};

const saveMarketPriceStartsStatus = () => {
	return { type: SAVE_MARKET_PRICE_STARTS };
};

const saveMarkertPiceComplete = (data: any) => {
	return { type: SAVE_MARKET_PRICE_COMPLETE, payload: data };
};

const saveMarketPriceReset = () => {
	return { type: SAVE_MARKET_PRICE_STAUS_RESET };
};

const saveMarkertError = (error: any) => {
	return { type: SAVE_MARKET_PRICE_ERROR, payload: error };
};

// get market price according to date
const getMarketPrice = (
	date: string,
	month: string = '',
	year: string = '',
	fetchNonLatest = false
) => {
	return (dispatch: Dispatch) => {
		dispatch(removeSelectedMarketPrice());
		dispatch(fetchStart({ date: date }));
		http
			.get(RM_API.MARKET_PRICE_HISTORIC_LIST(date, month, year, fetchNonLatest))
			.then(
				(response: AxiosResponse) => {
					let marketPriceList = response.data ? response.data : [];
					marketPriceList.forEach((item: MarketPrice) => {
						const monthYear = item.month + ' ' + item.year;
						item.date = new Date(monthYear);
					});

					marketPriceList = _.orderBy(
						marketPriceList,
						(item: MarketPrice) => new Date(item.tradingDateTime),
						['desc']
					);
					dispatch(marketPriceLoaded(marketPriceList));
					dispatch(fetchSuccess());
				},
				(err: AxiosError) => {
					dispatch(fetchFail(err));
					dispatch(showMessage(err?.message));
				}
			);
	};
};

// get market price in Offline mode according to date
const getMarketPriceOffline = (
	partyIdentifer: string,
	rmRevisionIdentifier: number,
	date: string,
	month: string,
	year: string
) => {
	return (dispatch: Dispatch) => {
		dispatch(fetchStart({ date: date }));

		const authInfo: AuthInfo = store.getState().auth;
		const syncedData: SyncOffline = store.getState().synced;
		let marketPriceList =
			_.filter(syncedData[authInfo?.userInfo?.mail]?.marketPriceList, {
				month: month,
				year: year,
			}) || [];

		if (Number(rmRevisionIdentifier) > 0) {
			const syncList = syncedData[authInfo?.userInfo?.mail]?.list || [];
			const producer = syncList.filter(
				(p: Producer) => p.producerIdentifier == partyIdentifer
			);
			const yearList =
				producer?.length > 0
					? producer[0]?.versions.filter(
							(item: ProducerYear) => String(item.year) == String(year)
					  )
					: [];
			const versionRM =
				yearList?.length > 0
					? yearList[0].riskMarketingVersions.filter(
							(item: RiskManagement) =>
								Number(item.version) == Number(rmRevisionIdentifier)
					  )
					: [];
			marketPriceList =
				versionRM?.length > 0 ? versionRM[0].marketPriceList : [];
		}

		marketPriceList?.forEach((item: MarketPrice) => {
			const monthYear = item.month + ' ' + item.year;
			item.date = new Date(monthYear);
		});

		marketPriceList = _.orderBy(
			marketPriceList,
			(item: MarketPrice) => new Date(item.tradingDateTime),
			['desc']
		);
		dispatch(marketPriceLoaded(marketPriceList));
		dispatch(fetchSuccess());
	};
};

const getDateForPostBody = (date: Date): string => {
	return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
		'0' + date.getDate()
	).slice(-2)}`;
};
const saveMarketData = () => (dispatch: Dispatch) => {
	dispatch(saveMarketPriceStartsStatus());

	const marketDictionary = store.getState().marketprice.modifiedDictionary;
	const dataFrame = store.getState().marketprice.dataFrame;

	const mappingDict: any = {};
	const body: any[] = [];

	const columns = ['@C', '@SM', '@ALFALFA', '@ACTUALFEED', '@ALLMILK'];

	const addPOSTJsonStructucreFromObject = (
		key: string,
		dataItem: MarketPrice
	) => {
		let newItem: any = {};
		if (mappingDict[key]) {
			newItem = mappingDict[key];
		} else {
			let settlePrice = 0.0;

			try {
				settlePrice = parseFloat(
					(dataItem.settlementPriceAmount + '').replace(
						REGEX_VALUES.PrecedingZero,
						''
					)
				);
			} catch (err) {
				console.log('parsing values', err);
			}

			newItem = {
				SymbolCode: dataItem.symbolCode,
				SettlementPriceAmount: settlePrice,
				SymbolFinalPriceIndicator: dataItem.symbolFinalPriceIndicator,
				SettlementDate: dataItem.settlementDate,
				Year: dataItem.year,
				Month: dataItem.month,
			};
			mappingDict[key] = newItem;
			body.push(newItem);
		}

		return newItem;
	};

	for (let key in marketDictionary) {
		// console.log('Key', key);
		const match = key.match(REGEX_VALUES.Month_Year_Symbol);
		if (match && match.length > 0) {
			const colKey = match[1];
			const rowKey = match[2];

			if (key.indexOf('PRICE') !== -1) {
				// console.log('PRICE', dataFrame[colKey][rowKey]);
				//TODO : provide only selected attributes
				const dataItem = dataFrame[colKey][rowKey];
				addPOSTJsonStructucreFromObject(colKey + rowKey, dataItem);
			} else if (key.indexOf('STATUS') !== -1) {
				//TODO : loop on all the objects in row via dataFrame. prepare multile objects
				for (let prop in dataFrame) {
					if (columns.indexOf(prop) !== -1 && dataFrame[prop][rowKey]) {
						const item = addPOSTJsonStructucreFromObject(
							prop + rowKey,
							dataFrame[prop][rowKey]
						);
						item.SymbolFinalPriceIndicator =
							marketDictionary[key].blnSymbolFinalPriceIndicator;
					}
				}
				// console.log('STATUS', dataFrame[colKey][rowKey]);
			} else if (key.indexOf('SETTLEDATE') !== -1) {
				//TODO : loop on all the objects in row via dataFrame. prepare multile objects
				for (let prop in dataFrame) {
					if (columns.indexOf(prop) !== -1 && dataFrame[prop][rowKey]) {
						const existingItem = dataFrame[prop][rowKey];
						if (
							(dataFrame['STATUS'] && dataFrame['STATUS'][rowKey]) ||
							existingItem.isModified
						) {
							const item = addPOSTJsonStructucreFromObject(
								prop + rowKey,
								dataFrame[prop][rowKey]
							);
							const [year, month, day] = marketDictionary[
								key
							].settlementDate.split('-');
							const today = new Date();
							const selectedDate = new Date(
								Date.UTC(
									year,
									parseInt(month) - 1,
									day,
									today.getUTCHours(),
									today.getTimezoneOffset() + today.getUTCMinutes(),
									today.getUTCSeconds(),
									today.getUTCMilliseconds()
								)
							);
							item.SettlementDate = getDateForPostBody(selectedDate);
						}
					}
				}
				// console.log('SETTLEDATE', dataFrame[colKey][rowKey]);
			}
		}
	}

	http.post(RM_API.MARKET_PRICE_POST(), body).then(
		(response: AxiosResponse) => {
			dispatch(saveMarkertPiceComplete(response.data));
		},
		(err: AxiosError) => {
			console.log('Error :', err);
			dispatch(saveMarkertError(err));
		}
	);
};

export {
	getMarketPrice,
	removeSelectedMarketPrice,
	getMarketPriceOffline,
	marketPriceLoaded,
	fetchStart,
	fetchSuccess,
	prepareDataFrameMarketPrice,
	editMarketPrice,
	saveMarketData,
	editSymbolFinalPriceIndicator,
	editDateForYearMonth,
	saveMarketPriceReset,
	addMarketPriceForMonth,
};
