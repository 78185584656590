export const RMHeader = {
	revisionIdentifier: '1',
	description: '',
	note: [
		{
			content: '',
			typeCode: 'Forecast Notes',
		},
		{
			content: '',
			typeCode: 'RM Plan Notes',
		},
	],
	documentReference: [
		{
			typeCode: 'CoP Version',
			identifier: '',
		},
		{
			typeCode: 'CoA Version',
			identifier: '',
		},
		{
			typeCode: 'Market Prices',
			identifier: '',
			documentDateTime: '',
		},
	],
	status: {
		code: 'DRAFT',
	},
	accountingPeriod: {
		year: '',
	},
	party: {
		identifier: '',
		name: '',
		location: {
			identifier: '',
		},
	},
	extension: {
		code: {
			content: '',
			typeCode: 'Valuation Method',
		},
		dateTime: [
			{
				content: '',
				typeCode: 'Created At',
			},
			{
				content: '',
				typeCode: 'Modified At',
			},
		],
		measure: [
			{
				content: '100',
				typeCode: 'Q1 Estimated Production Factor',
			},
			{
				content: '100',
				typeCode: 'Q2 Estimated Production Factor',
			},
			{
				content: '100',
				typeCode: 'Q3 Estimated Production Factor',
			},
			{
				content: '100',
				typeCode: 'Q4 Estimated Production Factor',
			},
			{
				content: '',
				typeCode: 'Estimated Average Herd Size',
			},
			{
				content: '',
				typeCode: 'Lbs Covered Annually (Lbs)',
			},
			{
				content: '',
				typeCode: 'Margin Floor Cover (dol per CWT)',
			},
			{
				content: '',
				typeCode: 'Margin Floor Premium (dol per CWT)',
			},
			{
				content: '',
				typeCode: 'Estimated Milk per C per Yr (Fcst))',
			},
			{
				content: '',
				typeCode: 'Estimated Milk per C per Yr (Historical)',
			},
			{
				content: '',
				typeCode: 'Annual Average pct Dry Heard',
			},
		],
		name: [
			{
				content: 'Uttaran Banerjee',
				typeCode: 'Created By',
			},
			{
				content: 'Uttaran Banerjee',
				typeCode: 'Modified By',
			},
		],
	},
};
