import {
	AUTH_START,
	AUTH_SUCCESS,
	AUTH_FAIL,
	AUTH_LOGOUT,
	SET_AUTH_REDIRECT_PATH,
} from '../actions/actions';
import { updateObject } from '../../common/utils/utility';
import { AuthInfo, User, Error, ActionSchema } from '../../schemas';
import { Reducer } from 'react';

const initialState: AuthInfo = {
	token: '',
	userInfo: new User(),
	error: new Error(),
	loading: false,
	authRedirectPath: '/home',
};
const reducer = (state = initialState, action: ActionSchema) => {
	switch (action.type) {
		case AUTH_START:
			return updateObject(state, {
				error: null,
				loading: true,
			});
		case AUTH_SUCCESS:
			return updateObject(state, {
				token: action.idToken,
				userInfo: action.userInfo,
				error: null,
				loading: false,
			});
		case AUTH_FAIL:
			return updateObject(state, {
				error: action.error,
				loading: false,
			});
		case AUTH_LOGOUT:
			return updateObject(state, {
				token: null,
				userInfo: null,
				selected: null,
				list: [],
			});
		case SET_AUTH_REDIRECT_PATH:
			return updateObject(state, {
				authRedirectPath: action.path,
			});
		default:
			return state;
	}
};

export default reducer;
