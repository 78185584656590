import { RM_OPTIONS } from '../../../../common/constants';
import { Dict_For_Sorting_Position } from '../../../../common/constants/madcap.constants';
import { PositionModel } from '../../../../schemas/Model/position.model';

export const getsortedpositionList = (positionList: any) => {
	// Soting based on Order Status: Working->Filled ->Manual && Sorting in Descending Order according to MadCap Reference
	const primarysortFunction = (a: PositionModel, b: PositionModel) => {
		if (
			Dict_For_Sorting_Position[a.positionReferences.Order_Status] ==
			Dict_For_Sorting_Position[b.positionReferences.Order_Status]
		) {
			return isNaN(parseFloat(b.positionReferences.MadCap_Reference))
				? -1
				: parseFloat(b.positionReferences.MadCap_Reference) -
						parseFloat(a.positionReferences.MadCap_Reference);
		} else {
			return (
				Dict_For_Sorting_Position[a.positionReferences.Order_Status] -
				Dict_For_Sorting_Position[b.positionReferences.Order_Status]
			);
		}
	};

	let temppositionlist = positionList.filter((pd: any) => {
		return pd.typeCode === 'Summary';
	});
	[
		RM_OPTIONS.FLOOR,
		RM_OPTIONS.COLLAR,
		RM_OPTIONS.CEILING,
		RM_OPTIONS.CONTRACT,
	].forEach((d: String) => {
		let positionlistarray = positionList.filter((pd: any) => {
			return pd.typeCode === d;
		});

		if (positionlistarray.length > 0) {
			positionlistarray.sort(primarysortFunction);
			temppositionlist.push(...positionlistarray);
		}
	});
	return temppositionlist;
};
