import { updateObject } from '../../common/utils/utility';
import { ActionSchema } from '../../schemas';
import {
	MAD_CAP_API_RESET,
	MAD_CAP_API_SUCCESS,
	MAD_CAP_FETCH_START,
} from '../actions/actions';

export type madcapstate = {
	success: boolean;
	loading: boolean;
	message: string;
};

const reducer = (
	state: madcapstate = { message: '', success: false, loading: false },
	action: ActionSchema
) => {
	switch (action.type) {
		case MAD_CAP_FETCH_START:
			return updateObject(state, {
				loading: true,
			});
		case MAD_CAP_API_SUCCESS:
			return updateObject(state, {
				loading: false,
				success: true,
				message: 'MadCap Data Update Complete',
			});
		case MAD_CAP_API_RESET:
			return updateObject(state, {
				loading: false,
				success: false,
				message: '',
			});
		default:
			return state;
	}
};

export default reducer;
