import React from 'react';
import { Grid } from '@material-ui/core';
import classes from '../../styles/layout/login.module.scss';
import { getQueryVariable } from '../../common/utils';
import { Link, RouteComponentProps } from 'react-router-dom';
import Box from '@material-ui/core/Box';

interface IProp extends RouteComponentProps {}
type Props = IProp;
function Error(props: Props) {
	const { location } = props;
	const msg = decodeURIComponent(getQueryVariable(location.search, 'msg'));
	return (
		<div>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justify="center"
				style={{ minHeight: '100vh' }}
			>
				<Grid item xs={6}>
					<div className={classes.authtext}>
						{msg}
						<Box display="flex" m={1} p={3}>
							<div>
								<Link to="/login">
									<button className={classes.errorbtn}>HOME</button>
								</Link>
							</div>
						</Box>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}

export default Error;
