import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideMessage } from '../../../store/actions';
import '../../../styles/layout/mixed-chart.scss';

interface IProp {
	msg: string;
	type: any;
	duration?: number | null;
}
type Props = IProp;
function Message(props: Props) {
	// const { msg, type, duration } = props;
	const dispatch = useDispatch();
	return (
		<>
			{props.duration ? (
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={true}
					autoHideDuration={props.duration}
					onClose={() => {
						dispatch(hideMessage());
					}}
				>
					<MuiAlert elevation={6} variant="filled" severity={props.type}>
						{props.msg}
					</MuiAlert>
				</Snackbar>
			) : (
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={true}
					onClose={() => {
						dispatch(hideMessage());
					}}
				>
					<MuiAlert elevation={6} variant="filled" severity={props.type}>
						{props.msg}
					</MuiAlert>
				</Snackbar>
			)}
		</>
	);
}
export default Message;
