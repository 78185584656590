import React from 'react';
import { CustomToolTip as Tooltip } from '..';
import { RM_OPTIONS } from '../../../constants';
import { formatNegativeValues, upto2DecimalPlaces } from '../../../utils';
import { MarketPrice } from './../../../../schemas';
import './../../../../styles/components/popup.scss';
interface IProp {
	data: MarketPrice | any;
	optionSelected(item: MarketPrice, optionType: string): void;
	showFullValues?: boolean;
}
type Props = IProp;
function Popup(props: Props) {
	const { data, showFullValues } = props;
	return (
		<div className="rmpopup" data-testid="popup">
			<div className="toptag">
				{data?.month} {data?.year}
			</div>
			<div className="uppersectionwrapper">
				<div className="datasection">
					<h5 className="borderRight">TODAY'S ACTIVITY</h5>
					<div className="borderRight">
						<p>SETTLED</p>
						<p>{data?.settlementPriceAmount || '-'}</p>
					</div>
					<div className="borderRight">
						<p>
							<Tooltip
								title={'Change in yesterday’s settle price compared to today'}
							>
								<span>CHANGE</span>
							</Tooltip>
						</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.differenceSettlementPrice)
								: upto2DecimalPlaces(data?.differenceSettlementPrice, true)) ||
								'-'}
						</p>
					</div>
					<div className="borderRight">
						<p>HIGH</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.highPriceAmount)
								: upto2DecimalPlaces(data?.highPriceAmount, true)) || '-'}
						</p>
					</div>
					<div className="borderRight">
						<p>LOW</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.lowPriceAmount)
								: upto2DecimalPlaces(data?.lowPriceAmount, true)) || '-'}
						</p>
					</div>
					<div className="borderRight">
						<p>OPEN</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.openPriceAmount)
								: upto2DecimalPlaces(data?.openPriceAmount, true)) || '-'}
						</p>
					</div>
					<div className="borderRight">
						<p>VOLUME</p>
						<p>{data?.tradeQuantity || '-'}</p>
					</div>
				</div>
				<div className="datasection">
					<h5>CONTRACT LIFE</h5>
					<div>
						<p>
							<Tooltip title={'Amount of contracts in existence'}>
								<span>OPEN INTEREST</span>
							</Tooltip>
						</p>
						<p>{data?.existingContracts || '-'}</p>
					</div>
					<div>
						<p>
							<Tooltip
								title={
									'Change in open interest of yesterday compared to today.'
								}
							>
								<span>INTEREST CHANGE</span>
							</Tooltip>
						</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.differenceOpenInterestMeasure1)
								: upto2DecimalPlaces(
										data?.differenceOpenInterestMeasure1,
										true
								  )) || '-'}
						</p>
					</div>
					<div className="nopad">
						<p>HIGH ({data?.contractHighDateTime || '-'})</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.contractHighPriceAmount)
								: upto2DecimalPlaces(data?.contractHighPriceAmount, true)) ||
								'-'}
						</p>
					</div>
					<div>
						<p>LOW ({data?.contractLowDateTime || '-'})</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.contractLowPriceAmount)
								: upto2DecimalPlaces(data?.contractLowPriceAmount, true)) ||
								'-'}
						</p>
					</div>
					<div>
						<p>
							<Tooltip
								title={
									'Average difference between the high and low prices for the contract across the life of the contract.'
								}
							>
								<span>AVG RANGE</span>
							</Tooltip>
						</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.contractAverageRangePrice)
								: upto2DecimalPlaces(data?.contractAverageRangePrice, true)) ||
								'-'}
						</p>
					</div>
					<div>
						<p>
							<Tooltip
								title={
									'Implied Volatility is an indicator of how much the contract price is changing day to day, % of how likely the price will move.'
								}
							>
								<span>VOLATILITY</span>
							</Tooltip>
						</p>
						<p>
							{(showFullValues
								? formatNegativeValues(data?.volatilityMeasure)
								: upto2DecimalPlaces(data?.volatilityMeasure, true)) || '-'}
						</p>
					</div>
				</div>
			</div>
			<div>
				<div className="lowersection">
					<button
						data-testid="floor-option"
						className={'btnoutline popUpBtn  '}
						onClick={() => {
							props?.optionSelected(data, RM_OPTIONS.FLOOR);
						}}
					>
						{/* <i className={'fa fa-add-circle-outline fa-lg'}></i> */}
						ADD FLOOR
						{/* <button className="orangebtn">+</button>{' '} */}
					</button>
					<button
						data-testid="ceiling-option"
						className={'btnoutline popUpBtn  '}
						onClick={() => {
							props?.optionSelected(data, RM_OPTIONS.CEILING);
						}}
					>
						{/* <i className={'fa fa-add-circle-outline fa-lg'}></i> */}
						ADD CEILING
					</button>
					<button
						data-testid="contract-option"
						className={'btnoutline popUpBtn  '}
						onClick={() => {
							props?.optionSelected(data, RM_OPTIONS.CONTRACT);
						}}
					>
						{/* <i className={'fa fa-add-circle-outline fa-lg'}></i> */}
						ADD CONTRACT
						{/* <button className="orangebtn">+</button>{' '} */}
					</button>
					<button
						data-testid="collar-option"
						onClick={() => {
							props?.optionSelected(data, RM_OPTIONS.COLLAR);
						}}
						className={'btnoutline popUpBtn  '}
					>
						{/* <i className={'fa fa-add-circle-outline fa-lg'}></i> */}
						ADD COLLAR
						<p className="subtitle">(Buy Floor/Sell Ceiling combo)</p>
						{/* <button className="orangebtn">+</button>{' '} */}
					</button>
				</div>
			</div>
		</div>
	);
}
export default Popup;
