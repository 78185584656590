import {
	PRODUCERS_FETCH_START,
	PRODUCERS_FETCH_FAIL,
	PRODUCERS_LIST,
	PRODUCER_SELECTED,
	PRODUCER_REMOVE_SELECTED,
} from '../actions/actions';
import { updateObject, mergeArray } from '../../common/utils/utility';
import { ProducerInfo, ActionSchema, Error, Producer } from '../../schemas';

const initialState: ProducerInfo = {
	list: [],
	selected: new Producer(),
	error: new Error(),
	loading: false,
};
const reducer = (state = initialState, action: ActionSchema) => {
	switch (action.type) {
		case PRODUCERS_FETCH_START:
			return updateObject(state, {
				loading: true,
			});
		case PRODUCERS_FETCH_FAIL:
			return updateObject(state, {
				error: action.payload.error,
				loading: false,
			});
		case PRODUCERS_LIST:
			return updateObject(state, {
				list: mergeArray(state.list, action.payload, 'producerIdentifier'),
				loading: false,
			});
		case PRODUCER_SELECTED:
			return updateObject(state, {
				selected: action.payload,
				loading: false,
			});
		case PRODUCER_REMOVE_SELECTED:
			return updateObject(state, {
				selected: new Producer(),
				loading: false,
			});
		default:
			return state;
	}
};

export default reducer;
