import { makeStyles, Checkbox } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
	root: {
		'&$checked': {
			color: '#026e6b',
		},
	},
	checked: {},
});
interface IProp {
	checked?: boolean;
	onChange?: any;
	disabled?: boolean;
}
type Props = IProp;
function CheckBox(props: Props) {
	const classes = useStyles();
	return (
		<Checkbox
			data-testid="chkBx"
			classes={{ root: classes.root, checked: classes.checked }}
			{...props}
		/>
	);
}

export default CheckBox;
