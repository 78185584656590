const INPUT_TYPE = {
	TEXT: 'TEXT',
	NUMBER: 'NUMBER',
	TEXT_AREA: 'TEXT_AREA',
	DECIMAL: 'DECIMAL',
	DECIMAL_5_PLACES: 'DECIMAL_5_PLACES',
	DECIMAL_4_PLACES: 'DECIMAL_4_PLACES',
	DECIMAL_3_PLACES: 'DECIMAL_3_PLACES',
	DECIMAL_2_PLACES: 'DECIMAL_2_PLACES',
	DECIMAL_1_PLACE: 'DECIMAL_1_PLACE',
	PERCENT: 'PERCENT',
};
const TEXT_SIZE = {
	SMALL: 'SMALL',
	DEFAULT: 'DEFAULT',
	LARGE: 'LARGE',
};
export { INPUT_TYPE, TEXT_SIZE };
