import { Button as MButton, makeStyles } from '@material-ui/core';
import React from 'react';

// const useStyles = makeStyles({
// 	root: {
// 		textTransform: 'none',
// 		background: '#E87722',
// 		borderRadius: 25,
// 		border: '1px solid white',
// 		boxShadow: '0 0 0 2px #E87722',
// 		color: 'white',
// 		height: 35,
// 		padding: '0 30px',
// 		'&:hover': {
// 			backgroundColor: '#fff',
// 			borderColor: '#E87722',
// 			boxShadow: 'none',
// 			color: '#E87722',
// 		},
// 		'&:active': {
// 			boxShadow: 'none',
// 			backgroundColor: '#E87722',
// 			borderColor: '#E87722',
// 		},
// 	},
// });
interface IProp {
	onSelect(): void;
	label: string;
	color?: any;
	className?: any;
	startIcon?: any;
}
type Props = IProp;
function Button(props: Props) {
	// const classes = useStyles();
	return (
		<div className="app-button">
			<MButton
				onClick={props.onSelect}
				className={props.className}
				// classes={{ root: classes.root }}
				{...props}
				// style={{ background: props.color }}
			>
				{props.label}
			</MButton>
		</div>
	);
}
export default Button;
