import { SSO_BASE_PATH, LOGOUT_REDIRECT_URI } from '../constants';
const AUTH_CONFIG = {
	AUTHORIZATION: `${SSO_BASE_PATH()}/as/authorization.oauth2`,
	LOGOUT: `${SSO_BASE_PATH()}/idp/startSLO.ping?TargetResource=${LOGOUT_REDIRECT_URI()}`,
	CLIENT_ID: 'rs_client_azure',
	GRANT_TYPE: 'authorization_code',
	RESPONSE_TYPE: 'code',
	SCOPE: 'openid',
	REDIRECT_URI_DEV: 'https://webapp-cp-pp-dv.azurewebsites.net/auth',
	REDIRECT_URI_QA: 'https://webapp-cp-pp-qa.azurewebsites.net/auth',
	REDIRECT_URI_PROD: 'https://dairyrmi.com/auth',
	REDIRECT_URI_LOCAL: 'http://localhost:3000/auth',
};

export { AUTH_CONFIG };
