const EXTENSION_INDICATOR_CODE_TYPE = {
	NONE: 'NONE',
	CALCULATION: 'CALCULATION',
	SUMMARY: 'SUMMARY',
};

const INDICATOR_TYPECODE_KEY = {
	CALCULATED: 'Calculated',
};

const LEVEL2_CATEGORIES = {
	FEED_EXPENSE: 'Feed Expense',
};
const FILLED_MANUAL_TEXT = ' (Filled/Manual orders only)';
const SECTION_TYPE_CODE = {
	INCOME: 'Income',
	EXPENSE: 'Expense',
	ACCRUAL: 'Accrual',
	FINANCIAL_INSIGHTS: 'Financial Insights',
	ACCRUAL_ADJUSTMENTS: 'Accrual Adjustments',
	BASIS_ASSUMPTION: 'Basis Assumptions',
	NET_CASH_INCOME: 'Net Cash Income',
	NET_INCOME: 'Net Income',
	TOTAL_ACCURAL_COP: 'Total Dairy Accrual Cost of Production',
};

const SECTION_CODES = {
	BASIC_INFO: 'BASIC INFO',
	SUMMARY: 'SUMMARY',
	GENERAL: 'GENERAL',
	CROP: 'CROP',
	BS_DETAIL: 'BS DETAIL',
};
const SUBSECTION_TYPE_CODE = {
	LIVESTOCK_INVENTORY: 'Livestock Inventory',
	NON_FEED: 'Non Feed-Crop Accruals',
	DEPRECIATION: 'Depreciation',
	PRINCIPAL_PAYMENTS: 'Principal Payments',
};
const STATUS: Array<string> = ['DRAFT', 'FINAL', 'ARCHIVED'];
const YEAR: Array<string> = ['2020', '2019', '2018', '2017', '2016'];
const STORED_AMT_KEYS = {
	BEGINNING_BALANCE: 'Beginning Balance',
	ENDING_BALANCE: 'Ending Balance',
	VALUE_PER_HEAD: 'Value per head',
	BEGINNING_NO_OF_HEAD: 'Beginning Number of head',
	ENDING_NO_OF_HEAD: 'Ending Number of head',
	ASSIGNED_PERCENTAGE: 'Assigned %',
};
const STORED_MEASURE_KEYS = {
	PER_CWT: 'per CWT',
	PER_COW_PER_DAY: 'Per Cow Per Day',
	PER_COW_PER_YEAR: 'Per Cow Per Year',
	PER_ACRE: 'Per Acre',
};

const INDICATOR_KEYS = {
	PER_CWT: 'CalculatePerCWT',
	PER_COW_PER_DAY: 'CalculatePerCowPerDay',
	PER_COW_PER_YEAR: 'CalculatePerYearPerCow',
	PER_ACRE: 'CalculatePerAcre',
};

const NOTE_KEYS = {
	STRING: 'string',
};
const JOURNAL_HEADER_KEYS = {
	AVG_NO_OF_MATURE_COWS: 'Average Number of Mature Cows',
	AVG_NO_OF_REPLACEMENT_INVENTORY:
		'Average Number of Replacements in Inventory',
	VOL_OF_MILK_SOLD_CWTS: 'Volume of Milk Sold (Cwts)',
	CORN_FORAGE_ACRES: 'Corn Forage Acres',
	CORN_GRAIN_ACRES: 'Corn Grain Acres',
	ALFA_ALFA_ACRES: 'Alfalfa Acres',
	OTHER_FORAGE_ACRES: 'Other Forage Acres',
	OTHER_CASH_GRAIN_ACRES: 'Other Cash Grain Acres',
	TOTAL_ACRES: 'Total Acres',
	TOTAL_CALVES: 'Total Calves',
	TOTAL_HEIFERS: 'Total Heifers',
	TOTAL_BRED_HEIFERS: 'Total Bred Heifers',
};
const ACCURAL_ADJUSTMENTS = {
	LIVESTOCK_INVENTORY: 'Change in Livestock Inventory',
	FEED_CROP_INVENTORY: 'Change in Feed/Crop Inventory',
	PREPAIDS: 'Change in Pre-Paids',
	ACCOUNT_RECEIVABLE: 'Change in Accounts Receivable',
	ACCOUNT_PAYABLE: 'Change in Accounts Payable',
	DEPR_EXPENSE: 'Depreciation Expense',
};
const CROP_ACCURAL = {
	FEED_CROP_ACCURAL: 'Feed-Crop Accrual',
	NET_ACCURAL_FEED_COST: 'Net Accrual Feed Cost',
};

const NAVIGATION_STEPS = [
	{
		id: 'basic-info',
		name: 'Basic Information',
		completed: false,
		substeps: [],
	},
	{
		id: 'income',
		name: 'General Assumptions',
		completed: false,
		substeps: [
			{
				id: 'income',
				name: 'Income',
				completed: true,
			},
			{
				id: 'expense',
				name: 'Expense',
				completed: false,
			},
		],
	},
	{
		id: 'balance-sheet',
		name: 'Balance Sheet Details',
		completed: false,
		substeps: [],
	},
	{
		id: 'class3-class4',
		name: 'Class 3/4 Basis Information',
		completed: false,
		substeps: [],
	},
	{
		id: 'cop-summary',
		name: 'COP Summary',
		completed: false,
		substeps: [],
	},
];

const IDENTIFIERS = {
	INCOME: '1',
	MILK: '2',
	EXPENSE: '12',
	TOTAL_OPERATING_COST: '88',
	TOTAL_ACCURAL_ADJUSTMENTS: '89',
	TOTAL_IDENTIFIED_COP: '90',
	NET_COP_MILK: '91',
	CLASS3: '129',
	CLASS3_HISTORIC: '86',
	CLASS3_FORECAST: '100',
	CLASS3_WEIGHT: '107',
	CLASS3_BREAKEVEN: '92',
	CLASS4: '112',
	CLASS4_HISTORIC: '87',
	CLASS4_FORECAST: '99',
	CLASS4_WEIGHT: '108',
	CLASS4_BREAKEVEN: '93',
	CASH_PLUS_PRINCIPAL: '130',
	CASH_PLUS_PRINCIPAL_BREAKEVEN: '113',
	CASH_PLUS_PRINCIPAL_CL3_BREAKEVEN: '114',
	CASH_PLUS_PRINCIPAL_CL4_BREAKEVEN: '115',
	CASH_PLUS_DEPRECIATION: '94',
	CASH_PLUS_DEPRECIATION_BREAKEVEN: '95',
	CASH_PLUS_DEPRECIATION_CL3_BREAKEVEN: '96',
	CASH_PLUS_DEPRECIATION_CL4_BREAKEVEN: '97',
	CASH_FEED_COST: '98',
	ACCURAL_FEED_COST: '106',
	CHANGE_IN_LIVESTOCK: '120',
	CHANGE_IN_FEED_CROP: '121',
	CHANGE_IN_PREPAID: '122',
	CHANGE_IN_AR: '123',
	CHANGE_IN_AP: '124',
	CHANGE_IN_DEPRECIATION: '125',
	NET_CASH_FEED_COST: '67',
	NET_ACCURAL_FEED_COST: '110',
	FEED_INVENTORY: '69',
	NON_FEED_INVENTORY: '70',
	FEED_CROP_PREPAID: '72',
	FEED_CROP_AR: '73',
	FEED_CROP_AP: '74',
	BS_PREPAID: '82',
	BS_AR: '83',
	BS_AP: '84',
	BS_LIVESTOCK: '76',
	BS_DEPRECIATION: '101',
	BS_NON_FEED_CROP: '75',
	BS_PRINCIPAL_PAYMENTS: '104',
	BS_EQUIPMENT: '102',
	BS_FACILITY: '103',
	FEED_EXPENSE: '19',
	NET_INCOME: '105',
	TOTAL_ACCURAL_COP: '128',
	NET_CASH_INCOME: '85',
	BS_CHANGE_IN_HERD_VALUE: '132',
};

const ACTION_CODE = {
	CREDIT: 'Credit',
	DEBIT: 'Debit',
};

const ClASS3_DROPDOWN_OPTIONS = {
	START: 0,
	END: 100,
	DIFFERENCE: 5,
};

const GRAPH_DATA_IDENTIFIERS = [1, 12, 128, 19, 46, 110];

const COA_TYPECODES = {
	DEFAULT_ALLOCATION_TYPE: 'Default Allocation Type',
	REPORTING_SECTION: 'Reporting Section',
	DEFAULT_PERCENT: 'Default Percent',
};

const ALLOCATION_TYPES = {
	DIRECT_ENTRY: 'Direct Entry',
};

const SUBSECTION_IN_LIVESTOCK_INVENTORY = {
	HERD_VALUE: 'Herd Value',
};

const RMSUMMARY_NETMTM_NOTE = '*Net Open Milk Volume Includes Working Orders';

export {
	RMSUMMARY_NETMTM_NOTE,
	EXTENSION_INDICATOR_CODE_TYPE,
	LEVEL2_CATEGORIES,
	SECTION_TYPE_CODE,
	STATUS,
	YEAR,
	SECTION_CODES,
	STORED_MEASURE_KEYS,
	STORED_AMT_KEYS,
	NAVIGATION_STEPS,
	SUBSECTION_TYPE_CODE,
	NOTE_KEYS,
	JOURNAL_HEADER_KEYS,
	ACCURAL_ADJUSTMENTS,
	CROP_ACCURAL,
	INDICATOR_TYPECODE_KEY,
	IDENTIFIERS,
	ACTION_CODE,
	ClASS3_DROPDOWN_OPTIONS,
	INDICATOR_KEYS,
	GRAPH_DATA_IDENTIFIERS,
	ALLOCATION_TYPES,
	COA_TYPECODES,
	SUBSECTION_IN_LIVESTOCK_INVENTORY,
	FILLED_MANUAL_TEXT,
};
