import { createTheme, MuiThemeProvider, Theme } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { GlobalState, Producer, ProducerYear } from '../../../schemas';
import { removeSelectedCOAScehma } from '../../../store/actions';
import classes from '../../../styles/layout/navigation.module.scss';
import { sortArray } from '../../utils';
import { ConfirmModal, Modal } from '../ui';

enum enumSteps {
	Producer,
	Years,
	COP,
	RM,
}

const theme: Theme = createTheme({
	overrides: {
		MuiStepper: {
			root: {
				padding: '0',
			},
		},
		MuiStepConnector: {
			vertical: {
				padding: '0px',
				marginLeft: '18px',
				// margin: '6px 0px 6px 10px'
			},
			lineVertical: {
				borderLeftWidth: '1px',
				minHeight: '21px',
				marginTop: '-5px',
				marginBottom: '-5px',
				borderColor: 'rgba(0,0,0,.21)',
			},
		},
		MuiStepIcon: {
			root: {
				'&.MuiStepIcon-active': {
					color: '#fff',
					'& .MuiStepIcon-text': {
						fill: 'grey',
						fontWeight: '900',
					},
				},
				'&.MuiStepIcon-completed': {
					color: '#004c73',
				},
			},
		},
		MuiSvgIcon: {
			root: {
				fontSize: '1.5rem!important',
			},
		},
	},
});

const Navigation = (props: any) => {
	const history = useHistory();
	let location = useLocation();

	let yearData = useSelector((state: GlobalState) => state.selectedYear);

	let producerIdentifier = '';
	let year = '';
	let revisionIdentifier = '0';
	let chartOfAccountsVersion = '0';
	let rmVersionIdentifier = '0';
	let currentActiveStep = 0;

	const params = location.pathname.split('/');
	const IS_COP = location.pathname.indexOf('/cop') > -1;
	const IS_RM = location.pathname.indexOf('/rm') > -1;
	const COP_RM = IS_COP || IS_RM;

	rmVersionIdentifier = isNaN(Number(params[8])) ? '0' : params[8];
	chartOfAccountsVersion = isNaN(Number(params[7])) ? '0' : params[7];
	revisionIdentifier = isNaN(Number(params[6])) ? '0' : params[6];
	year = params[5];
	producerIdentifier = params[3];

	if (producerIdentifier && producerIdentifier === 'rm') {
		currentActiveStep = 3;
	} else if (producerIdentifier && producerIdentifier === 'cop') {
		currentActiveStep = 2;
	} else if (producerIdentifier) {
		currentActiveStep = 1;
	} else {
		currentActiveStep = 0;
	}

	let steps = [
		{
			label: 'Producers',
			id: enumSteps.Producer,
			route: '/home/producer',
			enabled: true,
		},
		{
			label: 'Years',
			id: enumSteps.Years,
			route: '/home/producer',
			enabled: currentActiveStep != 0,
		},
		{
			label: 'Cost of Production',
			id: enumSteps.COP,
			route: '/home/producer',
			enabled: currentActiveStep == 3,
		},
		{
			label: 'Risk Management',
			id: enumSteps.RM,
			route: '/home/producer',
			enabled: currentActiveStep == 2,
		},
	];

	if (props?.YEAR_LIST) {
		if (props.YEAR_LIST?.length > 1) {
			steps = steps.filter(d => !(d.label === 'Risk Management'));
		}
	}

	const selectedProducer: Producer = useSelector((state: GlobalState) => {
		const selected = state.producer.selected;
		return selected;
	});

	// get latest Risk Marketing version identifier
	const latestRMVersion = (selectedProducer: Producer, year: string) => {
		const selectedYear: Array<ProducerYear> = selectedProducer.versions.filter(
			(item: ProducerYear) => item.year == year
		);
		let latestRMVersion = '0';
		let rmlastCopVersionOffline: boolean | undefined = false;
		if (selectedYear && selectedYear?.length > 0) {
			const year: ProducerYear = selectedYear[0];
			const rmVersions = sortArray(
				year?.riskMarketingVersions,
				'modifiedDate',
				true
			);
			latestRMVersion =
				rmVersions?.length > 0 ? String(rmVersions[0]?.version) : '0';
			rmlastCopVersionOffline = year.rmlastCopVersionOffline;
		}
		return {
			latestRMVersion: latestRMVersion,
			rmlastCopVersionOffline: rmlastCopVersionOffline,
		};
	};

	const isCOPDataModified = useSelector(
		(state: GlobalState) => state.coa.isDataModified
	);

	const [navigationURL, setNavigationURL] = useState('');
	const [openModal, setOpenModal] = useState<boolean>(false);

	const dispatch = useDispatch();

	const modalBody = (
		<div>
			<ConfirmModal
				message="Changes have not been saved.Click CANCEL to return and save changes or CONTINUE to discard changes."
				closeModal={(yes: boolean) => {
					confirmReset(yes);
				}}
				label="CANCEL"
			/>
		</div>
	);
	const confirmReset = (yes: boolean) => {
		setOpenModal(false);
		if (yes) {
			dispatch(removeSelectedCOAScehma());
			history.push(navigationURL);
		}
	};

	const navigation_click_handler = (step: {
		label: string;
		id: enumSteps;
		route: string;
	}) => {
		let path = '';
		switch (step.id) {
			case enumSteps.Producer:
				path = step.route;
				break;
			case enumSteps.Years:
				if (producerIdentifier)
					path = step.route + `/${selectedProducer.producerIdentifier}`;
				break;
			case enumSteps.COP:
				if (chartOfAccountsVersion && chartOfAccountsVersion != '0')
					if (yearData.year) {
						// year will be null on refresh
						path = `/home/producer/cop/${selectedProducer.producerIdentifier}/${yearData.year.year}/${yearData.year.rmlastCopVersion}/${yearData.year.rmlastCoaVersion}/entry`;
					} else {
						path = `/home/producer/cop/${selectedProducer.producerIdentifier}/${year}/${revisionIdentifier}/${chartOfAccountsVersion}/entry`;
					}
				break;
			case enumSteps.RM: {
				if (year) {
					// year will be null on refresh
					/// get path only if th value of year is available
					const rmDetails = latestRMVersion(selectedProducer, year);
					if (yearData.year) {
						path = `/home/producer/rm/${selectedProducer.producerIdentifier}/${yearData.year.year}/${yearData.year.rmlastCopVersion}/${yearData.year.rmlastCoaVersion}/${yearData.year.rmlastUpdatedVersion}/entry`;
					} else {
						path = `/home/producer/rm/${producerIdentifier}/${year}/${revisionIdentifier}/${chartOfAccountsVersion}/${rmDetails?.latestRMVersion}/entry`;
					}
					// `/home/producer/rm/${producer.producerIdentifier}/${item.year}/${item.rmlastCopVersion}/${item.rmlastCoaVersion}/${item.rmlastUpdatedVersion}/entry`
				}
				break;
			}
		}
		if (path) {
			if (COP_RM && isCOPDataModified) {
				setNavigationURL(path);
				setOpenModal(true);
			} else {
				dispatch(removeSelectedCOAScehma());
				history.push(path);
			}
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			{/* Commented due to issue in COP Save:: infinite loop >> visit later */}
			<Prompt
				when={
					COP_RM && isCOPDataModified && !openModal && !props.isSavedInitiated
				}
				message={(location: any) => {
					return `Are you sure you want to go to ${location.pathname}`;
				}}
			/>
			<div className={classes.navigationContainer}>
				<p className={classes.navigationHeader}>SECTIONS</p>
				<Stepper
					classes={{ root: classes.navigation }}
					activeStep={currentActiveStep}
					orientation="vertical"
				>
					{steps.map((step, index) => (
						<Step completed={false} key={step.label}>
							<StepLabel
								StepIconComponent={(props: String) => {
									const icons = {
										1: 'P',
										2: 'Y',
										3: 'C',
										4: 'R',
									};
									return (
										<div
											className={`${classes.stepIconContainer} ${
												index == currentActiveStep ? classes.activeStepIcon : ''
											}`}
										>
											{icons[props.icon]}
										</div>
									);
								}}
								classes={{
									labelContainer: classes.stepperLebelCollapse,
									label: `${
										step.enabled
											? classes.stepLebel
											: classes.stepLebelNoPointer
									}`,
									active: classes.stepLebelActive,
									iconContainer: `${
										index == currentActiveStep
											? `${classes.navIconContainer} ${classes.navIconActiveColor}`
											: classes.navIconContainer
									}`,
								}}
								onClick={event => {
									navigation_click_handler(step);
								}}
							>
								<span className={classes.navigationContent}>{step.label}</span>
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
			{openModal && (
				<Modal
					open={openModal}
					onClose={() => {
						setOpenModal(false);
					}}
					body={modalBody}
				></Modal>
			)}
		</MuiThemeProvider>
	);
};

export default Navigation;
