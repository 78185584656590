import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ErrorBoundary, Rehydrated } from './hoc';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './store';
import './styles/main.scss';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename="/">
			<ErrorBoundary>
				<Rehydrated>
					<App />
				</Rehydrated>
			</ErrorBoundary>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
