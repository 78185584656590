import { REHYDRATE_STORE } from './actions';
import { ActionCreator } from 'redux';

interface IAuthAction {
	type: string;
}

const rehydrateStore: ActionCreator<IAuthAction> = () => {
	return {
		type: REHYDRATE_STORE,
	};
};

export { rehydrateStore };
