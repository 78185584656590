import React, { FC, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import classes from '../../styles/layout/cards.module.scss';
import { CardThreedot } from '../../hoc';

interface Props extends RouteComponentProps {
	children: ReactNode | Element;
}

const CardHeader: FC<Props> = (props: Props) => {
	return (
		<>
			<div className={classes.header}>
				<div>{props.children}</div>
			</div>
		</>
	);
};

export default withRouter(CardHeader);
