import { GenericSchema } from './generic.schema';
import { MarketPrice } from './market-price.schema';

export interface CostOfProduction {
	identifier: number;
	version: number;
	status: string;
	modifiedDate: string;
	notes: string;
	archived: boolean;
	type?: any;
	key?: any;
	year?: string;
	costOfProductionVersion?: number;
	chartOfAccountsVersion?: number;
	journalEntry?: GenericSchema;
	isOffline?: boolean;
	isSelected?: boolean;
	effectiveDuration?: string;
	startMonth?: string;
	isOfflineAdded?: boolean;
}

export interface RiskManagement {
	identifier: number;
	version: number;
	status: string;
	modifiedDate: string;
	notes: string;
	archived: boolean;
	key?: any;
	type?: any;
	year?: string;
	costOfProductionVersion?: number;
	chartOfAccountsVersion?: number;
	journalEntry?: GenericSchema;
	isOffline?: boolean;
	isSelected?: boolean;
	isOfflineAdded?: boolean;
	marketPriceList?: Array<MarketPrice>;
}

export interface ProducerYear {
	row: CostOfProduction;
	year: string;
	costOfProductionVersions: Array<CostOfProduction>;
	riskMarketingVersions: Array<RiskManagement>;
	copstatus?: string;
	coplastupdated?: string;
	rmstatus?: string;
	rmlastupdated?: string;
	lastupdated?: string;
	lastupdatedVersion?: number;
	lastupdatedVersionIdentifier?: number;
	lastupdatedType?: string;
	lastupdatedSchemaIdentifier?: number;
	status?: string;
	key: number;
	isOffline: boolean;
	rmlastCopVersion?: number;
	rmlastCoaVersion?: number;
	rmlastUpdatedVersion?: number;
	rmlastCopVersionOffline?: boolean;
	isOfflineChanges?: boolean;
}

export class Producer {
	id: string = '';
	producerIdentifier: string = '';
	farmId: string = '';
	farmName: string = '';
	contact: string = '';
	dairyBusinessAdvisor: string = '';
	riskManagementPermission: string = '';
	costOfProductionPermission: string = '';
	milkshed: string = '';
	lastModificationDate: string = '';
	versions: Array<ProducerYear> = [];
}
