import React from 'react';
import classes from '../../../../../styles/layout/login.module.scss';
interface IProp {
	closeModal(yes: boolean): void;
	message: string;
	text: string;
}
type Props = IProp & any;

function ConfirmModal(props: Props) {
	return (
		<div className={classes.confirmbox} data-testid="confirm-modal">
			<div className={classes.confirmtext}>{props.message}</div>
			<div className={classes.contentJustifyCenter}>
				<div className={classes.contentPlaceInlineFlex}>
					<button
						data-testid="modal-btn-yes"
						className={`${classes.btnFill} ${classes.btnDefault} ${classes.marginRight20}`}
						onClick={evt => {
							evt.stopPropagation();
							props.closeModal(true);
						}}
					>
						CONTINUE
					</button>
					<button
						data-testid="modal-btn-no"
						className={`${classes.btnoutline} ${classes.btnDefault} ${classes.marginRight20}`}
						onClick={evt => {
							evt.stopPropagation();
							props.closeModal(false);
						}}
					>
						{props.label}
					</button>
				</div>
			</div>
		</div>
	);
}
export default ConfirmModal;
