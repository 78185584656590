import {
	makeStyles,
	Modal,
	createStyles,
	Backdrop,
	Fade,
	Theme,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(2, 4, 3),
			borderRadius: '10px',
			'@media screen and (max-width:769px)': {
				padding: theme.spacing(2, 2, 3),
			},
		},
	})
);

interface IProp {
	body: any;
	open: boolean;
	onClose(): void;
}
type Props = IProp;
function CustomModal(props: Props) {
	const classes = useStyles();
	const body = (
		<Fade in={props.open}>
			<div className={classes.paper}>{props.body}</div>
		</Fade>
	);
	return (
		<Modal
			data-testid="modalui"
			className={classes.modal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			{...props}
		>
			{body}
		</Modal>
	);
}

export default CustomModal;
