class DocumentReference {
	typeCode: string = '';
	identifier: string;
	constructor(typeCode: string, identifier: string) {
		this.typeCode = typeCode;
		this.identifier = identifier;
	}
}

class Data {
	content: number = 0;
	typeCode: string = '';
	constructor(content: number, typeCode: string) {
		this.content = content;
		this.typeCode = typeCode;
	}
}

class Code {
	content: string = '';
	typeCode: string = '';
	constructor(content: string, typeCode: string) {
		this.content = content;
		this.typeCode = typeCode;
	}
}

class Identifier {
	identifier: number;
	constructor(id: number) {
		this.identifier = id;
	}
}

class ExtensionSchema {
	typeCode: string = '';
	amount: Array<Data> = [];
	code: Array<Code> = [];

	constructor(typeCode: string, amount: Array<Data>) {
		this.typeCode = typeCode;
		this.amount = amount;
	}
}

export class PositionSummary {
	typeCode: string = 'Summary';
	identifierSet: Identifier;
	quantity: Array<Data>;
	extension: ExtensionSchema;

	constructor(typeCode: string, subTypeCode: string, identifier: number) {
		this.typeCode = typeCode;
		this.identifierSet = { identifier: identifier };
		(this.quantity = [
			{ content: 0, typeCode: 'Number of Contracts' },
			{ content: 0, typeCode: 'Volume' },
		]),
			(this.quantity = [
				{ content: 0, typeCode: 'Number of Contracts' },
				{ content: 0, typeCode: 'Volume' },
			]);
		this.extension = new ExtensionSchema(subTypeCode, [
			{ content: 0, typeCode: 'Strike' },
			{ content: 0, typeCode: 'Premium' },
			{ content: 0, typeCode: 'Net' },
			{ content: 0, typeCode: 'Amount' },
		]);
	}
}

export class PeriodSummary {
	typeCode: string = 'Summary';
	identifierSet: Identifier;
	quantity: Array<Data>;
	extension: ExtensionSchema;

	constructor(typeCode: string, subTypeCode: string, identifier: number) {
		this.typeCode = typeCode;
		this.identifierSet = { identifier: identifier };
		(this.quantity = []),
			(this.extension = new ExtensionSchema(subTypeCode, [
				{ content: 0, typeCode: 'Amount' },
			]));
	}
}

export class PeriodSummaryForForecast extends PeriodSummary {
	percentage: Data;
	documentReference: DocumentReference;

	constructor(
		typeCode: string,
		subTypeCode: string,
		identifier: number,
		docType: string,
		docId: string,
		codeType: string = '',
		codeContent: string = '',
		defaultPercent: number = 100
	) {
		super(typeCode, subTypeCode, identifier);
		this.quantity = [
			{ content: 0, typeCode: 'Lactating Cows' },
			{ content: 0, typeCode: 'Milk per Cow per Day' },
			{ content: 0, typeCode: 'CWTS per Month' },
			{ content: 0, typeCode: 'Number of Contracts' },
			{ content: 0, typeCode: 'Volume' },
			{ content: 0, typeCode: 'Per CWT' },
		];
		this.documentReference = { typeCode: docType, identifier: docId };

		this.percentage = new Data(defaultPercent, 'Allocation Percent');
		this.extension.amount = [
			...this.extension.amount,
			{ content: 0, typeCode: 'Forecast Milk income' },
			{ content: 0, typeCode: 'Forecast Amount' },
			{ content: 0, typeCode: 'Strike' },
			{ content: 0, typeCode: 'Premium' },
			{ content: 0, typeCode: 'Forecast Per CWT' },
			{ content: 0, typeCode: 'Forecast Per Cow Per year' },
			{ content: 0, typeCode: 'Net' },
		];

		this.extension.code = [new Code(codeContent, codeType)];
	}
}
