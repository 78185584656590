import { GenericSchema } from '..';
import {
	CLASS3_BREAKEVEN_ID,
	CLASS4_BREAKEVEN_ID,
	COMMODITY_SUMMARY,
	Comm_Divisor_Dict,
	Comm_Multiplier_Dict,
	Comm_SymbolTo_Volume_Dict,
	FULL_MONTHS,
	RM_COMMODITIES,
	RM_OPERATING_FORECAST_SECTION_CODES,
	RM_OPTIONS,
	RM_SECTION_CODES,
	RM_TYPECODES,
	SECTION_CODES,
	SECTION_TYPE_CODE,
} from '../../common/constants';
import {
	PositionSourceType,
	VolumeInOneContract,
} from '../../common/constants/madcap.constants';
import {
	getMonthFromString,
	getNumber,
	getPropertyIndex,
} from '../../common/utils';
import { Position_DICTIONARY } from '../../store/actions/actions';
interface IAverageCalculate {
	evaluate(pmlist: PositionModel[]): void;
}
interface IJsonSerializer {
	fromJson(jsonObject: any): void;
	toJson(): any;
}

const PostionEvents = {
	CALCULATION_UPDATED: 'Calculation updated',
};
const typeCodeIdentifierReducer = (arr: Array<any>) => {
	return arr.reduce((last: any, item: any) => {
		last[item.typeCode.replace(/ /g, '_')] = item.identifier;
		return last;
	}, {});
};
const typeCodeIdentifierRestore = (obj: any) => {
	var list = [];
	for (var key in obj) {
		list.push({
			typeCode: key.replace(/_/g, ' '),
			identifier: obj[key],
		});
	}
	return list;
};

const typeCodeContentReducer = (arr: Array<any>) => {
	return arr.reduce((last: any, item: any) => {
		last[item.typeCode.replace(/ /g, '_')] = isNaN(parseFloat(item.content))
			? item.content
			: parseFloat(item.content);
		return last;
	}, {});
};

const typeCodeContentRestore = (obj: any) => {
	var list = [];
	for (var key in obj) {
		list.push({
			typeCode: key.replace(/_/g, ' '),
			content: obj[key],
		});
	}
	return list;
};

export enum PositionEditFieldType {
	Strike = 'Strike',
	NoOfContracts = 'NoOfContracts',
	AdminFee = 'AdminFee',
	Premium = 'Premium',
	MadCap_Reference = 'MadCap_Reference',
}

export class PositionContainer implements IJsonSerializer {
	periodSettlePrice: number = 1;
	typeCode: string = '';
	code: { Commodity: string } = { Commodity: '' };
	accountingPeriod: {
		identifier: string;
		periodIdentifier: string;
		year: string;
	} = { identifier: '', periodIdentifier: '', year: '' };
	positionList: PositionModel[] = [];
	constructor() {
		this.positionNotificationHandler = this.positionNotificationHandler.bind(
			this
		);
		this.returnMarketPrice = this.returnMarketPrice.bind(this);
	}

	fromJson(jsonObject: any): void {
		//console.log("typeCode ---", jsonObject.typeCode);

		this.typeCode = jsonObject.typeCode;
		this.code = typeCodeContentReducer(jsonObject.extension.code);
		this.accountingPeriod = jsonObject.extension.accountingPeriod[0];
		jsonObject.extension.distribution.forEach((positionJson: any) => {
			//console.log("typeCode ---", positionJson.typeCode);
			if (positionJson.typeCode == 'Summary') {
				const summaryModel = new PositionSummaryModel();
				summaryModel.fromJson(positionJson);
				summaryModel.getPeriodSettlePrice = this.returnMarketPrice;
				/// set the previous settled price for the entiry
				if (
					summaryModel.extension.typeCode == COMMODITY_SUMMARY.CONTRACT_SETTLE
				) {
					this.periodSettlePrice = summaryModel.extension.amount.Strike;
				}
				this.positionList.push(summaryModel);
			} else {
				if (
					positionJson.typeCode == RM_OPTIONS.CEILING &&
					positionJson.extension.typeCode == RM_TYPECODES.MTM_FUTURES_POSITION
				) {
					const netCeiling = new NetCeilingMTM();
					netCeiling.fromJson(positionJson);
					this.positionList.push(netCeiling);
				} else if (
					positionJson.typeCode == RM_OPTIONS.FLOOR &&
					positionJson.extension.typeCode == RM_TYPECODES.MTM_FUTURES_POSITION
				) {
					const netFloorMTM = new NetFloorMTM();
					netFloorMTM.fromJson(positionJson);
					this.positionList.push(netFloorMTM);
				} else if (
					positionJson.typeCode == RM_OPTIONS.CONTRACT &&
					positionJson.extension.typeCode == RM_TYPECODES.MTM_FUTURES_POSITION
				) {
					const netcontractMTM = new NetContractMTM();
					netcontractMTM.fromJson(positionJson);
					this.positionList.push(netcontractMTM);
				} else if (
					positionJson.typeCode == RM_OPTIONS.COLLAR &&
					positionJson.extension.typeCode == RM_TYPECODES.MTM_FUTURES_POSITION
				) {
					const netCollarMTM = new NetCollarMTM();
					netCollarMTM.fromJson(positionJson);
					this.positionList.push(netCollarMTM);
				} else {
					const pm = new PositionModel();
					// pm.notifyChange.addListener(
					// 	PostionEvents.CALCULATION_UPDATED,
					// 	this.positionNotificationHandler
					// );
					pm.volumeInOneContract =
						Comm_SymbolTo_Volume_Dict[this.code.Commodity];
					pm.divisor = Comm_Divisor_Dict[this.code.Commodity];
					pm.multiplier = Comm_Multiplier_Dict[this.code.Commodity];
					pm.getPeriodSettlePrice = this.returnMarketPrice;
					pm.fromJson(positionJson);
					this.positionList.push(pm);
				}
			}
			this.checkOptionSummary();
		});
	}

	returnMarketPrice() {
		return this.periodSettlePrice;
	}

	checkOptionSummary() {
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.CEILING, NetCeilingMTM);
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.COLLAR, NetCollarMTM);
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.CONTRACT, NetContractMTM);
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.FLOOR, NetFloorMTM);
	}

	checkOptionsSummaryObjectForType(typeCode: string, type: any) {
		if (
			this.positionList.filter(
				item => item instanceof type && item.typeCode == typeCode
			).length > 0
		) {
			const netMtmFloorList = this.positionList.filter(
				item => item instanceof type
			);
			// delete all the additional item
			if (netMtmFloorList.length > 1) {
				for (let i = 1; i < netMtmFloorList.length; i++) {
					const currMtmFloorObj = netMtmFloorList[i];
					this.positionList.splice(
						this.positionList.indexOf(currMtmFloorObj),
						1
					);
				}
			}

			// get a floor instance and call positionNotification
			const foundItem = this.positionList.find(
				item => item instanceof PositionModel && item.typeCode == typeCode
			);
			if (foundItem) {
				this.positionNotificationHandler(foundItem);
			}
		}
	}

	positionNotificationHandler(data: PositionModel) {
		console.log('Listenting to changes data', data);
		// data.typeCode==
		// how to access model of ceiling summary, floor summary, collar amd contract

		// get summary objects for calculation via this.positionlist.filter(item=> typeof(item) == "SummaryModel" and RM Types)
		if (data.typeCode == RM_OPTIONS.FLOOR) {
			let mtmfloor: any = this.positionList.find(
				item => item instanceof NetFloorMTM
			);
			if (!mtmfloor) {
				mtmfloor = new NetFloorMTM();
				this.positionList.push(mtmfloor);
			}
			mtmfloor.evaluate(
				this.positionList.filter(
					item =>
						item.typeCode == RM_OPTIONS.FLOOR &&
						item.extension.typeCode == RM_OPTIONS.FLOOR
				)
			);
		} else if (data.typeCode == RM_OPTIONS.CEILING) {
			let mtmceiling: any = this.positionList.find(
				item => item instanceof NetCeilingMTM
			);
			if (!mtmceiling) {
				mtmceiling = new NetCeilingMTM();
				this.positionList.push(mtmceiling);
			}
			mtmceiling.evaluate(
				this.positionList.filter(
					item =>
						item.typeCode == RM_OPTIONS.CEILING &&
						item.extension.typeCode == RM_OPTIONS.CEILING
				)
			);
		} else if (data.typeCode == RM_OPTIONS.COLLAR) {
			let mtmcollar: any = this.positionList.find(
				item => item instanceof NetCollarMTM
			);
			if (!mtmcollar) {
				mtmcollar = new NetCollarMTM();
				this.positionList.push(mtmcollar);
			}
			mtmcollar.evaluate(
				this.positionList.filter(item => item.typeCode == RM_OPTIONS.COLLAR)
			);
		} else if (data.typeCode == RM_OPTIONS.CONTRACT) {
			let mtmContract: any = this.positionList.find(
				item => item instanceof NetContractMTM
			);
			if (!mtmContract) {
				mtmContract = new NetContractMTM();
				this.positionList.push(mtmContract);
			}
			console.log(
				'mtmContract.evaluate',
				mtmContract.evalutate,
				mtmContract instanceof NetContractMTM,
				typeof mtmContract.evaluate
			);
			if (mtmContract.evaluate) {
				mtmContract.evaluate(
					this.positionList.filter(
						item =>
							item.typeCode == RM_OPTIONS.CONTRACT &&
							item.extension.typeCode == RM_OPTIONS.CONTRACT
					)
				);
			}
		}

		const obj = this.positionList
			? this.positionList.filter(
					item => item.extension.typeCode == COMMODITY_SUMMARY.HEDGE_YIELD
			  )
			: null;

		console.log('', obj);
	}

	toJson() {
		return {
			typeCode: this.typeCode,
			extension: {
				code: typeCodeContentRestore(this.code),
				accountingPeriod: [this.accountingPeriod],
				distribution: this.positionList.map(item => item.toJson()),
			},
		};
	}

	deletePosition(positionModel: PositionModel) {
		if (this.positionList.indexOf(positionModel) != -1) {
			this.positionList.splice(this.positionList.indexOf(positionModel), 1);
			this.checkOptionsSummaryObjectForType(
				positionModel.typeCode,
				positionModel.typeCode == RM_OPTIONS.CEILING
					? NetCeilingMTM
					: positionModel.typeCode == RM_OPTIONS.COLLAR
					? NetCollarMTM
					: positionModel.typeCode == RM_OPTIONS.CONTRACT
					? NetContractMTM
					: positionModel.typeCode == RM_OPTIONS.FLOOR
					? NetFloorMTM
					: ''
			);
		}
	}

	addFloor() {
		const pm = new PositionModel();
		pm.typeCode = RM_OPTIONS.FLOOR;
		pm.extension.typeCode = RM_OPTIONS.FLOOR;
		pm.quantity.Number_of_Contracts = 0;
		pm.volumeInOneContract = Comm_SymbolTo_Volume_Dict[this.code.Commodity];
		pm.divisor = Comm_Divisor_Dict[this.code.Commodity];
		pm.multiplier = Comm_Multiplier_Dict[this.code.Commodity];
		pm.quantity.Volume = 0;

		pm.getPeriodSettlePrice = this.returnMarketPrice;
		pm.positionReferences.Position_Source = PositionSourceType.Manual;

		this.positionList.push(pm);

		pm.setAdminFee(0);
		pm.setStrike(0);
		pm.setPremium(0);
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.FLOOR, NetFloorMTM);
	}

	addCeiling() {
		const pm = new PositionModel();
		pm.typeCode = RM_OPTIONS.CEILING;
		pm.extension.typeCode = RM_OPTIONS.CEILING;
		pm.quantity.Number_of_Contracts = 0;
		pm.getPeriodSettlePrice = this.returnMarketPrice;
		pm.volumeInOneContract = Comm_SymbolTo_Volume_Dict[this.code.Commodity];
		pm.quantity.Volume = 0;
		pm.divisor = Comm_Divisor_Dict[this.code.Commodity];
		pm.multiplier = Comm_Multiplier_Dict[this.code.Commodity];
		pm.positionReferences.Position_Source = PositionSourceType.Manual;

		this.positionList.push(pm);

		pm.setAdminFee(0);
		pm.setStrike(0);
		pm.setPremium(0);
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.CEILING, NetCeilingMTM);
	}

	addContract() {
		const pm = new PositionModel();
		pm.typeCode = RM_OPTIONS.CONTRACT;
		pm.extension.typeCode = RM_OPTIONS.CONTRACT;
		pm.quantity.Number_of_Contracts = 0;
		pm.getPeriodSettlePrice = this.returnMarketPrice;
		pm.volumeInOneContract = Comm_SymbolTo_Volume_Dict[this.code.Commodity];
		pm.divisor = Comm_Divisor_Dict[this.code.Commodity];
		pm.multiplier = Comm_Multiplier_Dict[this.code.Commodity];
		pm.quantity.Volume = 0;
		pm.positionReferences.Position_Source = PositionSourceType.Manual;
		this.positionList.push(pm);

		pm.setAdminFee(0);
		pm.setStrike(0);
		pm.setPremium(0);
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.CONTRACT, NetContractMTM);
	}

	addCollar() {
		const length = this.positionList.length;
		const pm = new PositionModel();
		pm.identifierSet = { identifier: length };
		pm.typeCode = RM_OPTIONS.COLLAR;
		pm.extension.typeCode = RM_OPTIONS.FLOOR;
		pm.quantity.Number_of_Contracts = 0;
		pm.quantity.Volume = 0;
		pm.getPeriodSettlePrice = this.returnMarketPrice;
		pm.volumeInOneContract = Comm_SymbolTo_Volume_Dict[this.code.Commodity];
		pm.divisor = Comm_Divisor_Dict[this.code.Commodity];
		pm.multiplier = Comm_Multiplier_Dict[this.code.Commodity];
		pm.positionReferences.Position_Source = PositionSourceType.Manual;

		this.positionList.push(pm);

		pm.setAdminFee(0);
		pm.setStrike(0);
		pm.setPremium(0);

		const pm1 = new PositionModel();
		pm1.identifierSet = { identifier: length };

		pm1.typeCode = RM_OPTIONS.COLLAR;
		pm1.extension.typeCode = RM_OPTIONS.CEILING;
		pm1.quantity.Number_of_Contracts = 0;
		pm1.getPeriodSettlePrice = this.returnMarketPrice;
		pm1.volumeInOneContract = Comm_SymbolTo_Volume_Dict[this.code.Commodity];

		pm1.divisor = Comm_Divisor_Dict[this.code.Commodity];
		pm1.multiplier = Comm_Multiplier_Dict[this.code.Commodity];

		// pm1.notifyChange.addListener(
		// 	PostionEvents.CALCULATION_UPDATED,
		// 	this.positionNotificationHandler
		// );
		pm1.positionReferences.Position_Source = PositionSourceType.Manual;

		this.positionList.push(pm1);

		pm1.setAdminFee(0);
		pm1.setStrike(0);
		pm1.setPremium(0);
		this.checkOptionsSummaryObjectForType(RM_OPTIONS.COLLAR, NetCollarMTM);
	}
}

export class PositionModel implements IJsonSerializer {
	getPeriodSettlePrice: () => number = () => {
		// if (store.getState()?.marketprice) {
		// 	const MARKET_PRICE = store.getState()?.marketprice?.list;
		// 	const marketpriceList = MARKET_PRICE.filter(
		// 		(item: MarketPrice) => item.symbolCode === 'commodity'
		// 	);
		// 	const list = getUniqueLatestMarketPriceFromList(marketpriceList);
		// 	let price: MarketPrice = new MarketPrice();
		// 	list.forEach((item: MarketPrice) => {
		// 		if (
		// 			item.month.toString() == 'month' &&
		// 			item.year.toString() == 'year'
		// 		) {
		// 			price = item;
		// 		}
		// 	});
		// 	return price.settlementPriceAmount;
		// }
		return Number.POSITIVE_INFINITY;
	};
	typeCode: string = '';
	divisor = 100;
	multiplier = 1;
	validation = {
		error: true,
		message: 'Should either have MadCap Reference Or Comment.',
	};
	volumeInOneContract = VolumeInOneContract;
	quantity: { Number_of_Contracts: number; Volume: number };
	identifierSet: { identifier: string | number };
	positionReferences: {
		Position_Source: string;
		MadCap_Reference: string;
		Order_Status: string;
		Position_Note: string;
		MadCap_Ref_OID: string;
	};

	// notifyChange: EventEmitter = new EventEmitter();

	extension: {
		typeCode: string;
		amount: {
			Admin_Fee: number;
			Net: number;
			Premium: number;
			Strike: number;
		};
	};

	constructor() {
		this.extension = {
			typeCode: '',
			amount: { Admin_Fee: 0, Net: 0, Premium: 0, Strike: 0 },
		};
		this.quantity = { Number_of_Contracts: 0, Volume: 0 };
		this.positionReferences = {
			Position_Source: PositionSourceType.Manual,
			MadCap_Reference: '',
			Order_Status: 'Manual',
			Position_Note: '',
			MadCap_Ref_OID: '',
		};
		this.identifierSet = { identifier: 0 };
		this.setStrike = this.setStrike.bind(this);
		this.setNumberOfContracts = this.setNumberOfContracts.bind(this);
		this.setAdminFee = this.setAdminFee.bind(this);
		this.setPremium = this.setPremium.bind(this);
	}

	setNumberOfContracts(value: number) {
		let multiplier = 1;
		switch (this.typeCode) {
			case RM_OPTIONS.FLOOR:
				multiplier = -1 * this.volumeInOneContract;
				break;
			case RM_OPTIONS.CEILING:
				multiplier = this.volumeInOneContract;
				break;
			case RM_OPTIONS.CONTRACT:
				multiplier = this.volumeInOneContract;
				break;
			case RM_OPTIONS.COLLAR:
				if (this.extension.typeCode == RM_OPTIONS.FLOOR) {
					if (this.getPeriodSettlePrice() < this.extension.amount.Strike) {
						multiplier = this.volumeInOneContract * -1;
					} else {
						multiplier = 0;
					}
				} else if (this.extension.typeCode == RM_OPTIONS.CEILING) {
					if (this.getPeriodSettlePrice() > this.extension.amount.Strike) {
						multiplier = this.volumeInOneContract * 1;
					} else {
						multiplier = 0;
					}
				}
				break;
		}

		//if (value != this.quantity.Number_of_Contracts) {
		this.quantity.Number_of_Contracts = value;
		this.quantity.Volume = value * multiplier;
		this.calculateNet();
		//}
	}
	setAdminFee(value: number) {
		if (value != this.extension.amount.Admin_Fee) {
			this.extension.amount.Admin_Fee = value;
			this.calculateNet();
		}
	}

	setPremium(value: number) {
		if (value != this.extension.amount.Premium) {
			this.extension.amount.Premium = value;
			this.calculateNet();
		}
	}

	setStrike(value: number) {
		if (value != this.extension.amount.Strike) {
			this.extension.amount.Strike = value;
			if (this.typeCode == RM_OPTIONS.COLLAR) {
				this.setNumberOfContracts(this.quantity.Number_of_Contracts);
			}
			this.calculateNet();
		}
	}

	fromJson(jsonObject: any): void {
		this.typeCode = jsonObject.typeCode;
		this.quantity = typeCodeContentReducer(jsonObject.quantity);
		this.extension = {
			typeCode: jsonObject.extension.typeCode,
			amount: typeCodeContentReducer(jsonObject.extension.amount),
		};

		// admin fee was added in phase 2 older record does not have value for it
		if (isNaN(this.extension.amount.Admin_Fee)) {
			this.extension.amount.Admin_Fee = 0;
		}

		if (
			jsonObject.positionReferences &&
			jsonObject.positionReferences.length > 0
		) {
			this.positionReferences = typeCodeIdentifierReducer(
				jsonObject.positionReferences
			);
		}
		this.identifierSet = jsonObject.identifierSet;
		//console.log('After parsing log this', this);
		this.calculateNet();
	}

	toJson() {
		return {
			typeCode: this.typeCode,
			identifierSet: this.identifierSet,
			positionReferences: typeCodeIdentifierRestore(this.positionReferences),
			quantity: typeCodeContentRestore(this.quantity),
			extension: {
				typeCode: this.extension?.typeCode,
				amount: typeCodeContentRestore(this.extension?.amount),
			},
		};
	}
	getNetValueFromSettleLessThanStrikeFloor() {
		if (this.getPeriodSettlePrice() < this.extension.amount.Strike) {
			return (
				((this.getPeriodSettlePrice() -
					this.extension.amount.Strike +
					this.extension.amount.Premium +
					this.extension.amount.Admin_Fee) *
					this.quantity.Volume) /
				this.divisor
			);
		} else {
			return (
				((this.extension.amount.Premium + this.extension.amount.Admin_Fee) *
					this.quantity.Volume) /
				this.divisor
			);
		}
	}

	getNetValueFromSettleGreaterThanStrikeCeiling() {
		if (
			this.getPeriodSettlePrice() > this.extension.amount.Strike &&
			this.extension.amount.Strike > 0
		) {
			return (
				((this.getPeriodSettlePrice() -
					this.extension.amount.Strike -
					(this.extension.amount.Premium + this.extension.amount.Admin_Fee)) *
					this.quantity.Volume) /
				this.divisor
			);
		} else {
			return (
				(-1 *
					(this.extension.amount.Premium + this.extension.amount.Admin_Fee) *
					this.quantity.Volume) /
				this.divisor
			);
		}
	}

	getNetValueCeilingCollar() {
		if (
			this.getPeriodSettlePrice() > this.extension.amount.Strike &&
			this.extension.amount.Strike > 0
		) {
			return (
				(this.getPeriodSettlePrice() -
					this.extension.amount.Strike -
					(this.extension.amount.Premium - this.extension.amount.Admin_Fee)) *
				this.quantity.Number_of_Contracts *
				this.multiplier
			);
		} else {
			return (
				-1 *
				(this.extension.amount.Premium - this.extension.amount.Admin_Fee) *
				this.quantity.Number_of_Contracts *
				this.multiplier
			);
		}
	}

	getNetValueFromFloorCollar() {
		if (this.getPeriodSettlePrice() < this.extension.amount.Strike) {
			return (
				((this.getPeriodSettlePrice() - this.extension.amount.Strike) *
					this.quantity.Number_of_Contracts *
					this.multiplier +
					(this.extension.amount.Premium + this.extension.amount.Admin_Fee) *
						this.quantity.Number_of_Contracts *
						this.multiplier) *
				-1
			);
		} else {
			return (
				this.quantity.Number_of_Contracts *
				-1 *
				this.multiplier *
				(this.extension.amount.Admin_Fee + this.extension.amount.Premium)
			);
		}
	}

	calculateNet() {
		if (this.typeCode == RM_OPTIONS.CEILING) {
			this.extension.amount.Net = this.getNetValueFromSettleGreaterThanStrikeCeiling();
		} else if (this.typeCode == RM_OPTIONS.FLOOR) {
			this.extension.amount.Net = this.getNetValueFromSettleLessThanStrikeFloor();
		} else if (this.typeCode == RM_OPTIONS.COLLAR) {
			if (this.extension.typeCode == RM_OPTIONS.CEILING) {
				this.extension.amount.Net = this.getNetValueCeilingCollar();
			} else if (this.extension.typeCode == RM_OPTIONS.FLOOR) {
				this.extension.amount.Net = this.getNetValueFromFloorCollar();
			}
		} else if (this.typeCode == RM_OPTIONS.CONTRACT) {
			this.extension.amount.Net =
				((this.extension.amount.Strike - this.getPeriodSettlePrice()) *
					this.quantity.Volume) /
					-this.divisor +
				(this.extension.amount.Admin_Fee * this.quantity.Volume) / this.divisor;
		}
		// console.log('Calculated net for', this.typeCode, this.extension.amount.Net);
		// this.notifyChange.emit(PostionEvents.CALCULATION_UPDATED, this);
	}
}

export class NetFloorMTM extends PositionModel implements IAverageCalculate {
	constructor() {
		super();
		this.typeCode = RM_OPTIONS.FLOOR;
		this.extension.typeCode = RM_TYPECODES.MTM_FUTURES_POSITION;
	}
	evaluate(pmlist: PositionModel[]): void {
		this.quantity.Volume = pmlist.reduce((total: number, item) => {
			total += item.quantity.Volume;
			return total;
		}, 0);
		this.quantity.Number_of_Contracts = pmlist.reduce((total: number, item) => {
			total += item.quantity.Number_of_Contracts;
			return total;
		}, 0);
		this.extension.amount.Net = pmlist.reduce((total: number, item) => {
			total += item.extension.amount.Net;
			return total;
		}, 0);

		const result = pmlist.reduce(
			(
				total: { contractIntoStrike: number; sumOfNoofContract: number },
				item
			) => {
				total.contractIntoStrike +=
					item.quantity.Number_of_Contracts * item.extension.amount.Strike;
				total.sumOfNoofContract += item.quantity.Number_of_Contracts;
				return total;
			},
			{ contractIntoStrike: 0, sumOfNoofContract: 0 }
		);
		this.extension.amount.Strike =
			result.contractIntoStrike / result.sumOfNoofContract;

		if (this.quantity.Number_of_Contracts > 0) {
			const result = pmlist.reduce(
				(
					total: { noOfContIntoPremium: number; sumOfNoOfContract: number },
					item
				) => {
					total.noOfContIntoPremium +=
						item.quantity.Number_of_Contracts * item.extension.amount.Premium;
					total.sumOfNoOfContract += item.quantity.Number_of_Contracts;
					return total;
				},
				{ noOfContIntoPremium: 0, sumOfNoOfContract: 0 }
			);
			this.extension.amount.Premium =
				result.noOfContIntoPremium / result.sumOfNoOfContract;

			const resultForAdminFee = pmlist.reduce(
				(
					prev: { productsumofadminFee: number; sumnoofcontract: number },
					item
				) => {
					prev.productsumofadminFee +=
						item.extension.amount.Admin_Fee * item.quantity.Number_of_Contracts;
					prev.sumnoofcontract += item.quantity.Number_of_Contracts;
					return prev;
				},
				{ productsumofadminFee: 0, sumnoofcontract: 0 }
			);
			this.extension.amount.Admin_Fee =
				resultForAdminFee.productsumofadminFee /
				resultForAdminFee.sumnoofcontract;
		} else {
			this.extension.amount.Admin_Fee = 0;
			this.extension.amount.Premium = 0;
		}
		// console.log('Evaluate MTM for ' + this.typeCode, this.extension.amount);
	}
}

export class NetCollarMTM extends PositionModel implements IAverageCalculate {
	constructor() {
		super();
		this.typeCode = RM_OPTIONS.COLLAR;
		this.extension.typeCode = RM_TYPECODES.MTM_FUTURES_POSITION;
	}
	evaluate(pmlist: PositionModel[]): void {
		this.quantity.Volume = pmlist.reduce((total: number, item) => {
			total += item.quantity.Volume;
			return total;
		}, 0);

		this.quantity.Number_of_Contracts = Number.NaN;

		this.extension.amount.Net = pmlist.reduce((total: number, item) => {
			total += item.extension.amount.Net;
			return total;
		}, 0);

		var listofFloorContracts = pmlist.filter(
			item => item.extension.typeCode == RM_OPTIONS.FLOOR
		);

		var quantity = listofFloorContracts.reduce((prev, item) => {
			prev += item.quantity.Number_of_Contracts;
			return prev;
		}, 0);

		if (quantity > 0) {
			const result = pmlist.reduce(
				(
					total: { noOfContIntoPremium: number; sumOfNoOfContract: number },
					item
				) => {
					total.noOfContIntoPremium +=
						item.quantity.Number_of_Contracts * item.extension.amount.Premium;

					if (item.extension.typeCode == RM_OPTIONS.FLOOR)
						total.sumOfNoOfContract += item.quantity.Number_of_Contracts;
					return total;
				},
				{ noOfContIntoPremium: 0, sumOfNoOfContract: 0 }
			);
			this.extension.amount.Premium =
				result.noOfContIntoPremium / result.sumOfNoOfContract;

			// summary value of premium should not be negative
			if (this.extension.amount.Premium < 0) {
				this.extension.amount.Premium *= -1;
			}

			const resultForAdminFee = pmlist.reduce(
				(
					prev: { productsumofadminFee: number; sumnoofcontract: number },
					item
				) => {
					let multiplier = 1;
					if (item.extension.typeCode == RM_OPTIONS.CEILING) multiplier = -1;

					prev.productsumofadminFee +=
						item.extension.amount.Admin_Fee *
						item.quantity.Number_of_Contracts *
						multiplier;
					if (item.extension.typeCode == RM_OPTIONS.FLOOR)
						prev.sumnoofcontract += item.quantity.Number_of_Contracts;
					return prev;
				},
				{ productsumofadminFee: 0, sumnoofcontract: 0 }
			);
			this.extension.amount.Admin_Fee =
				resultForAdminFee.productsumofadminFee /
				resultForAdminFee.sumnoofcontract;

			// Admin fee
			if (this.extension.amount.Admin_Fee < 0) {
				this.extension.amount.Admin_Fee *= -1;
			}
		}

		// console.log('Evaluate MTM for Collar', this.extension.amount);
	}
}

export class NetContractMTM extends PositionModel implements IAverageCalculate {
	constructor() {
		super();
		this.typeCode = RM_OPTIONS.CONTRACT;
		this.extension.typeCode = RM_TYPECODES.MTM_FUTURES_POSITION;
	}
	evaluate(pmlist: PositionModel[]): void {
		this.quantity.Volume = pmlist.reduce((total: number, item) => {
			total += item.quantity.Volume;
			return total;
		}, 0);
		this.quantity.Number_of_Contracts = pmlist.reduce((total: number, item) => {
			total += item.quantity.Number_of_Contracts;
			return total;
		}, 0);
		this.extension.amount.Net = pmlist.reduce((total: number, item) => {
			total += item.extension.amount.Net;
			return total;
		}, 0);

		const result = pmlist.reduce(
			(
				total: { contractIntoStrike: number; sumOfNoofContract: number },
				item
			) => {
				total.contractIntoStrike +=
					item.quantity.Number_of_Contracts * item.extension.amount.Strike;
				total.sumOfNoofContract += item.quantity.Number_of_Contracts;
				return total;
			},
			{ contractIntoStrike: 0, sumOfNoofContract: 0 }
		);
		this.extension.amount.Strike =
			result.contractIntoStrike / result.sumOfNoofContract;

		if (this.quantity.Number_of_Contracts < 0) {
			const resultofAvgAdminFee = pmlist.reduce(
				(
					prevVal: { noContractIntoAdminFee: number; sumofnoContact: number },
					item
				) => {
					prevVal.noContractIntoAdminFee +=
						item.quantity.Number_of_Contracts * item.extension.amount.Admin_Fee;
					prevVal.sumofnoContact += item.quantity.Number_of_Contracts;
					return prevVal;
				},
				{ noContractIntoAdminFee: 0, sumofnoContact: 0 }
			);

			this.extension.amount.Admin_Fee =
				resultofAvgAdminFee.noContractIntoAdminFee /
				resultofAvgAdminFee.sumofnoContact;
		}

		console.log('--Evaluate this Contract', this.quantity, this.extension);
	}
}

export class NetCeilingMTM extends PositionModel implements IAverageCalculate {
	constructor() {
		super();
		this.typeCode = RM_OPTIONS.CEILING;
		this.extension.typeCode = RM_TYPECODES.MTM_FUTURES_POSITION;
	}
	evaluate(pmlist: PositionModel[]): void {
		this.quantity.Volume = pmlist.reduce((total: number, item) => {
			total += item.quantity.Volume;
			return total;
		}, 0);
		this.quantity.Number_of_Contracts = pmlist.reduce((total: number, item) => {
			total += item.quantity.Number_of_Contracts;
			return total;
		}, 0);
		this.extension.amount.Net = pmlist.reduce((total: number, item) => {
			total += item.extension.amount.Net;
			return total;
		}, 0);

		const result = pmlist.reduce(
			(
				total: { contractIntoStrike: number; sumOfNoofContract: number },
				item
			) => {
				total.contractIntoStrike +=
					item.quantity.Number_of_Contracts * item.extension.amount.Strike;
				total.sumOfNoofContract += item.quantity.Number_of_Contracts;
				return total;
			},
			{ contractIntoStrike: 0, sumOfNoofContract: 0 }
		);
		this.extension.amount.Strike =
			result.contractIntoStrike / result.sumOfNoofContract;

		if (this.quantity.Number_of_Contracts > 0) {
			const result = pmlist.reduce(
				(
					total: { noOfContIntoPremium: number; sumOfNoOfContract: number },
					item
				) => {
					total.noOfContIntoPremium +=
						item.quantity.Number_of_Contracts * item.extension.amount.Premium;
					total.sumOfNoOfContract += item.quantity.Number_of_Contracts;
					return total;
				},
				{ noOfContIntoPremium: 0, sumOfNoOfContract: 0 }
			);
			this.extension.amount.Premium =
				result.noOfContIntoPremium / result.sumOfNoOfContract;

			const resultForAdminFee = pmlist.reduce(
				(
					prev: { productsumofadminFee: number; sumnoofcontract: number },
					item
				) => {
					prev.productsumofadminFee +=
						item.extension.amount.Admin_Fee * item.quantity.Number_of_Contracts;
					prev.sumnoofcontract += item.quantity.Number_of_Contracts;
					return prev;
				},
				{ productsumofadminFee: 0, sumnoofcontract: 0 }
			);
			this.extension.amount.Admin_Fee =
				resultForAdminFee.productsumofadminFee /
				resultForAdminFee.sumnoofcontract;
		} else {
			this.extension.amount.Admin_Fee = 0;
			this.extension.amount.Premium = 0;
		}
		// console.log('Evaluate MTM for ' + this.typeCode, this.extension.amount);
	}
}

export class PositionSummaryModel extends PositionModel {
	// implements IAverageCalculate {

	fromJson(jsonObject: any): void {
		super.fromJson(jsonObject);
		this.quantity = {
			Number_of_Contracts: 0,
			Volume_Total: 0,
			Volume_Filled: 0,
			Volume_Working: 0,
			Volume: 0,
		};
	}

	quantity: {
		Number_of_Contracts: number;
		Volume_Total: number;
		Volume_Working: number;
		Volume_Filled: number;
		Volume: number;
	};

	constructor() {
		super();
		this.quantity = {
			Number_of_Contracts: 0,
			Volume_Total: 0,
			Volume_Filled: 0,
			Volume_Working: 0,
			Volume: 0,
		};
	}

	toJson() {
		// making is any for typescipt to allow delete property
		let json: any = super.toJson();
		delete json.positionReferences;
		return json;
	}
	evaluate(
		pmlist: PositionModel[],
		payload: {
			month: string;
			year: string;
			commodity: string;
			financialYear: number;
		},
		state: any
	): void {
		let typecode = this.extension.typeCode;
		let month = payload.month,
			year = parseInt(payload.year);
		let commodity = payload.commodity;

		const getCashYieldVolume = (month: string, year: number) => {
			const isCurrentFinancialYear = (month: string, year: number) => {
				const financialYearStartmonth =
					state?.calculatedSchema[RM_SECTION_CODES.COP_DATA][
						SECTION_CODES.BASIC_INFO
					]?.accountingPeriod?.periodIdentifier || 'January';
				const numMonthsIncluded =
					state?.calculatedSchema[RM_SECTION_CODES.COP_DATA][
						SECTION_CODES.BASIC_INFO
					]?.accountingPeriod?.effectiveTimePeriod?.duration || '12';

				let financialYear = payload.financialYear;
				if (FULL_MONTHS.indexOf(financialYearStartmonth) != 0) {
					financialYear = payload.financialYear - 1;
				}

				let financialYearStart = new Date(
					Date.parse(`${financialYearStartmonth} 1, ${financialYear}`)
				);
				let endDate = new Date(
					Date.parse(`${financialYearStartmonth} 1, ${financialYear}`)
				);
				const financialYearEnd = new Date(
					endDate.setMonth(
						financialYearStart.getMonth() + Number(numMonthsIncluded)
					)
				);

				const currentDate = new Date(Date.parse(`${month} 1, ${year}`));
				if (
					currentDate >= financialYearStart &&
					currentDate < financialYearEnd
				) {
					return true;
				} else {
					return false;
				}
			};

			// get forecast CWT per month for a given month and year
			const getForecastCWTPerMonth = (month: string, year: number) => {
				const data = state?.calculatedSchema;
				let forecastByMonth =
					data[RM_SECTION_CODES.OPERATION_FORECAST][
						RM_OPERATING_FORECAST_SECTION_CODES.MONTHS
					];
				let index = getPropertyIndex(
					forecastByMonth && forecastByMonth?.length > 0
						? forecastByMonth[0]?.extension?.distribution[0]?.quantity
						: [],
					RM_TYPECODES.CWT_PER_MONTH
				);
				let requiredRow = forecastByMonth?.filter(
					(forecast: GenericSchema) =>
						forecast?.extension?.accountingPeriod[0]?.identifier.includes(
							month
						) &&
						parseInt(forecast?.extension?.accountingPeriod[0]?.year) === year
				);
				if (requiredRow?.length > 0) {
					return getNumber(
						requiredRow[0]?.extension.distribution[0]?.quantity[index]?.content
					);
				}
				return 0;
			};

			// get class weight factor
			const getClassWeight = (id: string) => {
				const data = state?.calculatedSchema;
				let bsDetail =
					data[RM_SECTION_CODES.COP_DATA][SECTION_CODES.BS_DETAIL][
						SECTION_TYPE_CODE.BASIS_ASSUMPTION
					];
				let weight = 0;
				bsDetail &&
					Object.keys(bsDetail).map((key: string) => {
						const assumptions = bsDetail[key];
						Object.keys(assumptions).map((classAssumption: string) => {
							if (assumptions[classAssumption][''][0].identifier === id) {
								weight = assumptions[classAssumption][''][0]?.amount;
							}
						});
					});
				return getNumber(weight) / 100;
			};

			let id =
				RM_COMMODITIES.CLASS4.SYMBOL === payload.commodity ? '108' : '107';
			let factor =
				RM_COMMODITIES.CHEESE.SYMBOL === payload.commodity ? 10 : 100;

			const getCashYieldVolumeByMonth = () => {
				return getCashYieldVolume(month, year - 1);
			};

			const getMonthCountByFiscalYear = (month: string) => {
				const data = state?.calculatedSchema;
				let basicInfo =
					data[RM_SECTION_CODES.COP_DATA][SECTION_CODES.BASIC_INFO];

				const financialYearStartmonth =
					basicInfo?.accountingPeriod?.periodIdentifier || 'January';
				const numMonthsIncluded =
					basicInfo?.accountingPeriod?.effectiveTimePeriod?.duration || '12';

				let financialYear = payload.financialYear;
				if (FULL_MONTHS.indexOf(financialYearStartmonth) != 0) {
					financialYear = payload.financialYear - 1;
				}

				let financialYearStart = new Date(
					Date.parse(`${financialYearStartmonth} 1, ${financialYear}`)
				);
				let endDate = new Date(
					Date.parse(`${financialYearStartmonth} 1, ${financialYear}`)
				);

				const startMonth = financialYearStart.getMonth();
				const currentMonth = getMonthFromString(month);

				if (currentMonth > startMonth) {
					return currentMonth - startMonth;
				} else {
					return 12 - startMonth + currentMonth;
				}
			};

			const getEstimatedProductionFactor = (month: string) => {
				const data = state?.calculatedSchema;
				let header = data[RM_SECTION_CODES.HEADER];
				const monthInDigit = getMonthCountByFiscalYear(month);
				let quater = Math.ceil(monthInDigit / 3);
				let quater_prod_factor = '';

				switch (quater) {
					case 1:
						quater_prod_factor = RM_TYPECODES.Q1_EST_PROD_FACTOR;
						break;

					case 2:
						quater_prod_factor = RM_TYPECODES.Q2_EST_PROD_FACTOR;
						break;

					case 3:
						quater_prod_factor = RM_TYPECODES.Q3_EST_PROD_FACTOR;
						break;

					case 4:
						quater_prod_factor = RM_TYPECODES.Q4_EST_PROD_FACTOR;
						break;

					default:
						return 0;
				}
				const index = getPropertyIndex(
					header?.extension?.measure,
					quater_prod_factor
				);
				return getNumber(header?.extension?.measure[index]?.content) / 100;
			};

			if (isCurrentFinancialYear(month, year)) {
				const forcastCWTByMonth = getForecastCWTPerMonth(month, year);
				const classWeight = getClassWeight(id);
				return +forcastCWTByMonth * factor * +classWeight;
			} else {
				const cashYieldLastYear: any = getCashYieldVolumeByMonth();
				const estimatedProductionFactor = getEstimatedProductionFactor(month);
				return cashYieldLastYear * estimatedProductionFactor;
			}
		};

		const gethedgeyieldvolume = (pmlist: PositionModel[]) => {
			const option_sum_list = pmlist?.filter(
				item =>
					item instanceof NetCeilingMTM ||
					item instanceof NetCollarMTM ||
					item instanceof NetContractMTM ||
					item instanceof NetFloorMTM
			);

			const pm_model_list = pmlist?.filter(
				item =>
					!(
						item instanceof NetCeilingMTM ||
						item instanceof NetCollarMTM ||
						item instanceof NetContractMTM ||
						item instanceof NetFloorMTM
					)
			);
			return {
				Volume_Total: option_sum_list
					? option_sum_list
							.filter(item => {
								return item.typeCode !== 'Ceiling';
							})
							.reduce((prev, item) => {
								prev += item.quantity.Volume;
								return prev;
							}, 0)
					: 0,
				Volume_Filled: pm_model_list
					? pm_model_list
							.filter(item => {
								return item.typeCode !== 'Ceiling';
							})
							.reduce((prev, item) => {
								if (
									item.positionReferences.Order_Status == 'Filled' ||
									item.positionReferences.Order_Status == 'Manual'
								) {
									prev += item.quantity.Volume;
								}
								return prev;
							}, 0)
					: 0,

				Volume_Working: pm_model_list
					? pm_model_list
							.filter(item => {
								return item.typeCode !== 'Ceiling';
							})
							.reduce((prev, item) => {
								if (item.positionReferences.Order_Status == 'Working') {
									prev += item.quantity.Volume;
								}
								return prev;
							}, 0)
					: 0,
			};
		};

		const getbreakeven = () => {
			let id = CLASS3_BREAKEVEN_ID;
			if (payload.commodity === RM_COMMODITIES.CLASS4.SYMBOL) {
				id = CLASS4_BREAKEVEN_ID;
			}
			let forecastSummary = state?.calculatedSchema[
				RM_SECTION_CODES.COP_FORECAST
			][0].extension.distribution.filter((ob: any) => {
				return ob.documentReference.identifier === id;
			})[0];

			let breakeven = forecastSummary['extension']['amount'].filter(
				(ob: any) => {
					return ob.typeCode === RM_TYPECODES.FORECAST_AMOUNT;
				}
			)[0]['content'];

			return payload.commodity === RM_COMMODITIES.CHEESE.SYMBOL
				? breakeven / 10
				: breakeven;
		};

		const gethedgeyieldnet = () => {
			const pm_model_list = pmlist.filter(
				item =>
					!(
						item instanceof NetCeilingMTM ||
						item instanceof NetCollarMTM ||
						item instanceof NetContractMTM ||
						item instanceof NetFloorMTM
					)
			);
			return pm_model_list.reduce((prev, item) => {
				if (
					item.positionReferences.Order_Status == 'Filled' ||
					item.positionReferences.Order_Status == 'Manual'
				) {
					prev += item.extension.amount.Net;
				}
				return prev;
			}, 0);
		};

		const getMilkRevenueStrike = () => {
			let cashYieldVolume = getCashYieldVolume(month, year);
			let milrevenuenet =
				cashYieldVolume > 0
					? gethedgeyieldnet() / (cashYieldVolume / 100) +
					  this.getPeriodSettlePrice()
					: 0;
			if (payload.commodity === RM_COMMODITIES.CHEESE.SYMBOL) {
				milrevenuenet =
					cashYieldVolume > 0
						? gethedgeyieldnet() / cashYieldVolume + this.getPeriodSettlePrice()
						: 0;
			}
			return milrevenuenet;
		};

		switch (typecode) {
			case COMMODITY_SUMMARY.HEDGE_YIELD:
				{
					const pm_model_list = pmlist.filter(
						item =>
							!(
								item instanceof NetCeilingMTM ||
								item instanceof NetCollarMTM ||
								item instanceof NetContractMTM ||
								item instanceof NetFloorMTM
							)
					);
					const volumeobj = gethedgeyieldvolume(pmlist);

					this.quantity.Volume_Total = volumeobj.Volume_Total;
					this.quantity.Volume_Filled = volumeobj.Volume_Filled;
					this.quantity.Volume_Working = volumeobj.Volume_Working;

					const premResult = pm_model_list.reduce((prev, item) => {
						if (
							item.positionReferences.Order_Status == 'Filled' ||
							item.positionReferences.Order_Status == 'Manual'
						) {
							let premium = item.extension.amount.Premium;
							premium = isNaN(premium) ? 0 : premium;
							prev += item.quantity.Number_of_Contracts * premium;
						}
						return prev;
					}, 0);

					if (payload.commodity === RM_COMMODITIES.CHEESE.SYMBOL) {
						this.extension.amount.Premium = 20000 * premResult;
					} else {
						this.extension.amount.Premium = 2000 * premResult;
					}

					const adminFeeResult = pm_model_list.reduce((prev, item) => {
						if (
							item.positionReferences.Order_Status == 'Filled' ||
							item.positionReferences.Order_Status == 'Manual'
						) {
							let admin = item.extension.amount.Admin_Fee;
							admin = isNaN(admin) ? 0 : admin;
							let multiplier = 1;

							if (
								item.typeCode === 'Contract' ||
								(item.typeCode === RM_OPTIONS.COLLAR &&
									item.extension.typeCode === RM_OPTIONS.CEILING)
							) {
								multiplier = -1;
							}
							prev += item.quantity.Number_of_Contracts * admin * multiplier;
						}
						return prev;
					}, 0);

					if (payload.commodity === RM_COMMODITIES.CHEESE.SYMBOL) {
						this.extension.amount.Admin_Fee = 20000 * adminFeeResult;
					} else {
						this.extension.amount.Admin_Fee = 2000 * adminFeeResult;
					}

					this.extension.amount.Net = gethedgeyieldnet();
				}
				break;
			case COMMODITY_SUMMARY.NET_EXPOSURE:
				if (payload.commodity === RM_COMMODITIES.CLASS3.SYMBOL) {
					let cheesepmlist =
						state.calculatedSchema[Position_DICTIONARY][
							RM_COMMODITIES.CHEESE.SYMBOL
						][payload.month + '_' + payload.year].positionList;
					this.quantity.Volume =
						getCashYieldVolume(month, year) +
						gethedgeyieldvolume(pmlist).Volume_Total +
						cheesepmlist[0].quantity.Volume_Total * 10;

					this.extension.amount.Net =
						this.getPeriodSettlePrice() *
						((getCashYieldVolume(month, year) +
							gethedgeyieldvolume(pmlist).Volume_Filled +
							cheesepmlist[0].quantity.Volume_Filled * 10) /
							100);
				} else if (payload.commodity === RM_COMMODITIES.CLASS4.SYMBOL) {
					this.quantity.Volume =
						getCashYieldVolume(month, year) +
						gethedgeyieldvolume(pmlist).Volume_Total;

					this.extension.amount.Net =
						this.getPeriodSettlePrice() *
						((getCashYieldVolume(month, year) +
							gethedgeyieldvolume(pmlist).Volume_Filled) /
							100);
				} else {
					let class3pmlist =
						state.calculatedSchema[Position_DICTIONARY][
							RM_COMMODITIES.CLASS3.SYMBOL
						][payload.month + '_' + payload.year].positionList;
					this.quantity.Volume =
						getCashYieldVolume(month, year) * 10 +
						gethedgeyieldvolume(pmlist).Volume_Total * 10 +
						class3pmlist[0].quantity.Volume_Total;

					this.extension.amount.Net =
						(this.getPeriodSettlePrice() *
							(getCashYieldVolume(month, year) * 10 +
								gethedgeyieldvolume(pmlist).Volume_Filled * 10 +
								class3pmlist[0].quantity.Volume_Filled)) /
						10;
				}
				break;
			case COMMODITY_SUMMARY.CASH_YIELD:
				this.quantity.Volume = getCashYieldVolume(month, year);

				if (RM_COMMODITIES.CHEESE.SYMBOL === payload.commodity) {
					this.extension.amount.Net =
						this.getPeriodSettlePrice() * this.quantity.Volume;
				} else {
					this.extension.amount.Net =
						this.quantity.Volume > 0
							? this.getPeriodSettlePrice() * (this.quantity.Volume / 100)
							: 0;
				}

				break;

			case COMMODITY_SUMMARY.CL3_BREAKEVEN:
			case COMMODITY_SUMMARY.CL4_BREAKEVEN:
			case COMMODITY_SUMMARY.CHEESE_BREAKEVEN:
				this.extension.amount.Strike = getbreakeven();
				break;

			case COMMODITY_SUMMARY.CONTRACT_SETTLE:
				this.extension.amount.Strike = this.getPeriodSettlePrice();
				break;

			case COMMODITY_SUMMARY.CL3_MILK_REVENUE:
			case COMMODITY_SUMMARY.CL4_MILK_REVENUE:
			case COMMODITY_SUMMARY.CHEESE_MILK_REVENUE:
				this.extension.amount.Strike = getMilkRevenueStrike();
				this.extension.amount.Net =
					(getCashYieldVolume(month, year) / 100) *
					this.extension.amount.Strike;
				if (payload.commodity === RM_COMMODITIES.CHEESE.SYMBOL) {
					this.extension.amount.Net =
						this.extension.amount.Strike * getCashYieldVolume(month, year);
				}
				break;
			case COMMODITY_SUMMARY.NET_MARIGIN:
				this.extension.amount.Strike = getMilkRevenueStrike() - getbreakeven();
				this.extension.amount.Net =
					payload.commodity !== RM_COMMODITIES.CHEESE.SYMBOL
						? this.extension.amount.Strike *
						  (getCashYieldVolume(month, year) / 100)
						: this.extension.amount.Strike * getCashYieldVolume(month, year);

				break;
			default:
				console.log();
		}
	}
}

// export class PositionManager {
// 	constructor() {
// 		this.c = new PositionContainer();
// 	}
// 	c: PositionContainer;
// }

// window['pm'] = new PositionManager();
