import {
	COP_GET_PDF_PRINTDATA,
	COP_SAVE_PDF_PRINTDATA,
} from '../actions/actions';
import { updateObject } from '../../common/utils/utility';
import { ActionSchema } from '../../schemas';

const initialState = {
	cop: {},
	rm: {},
};
const reducer = (state = initialState, action: ActionSchema) => {
	let cop = {};
	switch (action.type) {
		case COP_SAVE_PDF_PRINTDATA:
			return {
				...action.data,
			};
		case COP_GET_PDF_PRINTDATA:
		default:
			return state;
	}
};
export default reducer;
