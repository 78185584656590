import { AxiosError, AxiosResponse } from 'axios';
import { ActionCreator, Dispatch } from 'redux';
import { coaDataChanged } from '.';
import { MADCAP_API } from '../../common/configs';
import { http } from '../../common/services';
import {
	MAD_CAP_API_RESET,
	MAD_CAP_API_SUCCESS,
	MAD_CAP_FETCH_START,
	RM_POSITION_DATA_FROM_MADCAP,
	RM_POSITION_HEADER_UPDATE,
} from './actions';
import { showMessage } from './message-notification';

interface IMadCapResult {
	type: string;
}

const fetchStart: ActionCreator<IMadCapResult> = () => {
	return {
		type: MAD_CAP_FETCH_START,
	};
};

const fetchreset: ActionCreator<IMadCapResult> = () => {
	return {
		type: MAD_CAP_API_RESET,
	};
};

const fetchSuccess: ActionCreator<IMadCapResult> = () => {
	return {
		type: MAD_CAP_API_SUCCESS,
	};
};

const updateRMRecordWithMadCapPosition = (payload: any) => {
	return {
		type: RM_POSITION_DATA_FROM_MADCAP,
		payload: payload,
	};
};

const updateHeaderInformationWithDate = () => {
	return {
		type: RM_POSITION_HEADER_UPDATE,
	};
};
export const MADCAPNOTFOUNDMSG = 'No MadCap data available for given period';
export const MADCAPISSUEERRORMSG =
	'An issue was encountered while accessing MadCap data. Please try again later. If the issue persists, please contact support.';
const getmadcappositionsdata = (
	farmID: string = '55004',
	startDate: string = '1',
	endDate: string = '1',
	year: number
) => {
	return (dispatch: Dispatch) => {
		dispatch(fetchStart());
		http.get(MADCAP_API.GETMADCAPDATA(farmID, startDate, endDate)).then(
			(response: AxiosResponse) => {
				if (response.data['get-milkContract'].length > 0) {
					dispatch(fetchSuccess());
					dispatch(coaDataChanged(true));
				} else {
					dispatch(fetchreset());
					dispatch(updateHeaderInformationWithDate());
					dispatch(showMessage(MADCAPNOTFOUNDMSG));
				}
				dispatch(
					updateRMRecordWithMadCapPosition({
						listContracts: response.data['get-milkContract'],
						financialYear: year,
					})
				);
			},
			(err: AxiosError) => {
				dispatch(fetchreset());
				dispatch(showMessage(MADCAPISSUEERRORMSG));
			}
		);
	};
};

export {
	getmadcappositionsdata,
	fetchStart,
	fetchreset,
	fetchSuccess,
	updateHeaderInformationWithDate,
};
