import { AxiosError, AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { ActionCreator, Dispatch } from 'redux';
import { PRODUCER_API } from '../../common/configs';
import { http } from '../../common/services';
import {
	AuthInfo,
	CostOfProduction,
	Producer,
	ProducerYear,
	RiskManagement,
	SyncOffline,
} from '../../schemas';
import {
	PRODUCERS_FETCH_FAIL,
	PRODUCERS_FETCH_START,
	PRODUCERS_FETCH_SUCCESS,
	PRODUCERS_LIST,
	PRODUCER_REMOVE_SELECTED,
	PRODUCER_SELECTED,
} from '../actions/actions';
import { store } from '../index';
import { formatDate, sortArray } from './../../common/utils';
import { showMessage } from './message-notification';

interface IAuthAction {
	type: string;
}
interface IAuthErrorAction extends IAuthAction {
	payload: AxiosError;
}

interface IAuthProducerLoadedAction extends IAuthAction {
	payload: Array<Producer>;
}

interface IAuthProducerByIDLoadedAction extends IAuthAction {
	payload: Producer;
}

interface IAuthSyncYearsOfflineAction extends IAuthAction {
	payload: Producer;
}

const producerFetchStart: ActionCreator<IAuthAction> = () => {
	return {
		type: PRODUCERS_FETCH_START,
	};
};

const producerFetchFail: ActionCreator<IAuthErrorAction> = (
	error: AxiosError
) => {
	return {
		type: PRODUCERS_FETCH_FAIL,
		payload: error,
	};
};

const producerFetchSuccess: ActionCreator<IAuthAction> = () => {
	return {
		type: PRODUCERS_FETCH_SUCCESS,
	};
};

const producersLoaded: ActionCreator<IAuthProducerLoadedAction> = (
	list: Array<Producer> = []
) => {
	return {
		type: PRODUCERS_LIST,
		payload: list,
	};
};
const producerByIdLoaded: ActionCreator<IAuthProducerByIDLoadedAction> = (
	producer: Producer
) => {
	return {
		type: PRODUCER_SELECTED,
		payload: producer,
	};
};
const removeSelectedProducer: ActionCreator<IAuthAction> = () => {
	return {
		type: PRODUCER_REMOVE_SELECTED,
	};
};

//get producers
const getProducers = () => {
	return (dispatch: Dispatch) => {
		dispatch(producerFetchStart());
		return http.get(PRODUCER_API.PRODUCERS).then(
			(response: AxiosResponse) => {
				const producers = response.data.length
					? _.sortBy(response.data, function (producer) {
							return producer.farmName.toLowerCase();
					  })
					: [];

				dispatch(producersLoaded(producers));
				dispatch(producerFetchSuccess());
			},
			(err: AxiosError) => {
				dispatch(producerFetchFail(err));
				dispatch(showMessage(err?.message));
			}
		);
	};
};

//get producer by ID
const getProducerByID = (id: string) => {
	return (dispatch: Dispatch) => {
		dispatch(producerFetchStart());
		const PRODUCER_BY_ID = PRODUCER_API.PRODUCER_BY_ID(id);
		http.get(PRODUCER_BY_ID).then(
			(response: AxiosResponse) => {
				let onlineProducer = response.data ? response.data : null;

				// offline producer
				const authInfo: AuthInfo = store.getState().auth;
				const syncedData: SyncOffline = store.getState().synced;
				const offlineProducers =
					syncedData[authInfo?.userInfo?.mail]?.list || [];
				let offlineProducer: Producer = new Producer();
				if (offlineProducers && offlineProducers.length > 0) {
					const producer = offlineProducers.filter(
						(producer: Producer) => producer?.producerIdentifier === id
					);
					offlineProducer =
						producer && producer.length > 0 ? producer[0] : new Producer();
				}

				//merge online / offline data
				offlineProducer.versions.forEach((offlineYear: ProducerYear) => {
					const isExist = _.findIndex(onlineProducer.versions, {
						year: offlineYear.year,
					});
					if (isExist === -1) {
						onlineProducer.versions = _.concat(onlineProducer.versions, [
							offlineYear,
						]);
					} else {
						onlineProducer.versions.forEach((onlineYear: ProducerYear) => {
							if (offlineYear.year === onlineYear.year) {
								const offlineCOPAdded = offlineYear.costOfProductionVersions.filter(
									(item: CostOfProduction) => item?.isOfflineAdded
								);
								onlineYear.costOfProductionVersions = _.concat(
									onlineYear.costOfProductionVersions,
									offlineCOPAdded
								);

								const offlineRMAdded = offlineYear.riskMarketingVersions.filter(
									(item: RiskManagement) => item?.isOfflineAdded
								);
								onlineYear.riskMarketingVersions = _.concat(
									onlineYear.riskMarketingVersions,
									offlineRMAdded
								);
							}
						});
					}
				});

				onlineProducer.versions.forEach((onlineYear: ProducerYear) => {
					onlineYear.costOfProductionVersions = sortArray(
						onlineYear.costOfProductionVersions,
						'modifiedDate',
						true
					);
					onlineYear.riskMarketingVersions = sortArray(
						onlineYear.riskMarketingVersions,
						'modifiedDate',
						true
					);
					const isOfflineRecordAddedCOP =
						_.findIndex(onlineYear.costOfProductionVersions, {
							isOfflineAdded: true,
						}) > -1;
					const isOfflineRecordAddedRM =
						_.findIndex(onlineYear.riskMarketingVersions, {
							isOfflineAdded: true,
						}) > -1;

					onlineYear.isOfflineChanges =
						isOfflineRecordAddedCOP || isOfflineRecordAddedRM;

					onlineYear.rmlastCopVersionOffline = isOfflineRecordAddedCOP;
				});

				if (onlineProducer) {
					onlineProducer.versions.forEach((p: ProducerYear) => {
						const coplastupdatedval = sortArray(
							p?.costOfProductionVersions,
							'modifiedDate',
							true
						);
						const rmlastupdatedval = sortArray(
							p?.riskMarketingVersions,
							'modifiedDate',
							true
						);

						if (coplastupdatedval.length > 0) {
							p.coplastupdated = formatDate(coplastupdatedval[0].modifiedDate);
							p.copstatus = coplastupdatedval[0].status;
							p.lastupdatedType = 'cop';
							p.rmlastCopVersion = coplastupdatedval[0].version;
							p.rmlastCoaVersion = coplastupdatedval[0].chartOfAccountsVersion;
							p.rmlastUpdatedVersion = 0;
						}

						if (rmlastupdatedval.length > 0) {
							p.rmlastupdated = formatDate(
								rmlastupdatedval[0].modifiedDate
							).toLocaleString();
							p.rmstatus = rmlastupdatedval[0].status;
							p.rmlastCopVersion =
								coplastupdatedval.length > 0 ? coplastupdatedval[0].version : 0;
							p.rmlastCoaVersion =
								coplastupdatedval.length > 0
									? coplastupdatedval[0].chartOfAccountsVersion
									: 0;
							p.rmlastUpdatedVersion = rmlastupdatedval[0].version;
							p.lastupdatedType = 'rm';
						}

						const allversionsConcated = _.chain(coplastupdatedval)
							.concat(rmlastupdatedval)
							.value();

						const allversions = sortArray(
							allversionsConcated,
							'modifiedDate',
							true
						);

						if (allversions?.length > 0) {
							p.lastupdated = formatDate(allversions[0].modifiedDate);
							p.lastupdatedVersion = allversions[0].version;
							p.lastupdatedVersionIdentifier = allversions[0].version;
							p.lastupdatedSchemaIdentifier =
								allversions[0].chartOfAccountsVersion;
							p.status = allversions[0].status;
						}
					});
				}

				dispatch(producerByIdLoaded(onlineProducer));
				dispatch(producerFetchSuccess());
			},
			(err: AxiosError) => {
				dispatch(producerFetchFail(err));
				dispatch(showMessage(err?.message));
			}
		);
	};
};

// get producer by ID while offline
const getProducerByIDOffline = (id: string) => {
	return (dispatch: Dispatch) => {
		dispatch(producerFetchStart());
		const authInfo: AuthInfo = store.getState().auth;
		const syncedData: SyncOffline = store.getState().synced;
		const syncList = syncedData[authInfo?.userInfo?.mail]?.list || [];
		const producer =
			syncList.filter(
				(p: Producer) => String(p.producerIdentifier) == String(id)
			)[0] || null;
		if (producer) {
			producer.versions.forEach((p: ProducerYear) => {
				p.costOfProductionVersions = sortArray(
					p.costOfProductionVersions,
					'modifiedDate',
					true
				);
				p.riskMarketingVersions = sortArray(
					p.riskMarketingVersions,
					'modifiedDate',
					true
				);

				const coplastupdatedval = sortArray(
					p?.costOfProductionVersions,
					'modifiedDate',
					true
				);
				const rmlastupdatedval = sortArray(
					p?.riskMarketingVersions,
					'modifiedDate',
					true
				);

				if (coplastupdatedval.length > 0) {
					p.coplastupdated = formatDate(coplastupdatedval[0].modifiedDate);
					p.copstatus = coplastupdatedval[0].status;
					p.lastupdatedType = 'cop';
					p.rmlastCopVersion = coplastupdatedval[0].version;
					p.rmlastCoaVersion = coplastupdatedval[0].chartOfAccountsVersion;
					p.rmlastUpdatedVersion = 0;
				}

				if (rmlastupdatedval.length > 0) {
					p.rmlastupdated = formatDate(
						rmlastupdatedval[0].modifiedDate
					).toLocaleString();
					p.rmstatus = rmlastupdatedval[0].status;
					p.rmlastCopVersion =
						coplastupdatedval.length > 0 ? coplastupdatedval[0].version : 0;
					p.rmlastCoaVersion =
						coplastupdatedval.length > 0
							? coplastupdatedval[0].chartOfAccountsVersion
							: 0;
					p.rmlastUpdatedVersion = rmlastupdatedval[0].version;
					p.lastupdatedType = 'rm';
				}

				const allversionsConcated = _.chain(coplastupdatedval)
					.concat(rmlastupdatedval)
					.value();

				const allversions = sortArray(
					allversionsConcated,
					'modifiedDate',
					true
				);

				if (allversions?.length > 0) {
					p.lastupdated = formatDate(allversions[0].modifiedDate);
					p.lastupdatedVersion = allversions[0].version;
					p.lastupdatedVersionIdentifier = allversions[0].version;
					p.lastupdatedSchemaIdentifier = allversions[0].chartOfAccountsVersion;
					p.status = allversions[0].status;
				}

				const isOfflineRecordAddedCOP =
					_.findIndex(p.costOfProductionVersions, {
						isOfflineAdded: true,
					}) > -1;
				const isOfflineRecordAddedRM =
					_.findIndex(p.riskMarketingVersions, {
						isOfflineAdded: true,
					}) > -1;

				p.isOfflineChanges = isOfflineRecordAddedCOP || isOfflineRecordAddedRM;
				p.rmlastCopVersionOffline = isOfflineRecordAddedCOP;
			});
		}
		dispatch(producerByIdLoaded(producer));
		dispatch(producerFetchSuccess());
	};
};

// producer loader start
const fetchProducerStart = () => {
	return (dispatch: Dispatch) => {
		dispatch(producerFetchStart());
	};
};
// producer loader stop
const fetchProducerFail = (err: AxiosError) => {
	return (dispatch: Dispatch) => {
		dispatch(producerFetchFail(err));
	};
};

export {
	producersLoaded,
	getProducers,
	getProducerByID,
	getProducerByIDOffline,
	removeSelectedProducer,
	fetchProducerStart,
	fetchProducerFail,
};
