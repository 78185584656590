import axios, { AxiosError, AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { ActionCreator, Dispatch } from 'redux';
import { COA_API, RM_API } from '../../common/configs';
import {
	MSG_TYPE,
	RM_ALLOCATION_TYPES,
	RM_SECTION_CODES,
	SCHEMA_TYPE,
	SECTION_CODES,
	SECTION_TYPE_CODE,
	VERSION_STATUS,
} from '../../common/constants';
import { http } from '../../common/services';
import {
	formatDateForPriceCurve,
	sortArray,
	updateRefrence,
} from '../../common/utils';
import {
	AuthInfo,
	CostOfProduction,
	GenericSchema,
	MarketPrice,
	Producer,
	ProducerYear,
	RiskManagement,
	RMHeader,
	SyncOffline,
} from '../../schemas';
import {
	PositionEditFieldType,
	PositionModel,
} from '../../schemas/Model/position.model';
import { moveRMPostionDictionaryData } from '../reducers/coa';
import { store } from './../../store';
import {
	COA_DATA_CHANGED,
	COA_ERROR_SAVE_DATA_TO_DB,
	COA_FETCH_FAIL,
	COA_FETCH_START,
	COA_FETCH_SUCCESS,
	COA_RESET_COP,
	COA_RESET_DATA,
	COA_RESET_RM,
	COA_SAVE_DATA,
	COA_SAVE_DATA_TO_DB,
	COA_SAVE_DATA_TO_DB_START,
	COA_SCHEMA_CALCULATION_CHANGED,
	COA_SCHEMA_MAAPED_WITH_DATA,
	COA_SCHEMA_REMOVE_SELECTED,
	COA_SCHEMA_SELECTED,
	COA_UPDATE_RESET,
	ENTRY_FORM_ERROR,
	Position_DICTIONARY,
	RM_ADD_NEW_POSITION,
	RM_MOVE_POSITION_DICTIONARY_DATA,
	RM_POSITION_CALCULATE_ALL,
	RM_POSITION_DATA_UPDATE,
	RM_POSITION_DELETE,
	RM_POSITION_RECALCULATE_MONTH_YEAR,
	RM_POSITION_UPDATE_MARKETPRICE,
	RM_POSTION_DATA_COLLAR_COUNT,
	RM_UPDATEVALIDATION_POSITION,
} from './actions';
import {
	fetchStart,
	fetchSuccess,
	marketPriceLoaded,
	removeSelectedMarketPrice,
	saveOffline,
	updateOfflineData,
} from './index';
import { showMessage } from './message-notification';

interface ICOAAction {
	type: string;
}
interface ICOAErrorAction extends ICOAAction {
	payload: AxiosError;
}

interface IAuthCOASelectedAction extends ICOAAction {
	payload: GenericSchema | null;
}

interface IAuthCOASavedAction extends ICOAAction {
	payload: GenericSchema | null;
}

interface IAuthCOACalculationChangedAction extends ICOAAction {
	payload: GenericSchema | null;
}

interface IAuthCOASchemaMappedAction extends ICOAAction {
	payload: GenericSchema | null;
}

interface IAuthEntryFormErrorAction extends ICOAAction {
	payload: GenericSchema | null;
}

interface ICOAResetAction extends ICOAAction {
	payload: boolean;
}

interface ICOAUpdateAction extends ICOAAction {
	payload: boolean;
}

const movePositionDataFromDictionary = () => {
	return { type: RM_MOVE_POSITION_DICTIONARY_DATA };
};

const updateValidationErrorPosition = () => {
	return {
		type: RM_UPDATEVALIDATION_POSITION,
	};
};

const addNewPosition = (payload: {
	type: string;
	month: string;
	year: string;
	commodity: string;
	settledprice: number;
	financialYear: any;
}) => {
	return {
		type: RM_ADD_NEW_POSITION,
		payload: payload,
	};
};

const updatePostionForMarketPrice = (payload: {
	marketPriceList: Array<MarketPrice>;
}) => {
	return {
		type: RM_POSITION_UPDATE_MARKETPRICE,
		payload: payload,
	};
};

const deletePositionFromPC = (payload: {
	settledprice: number;
	financialYear: number;
	model: PositionModel;
	commodity: string;
	month: string;
	year: string;
}) => {
	return { type: RM_POSITION_DELETE, payload: payload };
};

const calculateALLCommodities = (payload: { financialYear: any }) => {
	return { type: RM_POSITION_CALCULATE_ALL, payload: payload };
};

const calculateMonthCardPosition = (payload: {
	financialYear: number;
	month: string;
	year: string;
	commodity: string;
}) => {
	return { type: RM_POSITION_RECALCULATE_MONTH_YEAR, payload: payload };
};
const updatePositionCalculation = (payload: {
	settledprice: number;
	financialYear: number;
	model: PositionModel;
	commodity: string;
	month: string;
	year: string;
	fieldName: PositionEditFieldType;
	value: number;
}) => {
	return {
		type: RM_POSITION_DATA_UPDATE,
		payload: payload,
	};
};

const updateCollarNumberAndVolume = (payload: {
	model: PositionModel;
	commodity: string;
	month: string;
	year: string;
}) => {
	return {
		type: RM_POSTION_DATA_COLLAR_COUNT,
		payload: payload,
	};
};

const coaFetchStart: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_FETCH_START,
	};
};

const coaFetchFail: ActionCreator<ICOAErrorAction> = (error: AxiosError) => {
	return {
		type: COA_FETCH_FAIL,
		payload: error,
	};
};

const coaSchemaSelected: ActionCreator<IAuthCOASelectedAction> = (
	schema: GenericSchema | null = null
) => {
	return {
		type: COA_SCHEMA_SELECTED,
		payload: schema,
	};
};

const removeSelectedCOAScehma: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_SCHEMA_REMOVE_SELECTED,
	};
};

const resetCOAData: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_RESET_DATA,
	};
};

const resetRMData: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_RESET_RM,
	};
};
const resetCOPData: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_RESET_COP,
	};
};

const updateResetCOAData: ActionCreator<ICOAResetAction> = (
	isManuallyReset: boolean = false
) => {
	return {
		type: COA_UPDATE_RESET,
		payload: isManuallyReset,
	};
};

const coaSaveData: ActionCreator<IAuthCOASavedAction> = (
	data: GenericSchema | null = null
) => {
	return {
		type: COA_SAVE_DATA,
		payload: data,
	};
};

const coaUpdateCalculation: ActionCreator<IAuthCOACalculationChangedAction> = (
	data: GenericSchema | null = null
) => {
	return {
		type: COA_SCHEMA_CALCULATION_CHANGED,
		payload: data,
	};
};

const coaSchemaMappedWithData: ActionCreator<IAuthCOASchemaMappedAction> = (
	data: GenericSchema | null = null
) => {
	return {
		type: COA_SCHEMA_MAAPED_WITH_DATA,
		payload: data,
	};
};
const coaSyncedToDBStart: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_SAVE_DATA_TO_DB_START,
	};
};
const coaSyncedToDBSucces: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_SAVE_DATA_TO_DB,
	};
};
const coaSyncedToDBFail: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_ERROR_SAVE_DATA_TO_DB,
	};
};

const coaDataChanged: ActionCreator<ICOAUpdateAction> = (status: boolean) => {
	return {
		type: COA_DATA_CHANGED,
		payload: status,
	};
};

const coaFetchDataSuccess: ActionCreator<ICOAAction> = () => {
	return {
		type: COA_FETCH_SUCCESS,
	};
};

const entryFormError: ActionCreator<IAuthEntryFormErrorAction> = (
	data: GenericSchema | null = null
) => {
	return {
		type: ENTRY_FORM_ERROR,
		payload: data,
	};
};

const sortBySequence = (arr: Array<GenericSchema>) => {
	return _.sortBy(arr, item => item?.extension?.sequenceNumber);
};

// get schema for COP / RM
const getCOASchema = (
	schemaIdentifier: string = '',
	year: string = '',
	partyIdentifer: string = '',
	revisionIdentifier: string = '',
	schemaName: string = '',
	rmRevisionIdentifier: string = ''
) => {
	return (dispatch: Dispatch) => {
		const apiRequest = [];
		const authInfo: AuthInfo = store.getState().auth;
		const syncedData: SyncOffline = store.getState().synced;
		const syncList = syncedData[authInfo?.userInfo?.mail]?.list || [];
		const producer = syncList.filter(
			(p: Producer) => p.producerIdentifier == partyIdentifer
		);
		const yearList =
			producer?.length > 0
				? producer[0]?.versions.filter(
						(item: ProducerYear) => String(item.year) == String(year)
				  )
				: [];

		const versionCOP =
			yearList?.length > 0
				? yearList[0].costOfProductionVersions.filter(
						(item: CostOfProduction) =>
							Number(item.version) == Number(revisionIdentifier)
				  )
				: [];
		const versionCOPJournalEntry =
			versionCOP?.length > 0 ? versionCOP[0].journalEntry : {};
		const versionRM =
			yearList?.length > 0
				? yearList[0].riskMarketingVersions.filter(
						(item: RiskManagement) =>
							Number(item.version) == Number(rmRevisionIdentifier)
				  )
				: [];
		const versionRMJournalEntry =
			versionRM?.length > 0 ? versionRM[0].journalEntry : {};

		dispatch(removeSelectedCOAScehma());
		dispatch(coaFetchStart());

		// get schema
		apiRequest.push(http.get(COA_API.GET_COP_SCHEMA(schemaIdentifier)));

		switch (schemaName) {
			case SCHEMA_TYPE.RM:
				loadMarketPrice(
					dispatch,
					partyIdentifer,
					year,
					revisionIdentifier,
					rmRevisionIdentifier,
					versionRMJournalEntry
				);
				// get cop journal entry lines
				apiRequest.push(
					http.get(
						COA_API.GET_COP_DATA(
							partyIdentifer,
							year,
							revisionIdentifier,
							SCHEMA_TYPE.COP
						)
					)
				);

				//get previous rm entry lines
				apiRequest.push(
					http.get(
						COA_API.GET_JOURNAL_ENTRY_LINES(
							partyIdentifer,
							`${RM_SECTION_CODES.POSITION},${RM_SECTION_CODES.DRP},${RM_SECTION_CODES.DRP_COMP}`,
							(Number(year) - 1).toString(),
							SCHEMA_TYPE.RM
						)
					)
				);

				if (Number(rmRevisionIdentifier) > 0) {
					//get rm journal entry lines
					apiRequest.push(
						http.get(
							COA_API.GET_COP_DATA(
								partyIdentifer,
								year,
								rmRevisionIdentifier,
								SCHEMA_TYPE.RM
							)
						)
					);
				}

				axios
					.all(apiRequest)
					.then(
						axios.spread((...responses) => {
							// schema dispatched
							const schema = responses[0]?.data;
							dispatch(coaSchemaSelected(schema));

							// cop journal entry lines
							const copJournalEntryLines = responses[1]?.data;

							// previous year rm journal entry lines
							const prevRmJournalEntryLines = responses[2]?.data;

							// rm journal entry lines
							const rmJournalEntryLines = responses[3]?.data;

							const data = !_.isEmpty(copJournalEntryLines)
								? copJournalEntryLines
								: versionCOPJournalEntry;
							let mergedSchema: GenericSchema;

							if (!_.isEmpty(data)) {
								mergedSchema = mapDatawithSchema(schema, data);
								if (Number(rmRevisionIdentifier) > 0) {
									let journalEntry = !_.isEmpty(
										rmJournalEntryLines?.journalEntry
									)
										? rmJournalEntryLines?.journalEntry
										: versionRMJournalEntry?.journalEntry;

									// prior year RM journalEntry line data
									const prevYearJournalEntryLine =
										prevRmJournalEntryLines || [];

									const staticData: GenericSchema = {};
									staticData[
										'prevYearJournalEntryLine'
									] = prevYearJournalEntryLine;
									staticData['schema'] = {
										showChartOfAccounts:
											mergedSchema.schema.showChartOfAccounts,
										journalEntry: journalEntry,
									};
									staticData['basicInfo'] = mergedSchema.basicInfo;
									staticData['schema'][
										SECTION_TYPE_CODE.INCOME
									] = sortBySequence(
										schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
											(item: GenericSchema) => {
												return (
													item.extension.code[1].content !==
														RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
													item.typeCode === SECTION_TYPE_CODE.INCOME
												);
											}
										)
									);
									staticData['schema'][
										SECTION_TYPE_CODE.EXPENSE
									] = sortBySequence(
										schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
											(item: GenericSchema) => {
												return (
													item.extension.code[1].content !==
														RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
													(item.typeCode === SECTION_TYPE_CODE.EXPENSE ||
														item.typeCode === SECTION_TYPE_CODE.ACCRUAL)
												);
											}
										)
									);

									dispatch(coaSchemaMappedWithData(staticData));
									dispatch(coaFetchDataSuccess());
								} else {
									// prior year RM journalEntry line data
									const prevYearJournalEntryLine =
										prevRmJournalEntryLines || [];

									const staticData: GenericSchema = {};
									staticData[
										'prevYearJournalEntryLine'
									] = prevYearJournalEntryLine;
									staticData['schema'] = {
										showChartOfAccounts:
											mergedSchema.schema.showChartOfAccounts,
										journalEntry: {
											typeCode: 'RM',
											journalEntryHeader: RMHeader,
											journalEntryLine: [],
										},
									};
									staticData['basicInfo'] = mergedSchema.basicInfo;
									staticData['schema'][
										SECTION_TYPE_CODE.INCOME
									] = sortBySequence(
										schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
											(item: GenericSchema) => {
												return (
													item.extension.code[1].content !==
														RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
													item.typeCode === SECTION_TYPE_CODE.INCOME
												);
											}
										)
									);
									staticData['schema'][
										SECTION_TYPE_CODE.EXPENSE
									] = sortBySequence(
										schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
											(item: GenericSchema) => {
												return (
													item.extension.code[1].content !==
														RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
													(item.typeCode === SECTION_TYPE_CODE.EXPENSE ||
														item.typeCode === SECTION_TYPE_CODE.ACCRUAL)
												);
											}
										)
									);

									dispatch(coaSchemaMappedWithData(staticData));
									dispatch(coaFetchDataSuccess());
								}
							}
						})
					)
					.catch((err: AxiosError) => {
						dispatch(coaFetchFail(err));
						dispatch(showMessage(err?.message));
					});

				break;
			case SCHEMA_TYPE.COP:
				//get cop data
				if (Number(revisionIdentifier) > 0) {
					apiRequest.push(
						http.get(
							COA_API.GET_COP_DATA(
								partyIdentifer,
								year,
								revisionIdentifier,
								SCHEMA_TYPE.COP
							)
						)
					);
				}

				axios
					.all(apiRequest)
					.then(
						axios.spread((...responses) => {
							// schema dispatched
							const schema = responses[0]?.data;
							dispatch(coaSchemaSelected(schema));

							// journal entry lines dispatched
							const copJournalEntryLines = responses[1]?.data;
							const data = !_.isEmpty(copJournalEntryLines)
								? copJournalEntryLines
								: versionCOPJournalEntry;
							if (!_.isEmpty(data)) {
								const mergedSchema = mapDatawithSchema(schema, data);
								dispatch(coaSchemaMappedWithData(mergedSchema));
							} else {
								dispatch(coaFetchDataSuccess());
							}
						})
					)
					.catch((err: AxiosError) => {
						dispatch(coaFetchFail(err));
						dispatch(showMessage(err?.message));
					});

				break;
			default:
				break;
		}
	};
};

const loadMarketPrice = (
	dispatch: ActionCreator<any>,
	partyIdentifer: string,
	year: string,
	revisionIdentifier: string,
	rmRevisionIdentifier: string,
	versionRMJournalEntry: GenericSchema | null
) => {
	const mtmRequest = [];

	//get cop journal entry line
	mtmRequest.push(
		http.get(
			COA_API.GET_COP_DATA(
				partyIdentifer,
				year,
				revisionIdentifier,
				SCHEMA_TYPE.COP
			)
		)
	);

	if (Number(rmRevisionIdentifier) > 0) {
		//get rm journal entry lines
		mtmRequest.push(
			http.get(
				COA_API.GET_COP_DATA(
					partyIdentifer,
					year,
					rmRevisionIdentifier,
					SCHEMA_TYPE.RM
				)
			)
		);
	}

	axios.all(mtmRequest).then(
		axios.spread((...responses) => {
			// cop journal entry lines
			const copJournalEntryLines = responses[0].data;
			// rm journal entry lines
			const rmJournalEntryLines = responses[1]?.data;

			//when rn document is available
			if (Number(rmRevisionIdentifier) > 0) {
				// MTM date
				let journalEntryHeader = !_.isEmpty(rmJournalEntryLines?.journalEntry)
					? rmJournalEntryLines?.journalEntry?.journalEntryHeader
					: versionRMJournalEntry?.journalEntry?.journalEntryHeader;

				const mtmDate = formatDateForPriceCurve(
					journalEntryHeader['documentReference'][2]['documentDateTime']
				);
				const finacialYear = String(
					Number(journalEntryHeader?.accountingPeriod?.year) + 1
				);
				const financialYearStartmonth =
					copJournalEntryLines?.accountingPeriod?.periodIdentifier || 'January';
				fetchMarketPrice(
					dispatch,
					mtmDate,
					financialYearStartmonth,
					finacialYear
				);
			} else {
				const mtmDate = formatDateForPriceCurve(new Date());
				const finacialYear = String(Number(year) + 1);
				const financialYearStartmonth =
					copJournalEntryLines?.accountingPeriod?.periodIdentifier || 'January';
				fetchMarketPrice(
					dispatch,
					mtmDate,
					financialYearStartmonth,
					finacialYear
				);
			}
		})
	);
};

//fetch market price
function fetchMarketPrice(
	dispatch: ActionCreator<any>,
	mtmDate: string,
	financialYearStartmonth: string,
	finacialYear: string
) {
	dispatch(removeSelectedMarketPrice());
	dispatch(fetchStart({ date: mtmDate }));
	http
		.get(
			RM_API.MARKET_PRICE_HISTORIC_LIST(
				mtmDate,
				financialYearStartmonth,
				finacialYear
			)
		)
		.then((response: AxiosResponse) => {
			let marketPriceList = response.data ? response.data : [];
			marketPriceList.forEach((item: MarketPrice) => {
				const monthYear = item.month + ' ' + item.year;
				item.date = new Date(monthYear);
			});

			marketPriceList = _.orderBy(
				marketPriceList,
				(item: MarketPrice) => new Date(item.tradingDateTime),
				['desc']
			);
			dispatch(marketPriceLoaded(marketPriceList));
			dispatch(fetchSuccess());
		});
}

//get schema for COP / RM in Offline Mode
const getCOASchemaOffline = (
	schemaIdentifier: string = '',
	year: string = '',
	partyIdentifer: string = '',
	revisionIdentifier: string = '',
	schemaName: string = '',
	rmRevisionIdentifier: string = ''
) => {
	return (dispatch: Dispatch) => {
		dispatch(removeSelectedCOAScehma());
		dispatch(coaFetchStart());
		let schema: GenericSchema = {};
		let mergedSchema: GenericSchema = {};
		const authInfo: AuthInfo = store.getState().auth;
		const syncedData: SyncOffline = store.getState().synced;
		const syncList = syncedData[authInfo?.userInfo?.mail]?.list || [];
		const producer = syncList.filter(
			(p: Producer) => p.producerIdentifier == partyIdentifer
		);
		const yearList =
			producer?.length > 0
				? producer[0]?.versions.filter(
						(item: ProducerYear) => String(item.year) == String(year)
				  )
				: [];
		const versionCOP =
			yearList?.length > 0
				? yearList[0].costOfProductionVersions.filter(
						(item: CostOfProduction) =>
							Number(item.version) == Number(revisionIdentifier)
				  )
				: [];
		const versionCOPJournalEntry =
			versionCOP?.length > 0 ? versionCOP[0].journalEntry : {};
		const versionRM =
			yearList?.length > 0
				? yearList[0].riskMarketingVersions.filter(
						(item: RiskManagement) =>
							Number(item.version) == Number(rmRevisionIdentifier)
				  )
				: [];
		const versionRMJournalEntry =
			versionRM?.length > 0 ? versionRM[0].journalEntry : {};

		// get COA schema
		if (Number(schemaIdentifier) > 0) {
			schema =
				syncedData?.schemaList?.filter(
					(item: GenericSchema) =>
						Number(item.versionIdentifier) == Number(schemaIdentifier)
				)[0] || {};
		} else {
			schema =
				_.sortBy(syncedData?.schemaList, 'versionIdentifier').reverse()[0] ||
				{};
		}

		// previous year data for prefilling previous year
		const prevYear = (Number(year) - 1).toString();
		const prevYearList =
			producer?.length > 0
				? producer[0]?.versions.filter(
						(item: ProducerYear) => String(item.year) == String(prevYear)
				  )
				: [];
		const prevYearversionRM =
			prevYearList?.length > 0
				? sortArray(prevYearList[0].riskMarketingVersions, 'modifiedDate', true)
				: [];
		const versionRMJournalEntryLine =
			prevYearversionRM?.length > 0
				? prevYearversionRM[0].journalEntry?.journalEntry?.journalEntryLine
				: [];

		switch (schemaName) {
			case SCHEMA_TYPE.RM:
				// get cop data

				if (!_.isEmpty(versionCOPJournalEntry)) {
					const data = versionCOPJournalEntry;
					mergedSchema = mapDatawithSchema(schema, data);
					if (Number(rmRevisionIdentifier) > 0) {
						// get RM data
						const data = versionRMJournalEntry;

						const staticData: GenericSchema = {};
						staticData['prevYearJournalEntryLine'] = [
							{ year: prevYear, journalEntryLines: versionRMJournalEntryLine },
						];
						staticData['schema'] = {
							showChartOfAccounts: mergedSchema.schema.showChartOfAccounts,
							journalEntry: versionRMJournalEntry?.journalEntry,
						};
						staticData['basicInfo'] = mergedSchema.basicInfo;
						staticData['schema'][SECTION_TYPE_CODE.INCOME] = sortBySequence(
							schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
								(item: GenericSchema) => {
									return (
										item.extension.code[1].content !==
											RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
										item.typeCode === SECTION_TYPE_CODE.INCOME
									);
								}
							)
						);
						staticData['schema'][SECTION_TYPE_CODE.EXPENSE] = sortBySequence(
							schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
								(item: GenericSchema) => {
									return (
										item.extension.code[1].content !==
											RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
										(item.typeCode === SECTION_TYPE_CODE.EXPENSE ||
											item.typeCode === SECTION_TYPE_CODE.ACCRUAL)
									);
								}
							)
						);

						dispatch(coaSchemaMappedWithData(staticData));
						dispatch(coaFetchDataSuccess());
					} else {
						const staticData: GenericSchema = {};
						staticData['prevYearJournalEntryLine'] = [
							{ year: prevYear, journalEntryLines: versionRMJournalEntryLine },
						];
						staticData['schema'] = {
							showChartOfAccounts: mergedSchema.schema.showChartOfAccounts,
							journalEntry: {
								typeCode: 'RM',
								journalEntryHeader: RMHeader,
								journalEntryLine: [],
							},
						};
						staticData['basicInfo'] = mergedSchema.basicInfo;
						staticData['schema'][SECTION_TYPE_CODE.INCOME] = sortBySequence(
							schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
								(item: GenericSchema) => {
									return (
										item.extension.code[1].content !==
											RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
										item.typeCode === SECTION_TYPE_CODE.INCOME
									);
								}
							)
						);
						staticData['schema'][SECTION_TYPE_CODE.EXPENSE] = sortBySequence(
							schema.showChartOfAccounts.dataArea.chartOfAccounts.filter(
								(item: GenericSchema) => {
									return (
										item.extension.code[1].content !==
											RM_ALLOCATION_TYPES.DO_NOT_FORECAST &&
										(item.typeCode === SECTION_TYPE_CODE.EXPENSE ||
											item.typeCode === SECTION_TYPE_CODE.ACCRUAL)
									);
								}
							)
						);
						dispatch(coaSchemaMappedWithData(staticData));
						dispatch(coaFetchDataSuccess());
					}
				}
				break;
			case SCHEMA_TYPE.COP:
				dispatch(coaSchemaSelected(schema));

				//getting data
				if (Number(revisionIdentifier) > 0) {
					// GET DATA
					const data = versionCOPJournalEntry;
					if (!_.isEmpty(data)) {
						const mergedSchema = mapDatawithSchema(schema, data);
						dispatch(coaSchemaMappedWithData(mergedSchema));
					} else {
						dispatch(coaFetchDataSuccess());
					}
				} else {
					dispatch(coaFetchDataSuccess());
				}
				break;
			default:
				break;
		}
	};
};

// save data to DB for COP & RM
const saveCOAData = (
	schemaOb: GenericSchema,
	producerIdentifier: string,
	saveLocal: boolean = true,
	isNewRecord: boolean = true,
	type: string,
	revisionIdentifier: number
) => {
	return (dispatch: Dispatch) => {
		let schema = updateRefrence(schemaOb);
		const authInfo: AuthInfo = store.getState().auth;
		dispatch(coaSyncedToDBStart());
		if (saveLocal) {
			dispatch(coaSaveData(schema));
		} else {
			//get header
			let journalEntryHeader: GenericSchema;
			// get entry line to store in DB
			let journalEntryLine: Array<GenericSchema> = [];
			if (type === SCHEMA_TYPE.RM) {
				journalEntryHeader = schema[RM_SECTION_CODES.HEADER];
				delete schema[RM_SECTION_CODES.HEADER];
				delete schema[SECTION_TYPE_CODE.INCOME];
				delete schema[SECTION_TYPE_CODE.EXPENSE];
				delete schema['copData'];
				moveRMPostionDictionaryData({ calculatedSchema: schema });
				delete schema[Position_DICTIONARY];
				journalEntryLine = flattenedRMSchema(schema);
			} else {
				journalEntryHeader = schema[SECTION_CODES?.BASIC_INFO];
				delete schema[SECTION_CODES.BASIC_INFO];
				journalEntryLine = flattenedSchema(schema).map(item => {
					//item.identifier from 77 to 80 is for Age Group under the Livestock Inventory
					if (item.identifier >= 77 && item.identifier <= 80) {
						return {
							lineNumberIdentifier: item.identifier,
							note: item.note,
							amount: item.amount,
							description: item.description ? item.description : '',
							extension: {
								amount: item.extension?.amount,
								measure: item.extension?.measure,
							},
						};
					} else {
						return {
							lineNumberIdentifier: item.identifier,
							note: item.note,
							amount: item.amount,
							extension: {
								amount: item.extension?.amount,
								measure: item.extension?.measure,
							},
						};
					}
				});
			}

			// preparing params to be send
			const obj = {
				id: journalEntryHeader?.id,
				journalEntry: {
					journalEntryLine: journalEntryLine,
					journalEntryHeader: journalEntryHeader,
					typeCode: type,
				},
			};

			const URL = COA_API.COP_SAVE(producerIdentifier, type);
			let req;
			if (isNewRecord) {
				req = http.post(URL, obj).then(
					(response: AxiosResponse) => {
						dispatch(
							updateOfflineData(
								producerIdentifier,
								journalEntryHeader?.accountingPeriod?.year,
								type,
								revisionIdentifier,
								response?.data?.revisionIdentifier,
								authInfo?.userInfo?.mail
							)
						);
						dispatch(coaSyncedToDBSucces());
					},
					(err: AxiosError) => {
						dispatch(showMessage(err?.message));
					}
				);
			} else {
				req = http.patch(URL, obj).then(
					(response: AxiosResponse) => {
						dispatch(
							updateOfflineData(
								producerIdentifier,
								journalEntryHeader?.accountingPeriod?.year,
								type,
								revisionIdentifier,
								response?.data?.revisionIdentifier,
								authInfo?.userInfo?.mail
							)
						);
						dispatch(coaSyncedToDBSucces());
						dispatch(showMessage('Data saved Successfully', MSG_TYPE.SUCCESS));
					},
					(err: AxiosError) => {
						dispatch(showMessage(err?.message));
					}
				);
			}

			return req;
		}
	};
};

// save data to DB for COP & RM in Offline Mode
const saveCOADataOffline = (
	schema: GenericSchema,
	producerIdentifier: string,
	copVersion: string,
	chartOfAccountsVersion: string,
	type: string,
	marketPriceList: Array<MarketPrice> = []
) => {
	return (dispatch: Dispatch) => {
		const date = new Date();
		//get header
		let journalEntryHeader: GenericSchema;
		// get entry line to store in DB
		let journalEntryLine: Array<GenericSchema> = [];
		if (type === SCHEMA_TYPE.RM) {
			console.log('REached here:: ', schema);
			journalEntryHeader = schema[RM_SECTION_CODES.HEADER];
			delete schema[RM_SECTION_CODES.HEADER];
			delete schema[SECTION_TYPE_CODE.INCOME];
			delete schema[SECTION_TYPE_CODE.EXPENSE];
			delete schema['copData'];
			moveRMPostionDictionaryData({ calculatedSchema: schema });
			delete schema[Position_DICTIONARY];
			journalEntryLine = flattenedRMSchema(schema);
		} else {
			journalEntryHeader = schema[SECTION_CODES?.BASIC_INFO];
			delete schema[SECTION_CODES?.BASIC_INFO];
			journalEntryLine = flattenedSchema(schema).map(item => {
				if (item.identifier >= 77 && item.identifier <= 80) {
					return {
						lineNumberIdentifier: item.identifier,
						note: item.note,
						amount: item.amount,
						description: item.description ? item.description : '',
						extension: {
							amount: item.extension?.amount,
							measure: item.extension?.measure,
						},
					};
				} else {
					return {
						lineNumberIdentifier: item.identifier,
						note: item.note,
						amount: item.amount,
						extension: {
							amount: item.extension?.amount,
							measure: item.extension?.measure,
						},
					};
				}
			});
		}

		// preparing params to be send

		journalEntryHeader.extension.dateTime[1].content = date.toISOString();
		const obj = {
			id: journalEntryHeader?.id,
			journalEntry: {
				journalEntryLine: journalEntryLine,
				journalEntryHeader: journalEntryHeader,
				typeCode: type,
			},
		};

		const record = {
			version: date.getTime(),
			status: journalEntryHeader?.status?.code,
			modifiedDate: date.toISOString(),
			notes: journalEntryHeader?.description || '',
			archived:
				journalEntryHeader?.status?.code == VERSION_STATUS.ARCHIVED
					? true
					: false,
			costOfProductionVersion: type === SCHEMA_TYPE.RM ? copVersion : '',
			chartOfAccountsVersion: chartOfAccountsVersion,
			journalEntry: obj,
			isOfflineAdded: true,
			marketPriceList: type === SCHEMA_TYPE.RM ? marketPriceList : [],
		};

		const authInfo: AuthInfo = store.getState().auth;
		const year: string = journalEntryHeader?.accountingPeriod?.year;

		dispatch(
			saveOffline(
				record,
				producerIdentifier,
				year,
				type,
				authInfo?.userInfo?.mail
			)
		);
		dispatch(coaSyncedToDBSucces());
		dispatch(showMessage('Offline Synced Successfully.', MSG_TYPE.SUCCESS));
	};
};

// map data with schema COP/RM
export const mapDatawithSchema = (
	schema: GenericSchema,
	data: GenericSchema
) => {
	const schemaLineItem =
		schema?.showChartOfAccounts?.dataArea?.chartOfAccounts || [];
	const documentData = data?.journalEntry?.journalEntryLine || [];
	const documentHeader = data?.journalEntry?.journalEntryHeader;
	const typeCode = data?.journalEntry?.typeCode;

	schemaLineItem.forEach((lineItem: GenericSchema) => {
		documentData.forEach((document: GenericSchema) => {
			if (lineItem.identifier == document.lineNumberIdentifier) {
				lineItem.amount = document.amount;
				lineItem.note = document.note;
				document.lineNumberIdentifier >= 77 &&
				document.lineNumberIdentifier <= 80
					? (lineItem.description = document.description
							? document.description
							: '')
					: '';
				lineItem.extension.amount = document.extension.amount;
				lineItem.extension.measure = document.extension.measure;
			}
		});
	});

	schema.showChartOfAccounts.dataArea.chartOfAccounts = schemaLineItem;

	const BASIC_INFO = {
		id: data?.id,
		documentDateTime: documentHeader?.extension?.dateTime[1]?.content || '',
		typeCode: typeCode,
		identifier: documentHeader.identifier,
		revisionIdentifier: documentHeader.revisionIdentifier,
		documentReference: documentHeader.documentReference,
		status: documentHeader.status,
		accountingPeriod: documentHeader.accountingPeriod,
		party: documentHeader.party,
		extension: documentHeader.extension,
		description: documentHeader.description,
		note: documentHeader.note,
		variationIdentifier: documentHeader?.variationIdentifier,
	};
	return {
		id: data?.id,
		schema: schema,
		basicInfo: BASIC_INFO,
	};
};

// flattened schema for RM
const flattenedRMSchema = (schema: GenericSchema): Array<GenericSchema> => {
	let arr: Array<GenericSchema> = [];
	let LEVEL_1 = schema;
	for (const L1 in LEVEL_1) {
		const LEVEL_ARR_1 = LEVEL_1[L1];
		_.isArray(LEVEL_ARR_1) ? arr.push(LEVEL_ARR_1) : '';

		for (const L2 in LEVEL_1[L1]) {
			const LEVEL_ARR_2 = LEVEL_1[L1][L2];
			_.isArray(LEVEL_ARR_2) ? arr.push(LEVEL_ARR_2) : '';
		}
	}
	return _.chain(arr).flatMap().sortBy('identifier').value();
};
// flattened schema for COP
const flattenedSchema = (schema: GenericSchema): Array<GenericSchema> => {
	let arr: Array<GenericSchema> = [];
	let LEVEL_1 = schema;
	for (const L1 in LEVEL_1) {
		const LEVEL_ARR_1 = LEVEL_1[L1];
		_.isArray(LEVEL_ARR_1) ? arr.push(LEVEL_ARR_1) : '';

		for (const L2 in LEVEL_1[L1]) {
			const LEVEL_ARR_2 = LEVEL_1[L1][L2];
			_.isArray(LEVEL_ARR_2) ? arr.push(LEVEL_ARR_2) : '';

			for (const L3 in LEVEL_1[L1][L2]) {
				const LEVEL_ARR_3 = LEVEL_1[L1][L2][L3];
				_.isArray(LEVEL_ARR_3) ? arr.push(LEVEL_ARR_3) : '';

				for (const L4 in LEVEL_1[L1][L2][L3]) {
					const LEVEL_ARR_4 = LEVEL_1[L1][L2][L3][L4];
					_.isArray(LEVEL_ARR_4) ? arr.push(LEVEL_ARR_4) : '';

					for (const L5 in LEVEL_1[L1][L2][L3][L4]) {
						const LEVEL_ARR_5 = LEVEL_1[L1][L2][L3][L4][L5];
						_.isArray(LEVEL_ARR_5) ? arr.push(LEVEL_ARR_5) : '';
					}
				}
			}
		}
	}
	return _.chain(arr).flatMap().sortBy('identifier').value();
};

export {
	getCOASchema,
	getCOASchemaOffline,
	removeSelectedCOAScehma,
	saveCOAData,
	saveCOADataOffline,
	resetCOAData,
	updateResetCOAData,
	coaDataChanged,
	coaSyncedToDBSucces,
	entryFormError,
	coaUpdateCalculation,
	resetRMData,
	resetCOPData,
	updatePositionCalculation,
	updatePostionForMarketPrice,
	calculateMonthCardPosition,
	deletePositionFromPC,
	calculateALLCommodities,
	addNewPosition,
	movePositionDataFromDictionary,
	updateValidationErrorPosition,
	updateCollarNumberAndVolume,
};
