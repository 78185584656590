import { SIDEBAR_COLLAPSE, SIDEBAR_EXPAND } from '../actions/actions';

export enum MenuState {
	Expanded = 1,
	Collapse = 0,
}

const leftMenuReducer = (
	state = MenuState.Expanded,
	action: { type: string }
) => {
	switch (action.type) {
		case SIDEBAR_COLLAPSE:
			state = MenuState.Collapse;
			break;
		case SIDEBAR_EXPAND:
			state = MenuState.Expanded;
			break;
	}
	return state;
};

export { leftMenuReducer };
