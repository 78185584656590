import React, { FC, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import classes from '../../styles/layout/cards.module.scss';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';

interface Props extends RouteComponentProps {
	children: ReactNode | Element;
	onMenuSelected(code: string): void;
}

const CardThreedot: FC<Props> = (props: Props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event: any) => {
		handleClose('PRIOR_YEAR');
	};

	const handleClose = (code: string) => {
		setAnchorEl(null);
		props?.onMenuSelected(code);
	};

	return (
		<div data-testid="card-threedot">
			<span style={{ textAlign: 'right' }}>
				<Tooltip title="Prior Year" placement="left">
					<MoreVertIcon
						className={classes.iconcolor}
						onClick={handleClick}
					></MoreVertIcon>
				</Tooltip>
			</span>
		</div>
	);
};

export default withRouter(CardThreedot);
