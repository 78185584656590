import { Tooltip, withStyles, Theme } from '@material-ui/core';
import React from 'react';

const CustToolTip = withStyles((theme: Theme) => ({
	tooltip: {
		fontSize: 13,
	},
}))(Tooltip);

interface IProp {}
type Props = IProp & any;
function CustomToolTip(props: Props) {
	return <CustToolTip {...props} />;
}

export default CustomToolTip;
