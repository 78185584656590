import React from 'react';
import LogoRMI from '../../../../assets/images/logo-rmi-lockup.svg';
import classes from './footer.styles.module.scss';

export const Footer: React.FC = props => {
	return (
		<div className={classes.footerContainer}>
			<footer className={classes.imageContainer}>
				<img src={LogoRMI} alt="" className={classes.footerLogo} />
				<p className={classes.footerText}>
					&copy; 2021 Land O Lakes, Inc. All rights reserved
				</p>
			</footer>
		</div>
	);
};
