import { SELECT_PRODUCER_YEAR, CLEAR_PRODUCER_YEAR } from '../actions/actions';

const yearReducer = (
	state: { year: any } = { year: null },
	action: { type: string; payload: any }
) => {
	switch (action.type) {
		case SELECT_PRODUCER_YEAR:
			state = { ...state, year: action.payload };
			break;
		case CLEAR_PRODUCER_YEAR:
			state = { ...state, year: null };
			break;
	}
	return state;
};

export default yearReducer;
