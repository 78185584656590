import {
	createTheme,
	makeStyles,
	MuiThemeProvider,
	Theme,
	withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StylesProvider } from '@material-ui/styles';
import * as _ from 'lodash';
import React, { memo } from 'react';
import { Producer } from '../../../../schemas';

const CssTextField = withStyles({
	root: {
		borderTopLeftRadius: '0px',
		borderTopRightRadius: '10px',
		borderBottomLeftRadius: '0px',
		borderBottomRightRadius: '10px',
		MuiOutlinedInput: {
			root: {
				borderRadius: '0 !important',
			},
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'rgba(0,76,115, 0.3)',
			},
			'&:hover fieldset': {
				borderColor: '#808078',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#808078',
			},
		},
		'& .MuiInputBase-adornedStart': {
			minHeight: '40px',
		},
		input: {
			'&::placeholder': {
				color: 'red !important',
			},
		},
	},
})(TextField);

const useStyles = makeStyles((theme: any) => ({
	dropdownStyle: {
		selectedTextColor: 'white',
		margin: theme.spacing(0),
		border: '2px solid   #D1CCBD',
		borderRadius: '10%',
		backgroundColor: '#fff',
		boxShadow: ' 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);',
		color: '#808078',
		'&:before': {
			borderTop: '2px solid black',
			display: 'block',
			content: '',
		},
	},
	popupIndicator: {
		'& span': {
			'& svg': {
				'& path': {
					d: "path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z')",
					fill: '#782b79',
				},
			},
		},
	},
}));

const theme: Theme = createTheme({
	overrides: {
		MuiInputBase: {
			root: {
				width: '100%',
				lineHeight: '1.61876em',
			},
			input: {
				'&::placeholder': {
					fontSize: '14px',
				},
			},
			adornedStart: {
				minHeight: '60px',
			},
		},
		MuiSelect: {
			icon: {
				color: '#38383B',
				right: '8px',
			},
		},
		MuiFormControl: {
			fullWidth: {
				width: '100%',
			},
			root: {
				backgroundColor: '#fff',
				borderRadius: '0px 10px 10px 0px',
			},
		},
		MuiOutlinedInput: {
			root: {
				paddingLeft: '33px!important',
			},
		},
		MuiList: {
			padding: {
				paddingTop: '0px',
				paddingBottom: '0px',
			},
		},
		MuiListItem: {
			gutters: {
				paddingLeft: '26px',
				paddingRight: '0px',
			},

			root: {
				'&:hover': {
					backgroundColor: '#00B1AD ',
				},
				'&$selected': {
					backgroundColor: '#00B1AD ',
					color: '#fff',
				},
				selected: {},
			},
		},
	},
});

interface IProp {
	data: Array<Producer>;
	filterField: string;
	inputText: string;
	onSelect(producer: Producer): void;
	onInputChange(val: string): void;
}
type Props = IProp & any;
function Autodropdown(props: Props) {
	const classes = useStyles();
	const getLabel = (option: any, field: string) => {
		return field == 'farmId'
			? `${option['farmId']} - ${option['farmName']}`
			: option[field];
	};
	const uniqData = (data: Array<Producer>, field: string) => {
		return ['farmId', 'farmName'].includes(field)
			? data
			: _.chain(data)
					.uniqBy(field)
					.sortBy(function (item: any) {
						return item[field] ? item[field].toLowerCase() : '';
					})
					.value();
	};
	const getRecordNotFoundLabel = (field: string) => {
		let msg = '';
		switch (field) {
			case 'farmName':
				msg = 'Producer not found';
				break;
			case 'farmId':
				msg = 'FarmId not found';
				break;
			case 'milkshed':
				msg = 'Milkshed not found';
				break;
			case 'dairyBusinessAdvisor':
				msg = 'DBA not found';
				break;
			default:
				break;
		}
		return msg;
	};
	return (
		<MuiThemeProvider theme={theme}>
			<StylesProvider>
				<Autocomplete
					data-testid="autoComp"
					id="Searchproducer"
					options={uniqData(props?.data, props?.filterField) || []}
					classes={{
						popupIndicator: classes.popupIndicator,
					}}
					noOptionsText={getRecordNotFoundLabel(props?.filterField)}
					clearOnBlur={false}
					getOptionLabel={(option: any) => option[props?.filterField] || ''}
					renderOption={(option: any) => (
						<React.Fragment>
							{getLabel(option, props?.filterField)}
						</React.Fragment>
					)}
					onChange={(event: object, value: any, reason: string) => {
						if (['farmId', 'farmName'].includes(props?.filterField)) {
							props?.onSelect(value);
						}
					}}
					inputValue={props?.inputText || ''}
					onInputChange={(event: object, value: any, reason: string) => {
						props?.onInputChange(value);
					}}
					renderInput={(params: any) => (
						<CssTextField
							placeholder="Search"
							{...params}
							variant="outlined"
							InputProps={{
								...params.InputProps,
								startAdornment: <SearchIcon></SearchIcon>,
							}}
						/>
					)}
				/>
			</StylesProvider>
		</MuiThemeProvider>
	);
}

export default memo(Autodropdown);
