import { RM_TYPECODES } from '../common/constants';

export const EndorsementModel = () => {
	return {
		typeCode: RM_TYPECODES.ENDORSEMENT,
		identifierSet: {
			identifier: '3',
		},
		quantity: [
			{
				content: '0',
				typeCode: RM_TYPECODES.DECLARED_PROD_QTR_END,
			},
			{
				content: '0.00',
				typeCode: RM_TYPECODES.CLASS_3_FLOOR_PRICE,
			},
			{
				content: '0.00',
				typeCode: RM_TYPECODES.CLASS_4_FLOOR_PRICE,
			},
			{
				content: '0',
				typeCode: RM_TYPECODES.PERCENT_COVERED,
			},
			{
				content: '1.50',
				typeCode: RM_TYPECODES.PROTECT_FACT,
			},
			{
				content: '0',
				typeCode: RM_TYPECODES.PREMIUM_DOLLAR,
			},
			{
				content: '1.0000',
				typeCode: RM_TYPECODES.YIELD_ADJ,
			},
			{
				content: '0',
				typeCode: RM_TYPECODES.CLASS3_WEIGHT_FACTOR,
			},
			{
				content: '0',
				typeCode: RM_TYPECODES.CLASS4_WEIGHT_FACTOR,
			},
			{
				content: '0',
				typeCode: RM_TYPECODES.PREMIUM_PAID,
			},
		],
		extension: {
			typeCode: 'Endorsement Summary',
			amount: [
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_EXPECTED_REVENUE_GUARANTEE_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.BEGINNING_PROFITABLE_LEVEL_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_PRICE_WEIGHTED_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_PROBABLE_INDEMNITY_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MTM_WYF_ADJUSTMENT_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_EXPECTED_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.BEG_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_PRICE_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROS_PROB_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MTM_ADJ_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_TO_MARKET_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.NET_MTM_TOTAL,
				},
			],
			code: [],
			dateTime: '24-11-2021',
		},
	};
};

export const DRP_Quarterly_Endorsement_Summary = () => {
	return {
		typeCode: RM_TYPECODES.QUARTERLY_ENDORSEMENT_SUMMARY,
		identifierSet: {
			identifier: '4',
		},
		quantity: [],
		extension: {
			typeCode: '',
			amount: [
				{
					content: '0',
					typeCode: RM_TYPECODES.TOTAL_COVERED_PRODUCTION_QTR,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.TOTAL_COVERED_PRODUCTION_MO,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.TOTAL_COVERED_PRODUCTION_CLASS3,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.TOTAL_COVERED_PRODUCTION_CLASS4,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_EXPECTED_REVENUE_GUARANTEE_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_EXPECTED_REVENUE_GUARANTEE_MO_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_EXPECTED_REVENUE_GUARANTEE_QTR_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.BEGINNING_PROFITABLE_LEVEL_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.BEGINNING_PROFITABLE_LEVEL_MO_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.BEGINNING_PROFITABLE_LEVEL_QTR_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_PRICE_WEIGHTED_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_PRICE_WEIGHTED_MO_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_PRICE_WEIGHTED_QTR_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_TO_MARKET_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_TO_MARKET_MO_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MARKET_TO_MARKET_QTR_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.WEIGHT_AVERAGE_YIELD_ADJUSTMENT_FACTOR,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_PROBABLE_INDEMNITY_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_PROBABLE_INDEMNITY_QTR_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.GROSS_PROBABLE_INDEMNITY_MO_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MTM_WYF_ADJUSTMENT_CWT,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MTM_WYF_ADJUSTMENT_MO_TOTAL,
				},
				{
					content: '0',
					typeCode: RM_TYPECODES.MTM_WYF_ADJUSTMENT_QTR_TOTAL,
				},
			],
			code: [],
			datetime: '4-1-2020',
		},
	};
};
