import { updateObject } from '../../common/utils/utility';
import { ActionSchema, APIStatus, Error, MarketPriceInfo } from '../../schemas';
import {
	ADD_MARKET_PRICE,
	EDIT_MARKET_FINALSYMBOL_STATUS,
	EDIT_MARKET_PRICE,
	EDIT_MARKET_SETTLEMENT_DATE_MONTH,
	MARKET_FETCH_FAIL,
	MARKET_FETCH_START,
	MARKET_FETCH_SUCCESS,
	MARKET_PRICE_LIST,
	MARKET_PRICE_REMOVE_SELECTED,
	PREPARE_DATAFRAME_MARKET_PRICE,
	SAVE_MARKET_PRICE_COMPLETE,
	SAVE_MARKET_PRICE_ERROR,
	SAVE_MARKET_PRICE_STARTS,
	SAVE_MARKET_PRICE_STAUS_RESET,
} from '../actions/actions';

const initialState: MarketPriceInfo = {
	list: [],
	error: new Error(),
	loading: false,
	dataFrame: {},
	modifiedDictionary: {},
	isModified: false,
	saveAPIStatus: APIStatus.IDLE,
	saveResponseOrError: {},
	date: '',
};
const reducer = (state = initialState, action: ActionSchema) => {
	switch (action.type) {
		case MARKET_FETCH_START:
			return updateObject(state, {
				loading: true,
				date: action.payload
					? action.payload.date
						? action.payload.date
						: ''
					: '',
			});
		case MARKET_FETCH_SUCCESS:
			return updateObject(state, {
				loading: false,
			});
		case MARKET_FETCH_FAIL:
			return updateObject(state, {
				error: action.payload.error,
				loading: false,
			});
		case MARKET_PRICE_LIST:
			return updateObject(state, {
				list: [...action.payload],
				loading: false,
			});
		case MARKET_PRICE_REMOVE_SELECTED:
			return updateObject(state, {
				list: [],
				loading: false,
				dataFrame: {},
				modifiedDictionary: [],
				isModified: false,
			});
		case PREPARE_DATAFRAME_MARKET_PRICE:
			state.dataFrame = reduceMarketDataInfoSymbolAndMonthTable(action.payload);
			return state;
		case SAVE_MARKET_PRICE_STARTS:
			state.saveAPIStatus = APIStatus.LOADING;
			return state;
		case SAVE_MARKET_PRICE_COMPLETE:
			state.saveAPIStatus = APIStatus.COMPLETE;
			state.saveResponseOrError = action.payload;
			return state;
		case SAVE_MARKET_PRICE_STAUS_RESET: {
			const res = state.saveResponseOrError;
			if (res && Array.isArray(res)) {
				res.forEach(item => {
					if (item.statusCode == 'OK' && item.marketpricingData) {
						//`Saved ${item.marketpricingData.symbolCode} for ${item.marketpricingData.month}-${item.marketpricingData.year}`
						state.dataFrame[item.marketpricingData.symbolCode][
							item.marketpricingData.year + '_' + item.marketpricingData.month
						] = {
							...state.dataFrame[item.marketpricingData.symbolCode][
								item.marketpricingData.year + '_' + item.marketpricingData.month
							],
							isModified: false,
							...item.marketpricingData,
						};
						try {
							delete state.modifiedDictionary[
								item.marketpricingData.symbolCode +
									'_' +
									item.marketpricingData.year +
									'_' +
									item.marketpricingData.month +
									'_PRICE'
							];
							if (
								state.dataFrame['SETTLEDATE'][
									item.marketpricingData.year +
										'_' +
										item.marketpricingData.month
								]
							) {
								delete state.dataFrame['SETTLEDATE'][
									item.marketpricingData.year +
										'_' +
										item.marketpricingData.month
								];
							}
						} catch (e) {
							console.log(e);
						}
					}
				});
			}

			if (Object.keys(state.modifiedDictionary).length == 0) {
				state.isModified = false;
			}

			state.saveAPIStatus = APIStatus.IDLE;
			state = { ...state };
			return state;
		}
		case SAVE_MARKET_PRICE_ERROR:
			state.saveAPIStatus = APIStatus.ERROR;
			state.saveResponseOrError = action.payload;
			return state;
		case ADD_MARKET_PRICE:
			try {
				const maxDate = new Date(action.payload.date);
				const [year, month] = action.payload.rowKey.split('_');
				if (!state.dataFrame[action.payload.colKey]) {
					state.dataFrame[action.payload.colKey] = {};
				}
				state.dataFrame[action.payload.colKey][action.payload.rowKey] = {
					...state.dataFrame[action.payload.colKey][action.payload.rowKey],
					settlementPriceAmount: action.payload.price,
					settlementDate: `${maxDate.getFullYear()}-${(
						'0' +
						(maxDate.getMonth() + 1)
					).slice(-2)}-${('0' + maxDate.getDate()).slice(-2)}`,
					symbolCode: action.payload.colKey,
					isModified: true,
					year: year,
					month: month,
				};
				const key = `${action.payload.colKey}_${action.payload.rowKey}_PRICE`;
				state.modifiedDictionary = {
					...state.modifiedDictionary,
					[key]: action.payload,
				};
				state.isModified = true;
			} catch (e) {
				console.log('Unable to update state :', e);
			}
			return state;
		case EDIT_MARKET_PRICE:
			try {
				state.dataFrame[action.payload.colKey][action.payload.rowKey] = {
					...state.dataFrame[action.payload.colKey][action.payload.rowKey],
					settlementPriceAmount: action.payload.price,
					isModified: true,
				};
				const key = `${action.payload.colKey}_${action.payload.rowKey}_PRICE`;
				state.modifiedDictionary = {
					...state.modifiedDictionary,
					[key]: action.payload,
				};
				state.isModified = true;
			} catch (e) {
				console.log('Unable to update state :', e);
			}
			return state;
		case EDIT_MARKET_FINALSYMBOL_STATUS:
			try {
				if (!state.dataFrame[action.payload.colKey]) {
					state.dataFrame[action.payload.colKey] = {};
				}

				state.dataFrame[action.payload.colKey][action.payload.rowKey] = {
					symbolFinalPriceIndicator:
						action.payload.blnSymbolFinalPriceIndicator,
				};

				const key = `${action.payload.colKey}_${action.payload.rowKey}_STATUS`;
				state.modifiedDictionary = {
					...state.modifiedDictionary,
					[key]: action.payload,
				};
				state.isModified = true;
			} catch (e) {
				console.log('Unable to update state :', e);
			}
			return state;

		case EDIT_MARKET_SETTLEMENT_DATE_MONTH:
			try {
				if (!state.dataFrame[action.payload.colKey]) {
					state.dataFrame[action.payload.colKey] = {};
				}
				state.dataFrame[action.payload.colKey][action.payload.rowKey] = {
					settlementDate: action.payload.settlementDate,
				};

				const key = `${action.payload.colKey}_${action.payload.rowKey}_SETTLEDATE`;
				state.modifiedDictionary = {
					...state.modifiedDictionary,
					[key]: action.payload,
				};
				state.isModified = true;
			} catch (e) {
				console.log('Unable to update state :', e);
			}
			return state;
		default:
			return state;
	}
};

const reduceMarketDataInfoSymbolAndMonthTable = (array: Array<any>) => {
	let initialValue = {};
	return array.reduce((prev, item) => {
		const key_year_month = item.year + '_' + item.month;
		if (!prev[item.symbolCode]) {
			// adding the object for the first time for symbol
			prev[item.symbolCode] = {};
			prev[item.symbolCode][key_year_month] = item;
		} else if (prev[item.symbolCode][key_year_month]) {
			// already object exists check date and replace if latest available
			const oldItem = prev[item.symbolCode][key_year_month];
			const oldItemDate = new Date(oldItem.settlementDate);
			const currentItemDate = new Date(item.settlementDate);
			if (currentItemDate > oldItemDate) {
				prev[item.symbolCode][key_year_month] = item;
			}
		} else {
			// adding first time for year_month
			prev[item.symbolCode][key_year_month] = item;
		}
		return prev;
	}, initialValue);
};

export default reducer;
