import { COMMODITY_EXTENSION, SECTION_CODES } from '../../common/constants';
import {
	MULTI_YEAR_API_ADD_DATA,
	MULTI_YEAR_API_ADD_RESET,
	MULTI_YEAR_API_FETCH,
	MULTI_YEAR_API_SUCCESS,
} from '../actions/actions';

export type MultiYearCOPState = {
	loading: boolean;
	data: any | null;
	apiComplete: boolean;
};

const MultiYearPrintReducer = (
	state: MultiYearCOPState = { loading: false, data: null, apiComplete: false },
	action: { type: string; payload: any }
) => {
	switch (action.type) {
		case MULTI_YEAR_API_FETCH:
			delete state.data;
			state = { ...state, loading: true, data: {}, apiComplete: false };
			return state;
		case MULTI_YEAR_API_ADD_DATA: {
			const copyeardata = action.payload;
			const year =
				copyeardata[SECTION_CODES.BASIC_INFO][
					COMMODITY_EXTENSION.ACCOUNTING_PERIOD
				]['year'];
			state.data = { ...state.data, [year]: copyeardata };
			return state;
		}
		case MULTI_YEAR_API_SUCCESS:
			state = { ...state, loading: false, apiComplete: true };
			return state;
		case MULTI_YEAR_API_ADD_RESET:
			state = { loading: false, data: null, apiComplete: false };
			return state;
		default:
			return state;
	}
};

export default MultiYearPrintReducer;
