import {
	DRP_COMP_ENDORSEMENT,
	DRP_COMP_ENDORSEMENT_SUMMARY,
	DRP_COMP_QUARTERLY_SUMMARY,
	RM_TYPECODES
} from '../common/constants';

export const DRP_COMP_EndorsementModel = () => {
	return {
		typeCode: 'Endorsement',
		identifierSet: {
			identifier: '3',
		},
		quantity: [
			{
				content: new Date(),
				typeCode: DRP_COMP_ENDORSEMENT.Endorsement_Date,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.Declared_Production_QTR,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Butterfat,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Protein,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Other_Solids,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QMP_Non_Fat_Solids,
			},
			{
				content: '3.70',
				typeCode: DRP_COMP_ENDORSEMENT.DT_Butterfat,
			},
			{
				content: '3.05',
				typeCode: DRP_COMP_ENDORSEMENT.DT_Protein,
			},
			{
				content: '5.70',
				typeCode: DRP_COMP_ENDORSEMENT.DT_Other_Solids,
			},
			{
				content: '8.75',
				typeCode: DRP_COMP_ENDORSEMENT.DT_Non_Fat_Solids,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Butterfat,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Protein,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Other_Solids,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.QT_Non_Fat_Solids,
			},

			{
				content: '100',
				typeCode: DRP_COMP_ENDORSEMENT.Comp_Weight_factor,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.NonFt_BF_Wght,
			},
			{
				content: '1.40',
				typeCode: DRP_COMP_ENDORSEMENT.Protection_Factor,
			},
			{
				content: '95',
				typeCode: DRP_COMP_ENDORSEMENT.Percentage_Covered,
			},
			{
				content: '1',
				typeCode: DRP_COMP_ENDORSEMENT.Yield_Adj_Fact,
			},
			{
				content: '0',
				typeCode: DRP_COMP_ENDORSEMENT.Premium_Paid_Total,
			},
		],
		extension: {
			typeCode: 'Endorsement Summary',
			amount: [
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Total_Price_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Expected_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Beg_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Market_Price_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Prob_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.MTM_Adj_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Expected_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Beg_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Market_Price_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Gross_Prob_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.MTM_Adj_TOTAL,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Net_MTM_CWT,
				},
				{
					content: '0',
					typeCode: DRP_COMP_ENDORSEMENT_SUMMARY.Net_MTM_TOTAL,
				},
			],
			code: [],
			dateTime: '24-11-2021',
		},
	};
};

export const DRP_Comp_Quarterly_Endorsement_Summary = () => {
	return {
		typeCode: RM_TYPECODES.QUARTERLY_ENDORSEMENT_SUMMARY,
		identifierSet: {
			identifier: '3',
		},
		quantity: [],
		extension: {
			typeCode: '',
			amount: [
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.BEG_CWT,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.BEG_MONTH,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.BEG_QTR,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.GPI_CWT,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.GPI_MONTH,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.GPI_QTR,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.GROSS_EXPECTED_RG_CWT,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.GROSS_EXPECTED_RG_MONTH,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.GROSS_EXPECTED_RG_QTR,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MARKET_PRICE_WEIGHTED_CWT,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MARKET_PRICE_WEIGHTED_MONTHLY,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MARKET_PRICE_WEIGHTED_QTR,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MTM_ADJ_CWT,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MTM_ADJ_MONTH,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MTM_ADJ_QTR,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MTM_CWT,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MTM_MONTH,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.MTM_QTR,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.TOTAL_COVERED_PRODUCTION_MONTHLY,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.TOTAL_COVERED_PRODUCTION_QTR,
				},
				{
					content: '',
					typeCode: DRP_COMP_QUARTERLY_SUMMARY.WEIGHT_AVG_YIELD,
				},
			],
			code: [],
		},
	};
};
