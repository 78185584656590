import React, { FC, ReactNode } from 'react';
import classes from '../../styles/layout/cards.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { CardHeader, CardContent } from '../../hoc';

interface Props extends RouteComponentProps {
	children: ReactNode | Element;
}

const Card: FC<Props> = (props: Props) => {
	return (
		<>
			<div className={classes.cardbg}>{props.children}</div>
		</>
	);
};

export default withRouter(Card);
