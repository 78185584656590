import { Popper } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { store } from '../../../../store';
import classes from '../../../../styles/components/text-input.module.scss';
import { DEFAULT_LOCALE, INPUT_TYPE, TEXT_SIZE } from '../../../constants';
import { compareValues } from '../../../utils';
import { entryFormError } from './../../../../store/actions';

const PopperWrapper = styled(Popper)`
	&& {
		top: -3px !important;
	}
`;
const StyledPopper = styled.div`&&{
	color: white;
    height: 29px;
    width: 150px;
    border-radius: 3px;
    font-size: 10px;
    position: relative;
	text-align: center;
	padding:10px 10px 7px 7px;
	font-weight:bold;
    background: #00b4ae;
	::after{
	    content: '';
		position: absolute;
		left: 45%;
		padding: '10px', border:'1px solid';
		width: 0;
		height: 0;
		top:29px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		
	}`;
const ErrorPopper = styled(StyledPopper)`
	background: #ec4757;
	&::after {
		border-top: 8px solid #ec4757;
	}
`;
const EmptyPopper = styled(StyledPopper)`
	background: #00b4ae;
	&::after {
		border-top: 8px solid #00b4ae;
	}
`;

const getRandomID = () => {
	// this should not be done as it causes react to redraw these components
	// unique id should be given when inside a for loop or map function
	return '_' + Math.random().toString(36).substr(2, 9);
};

const INPUT_STATE = {
	FOCUSED: 'FOCUSED',
	ERROR: 'ERROR',
	VALID: 'VALID',
	NONE: '',
};

interface IProp extends RouteComponentProps<any> {
	value: string | number;
	locale: string;
	type: string;
	prefix: string;
	thousandSeparator: boolean;
	disabled: boolean;
	automationId?: string;
}
type Props = IProp & any;
class TextInput extends Component<Props, any> {
	inputElem: any;
	locale: string;
	inputType: string;
	thousandSeparator: boolean;
	prevValue: string;
	uID: string;
	size: any;
	constructor(props: Props) {
		super(props);
		const { value, locale, type, thousandSeparator, disabled } = props;
		this.state = {
			value: value,
			errorMsg: '',
			isError: false,
			curState: INPUT_STATE.NONE,
			disabled: disabled,
			closepop: true,
		};
		this.inputElem = props.inputElemref;
		this.locale = locale;
		this.inputType = type;
		this.thousandSeparator = thousandSeparator;
		this.prevValue = value;
		this.uID = getRandomID();
		this.size = type;
	}

	static defaultProps = {
		id: '',
		value: '',
		placeholder: '',
		automationId: '',
		locale: DEFAULT_LOCALE,
		type: INPUT_TYPE.TEXT,
		thousandSeparator: true,
		prefix: '',
		postfix: '',
		validation: null,
		disabled: false,
		size: TEXT_SIZE.SMALL,
		blnRoundingDefaultFalse: false,
	};

	static propTypes = {
		id: PropTypes.any.isRequired,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		placeholder: PropTypes.string,
		automationId: PropTypes.string,
		locale: PropTypes.string,
		type: PropTypes.string,
		thousandSeparator: PropTypes.bool,
		prefix: PropTypes.string,
		postfix: PropTypes.string,
		validation: PropTypes.object,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		onKeyUp: PropTypes.func,
		onTabKeyDown: PropTypes.func,
		onFocus: PropTypes.func,
		onClear: PropTypes.func,
		t: PropTypes.func,
		passInputReference: PropTypes.func,
		disabled: PropTypes.bool,
	};

	componentDidMount() {
		this.changeValue(undefined, true);
	}
	componentDidUpdate(prevProps: Props) {
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value }, () => {
				this.changeValue(undefined, this.state.closepop);
			});
		}
	}
	changeValue(event?: ChangeEvent, isFormUpdated?: boolean) {
		const el = this.inputElem;
		const value =
			this.inputType === INPUT_TYPE.TEXT_AREA
				? el.value
				: this.removeComma(el.value);

		// emit value to parent
		// auto resize input
		el.style.width = (value.length + 1) * 7.7 + 'px';
		this.setState({ value }, () => {
			// check for validation
			this.checkValid(!isFormUpdated);
		});
	}

	handleEnter(event: any) {
		if (event.key === 'Enter') {
			event.preventDefault();
			const currentNode = event.target;

			//find all tab-able elements
			const allElements: any = document.querySelectorAll(
				'input[type=text]:enabled, textarea'
			);

			//Find the current tab index.
			const currentIndex = [...allElements].findIndex(el =>
				currentNode.isEqualNode(el)
			);

			//focus the following element
			const targetIndex = (currentIndex + 1) % allElements.length;
			// const targetIndex = (currentIndex) % allElements.length;
			allElements[targetIndex].focus();
		} else {
			if (event.key === 'Tab' && this.props.onTabKeyDown) {
				this.props.onTabKeyDown(event);
			}
		}
	}

	handleBlur() {
		const el = this.inputElem;
		const values =
			this.inputType === INPUT_TYPE.TEXT_AREA
				? el?.value
				: this.removeComma(
						el?.value,
						this.props.prefix &&
							this.props.prefix === '$' &&
							!this.props.blnRoundingDefaultFalse
				  );
		this.setState(
			{
				value: values || this.inputType === INPUT_TYPE.TEXT_AREA ? values : '0',
			},
			() => {
				// check for validation
				this.checkValid();
			}
		);
		const { value } = this.state;
		if (value && value !== 0) {
			if (this.props?.onBlur) {
				// update the pop up previous value
				this.prevValue = this.props.value;
				// this.props.onBlur(value);
			}
			this.setState({ curState: INPUT_STATE.NONE, isError: true });
		} else {
			this.setState({ curState: INPUT_STATE.NONE, isError: false });
		}
	}

	clearValue() {
		this.inputElem.value = this.inputType === INPUT_TYPE.TEXT_AREA ? '' : '0';
		this.inputElem.focus();
		this.changeValue();
		this.props?.onInputError(null);
		if (this.props?.onClear) {
			this.props.onClear(this.inputElem.value);
		}
	}

	handleKeyPress() {
		if (this.props?.onKeyPress) {
			this.props.onKeyPress();
		}
	}

	handleKeyUp() {
		if (this.props?.onKeyUp) {
			this.props.onKeyUp(this.inputElem.value);
		}
	}

	handleFocus() {
		const el = this.inputElem;
		const value =
			this.inputType === INPUT_TYPE.TEXT_AREA
				? el.value
				: this.removeComma(el.value);
		if (!this.state.isError) {
			this.setState(
				{
					curState: INPUT_STATE.FOCUSED,
					isError: false,
					value:
						value.indexOf('(') === 0
							? value.replace('(', '-').replace(')', '')
							: value,
				},
				() => {
					this.inputElem.setSelectionRange(0, this.inputElem.value.length);
				}
			);
		}
		if (this.props?.onFocus) {
			this.props.onFocus();
		}
	}

	componentWillUnmount() {
		if (!this.state.isError) {
			this.props?.onInputError(null);
		}
	}

	checkValid(isChangeEvent?: boolean) {
		// validation test
		const { validation } = this.props;
		const { value } = this.state;
		const entryForm: any = store.getState()?.coa;
		const entryFormError: any = entryForm?.formError;

		if (validation && value.length > 0) {
			const regex = new RegExp(validation.REGEX);

			if (
				!regex.test(
					value.indexOf('.') === value.length - 1 ? value + '0' : value
				)
			) {
				this.setState({
					curState: INPUT_STATE.ERROR,
					isError: true,
					errorMsg: validation.MSG,
				});
				this.inputElem.focus();
				const errorInput = { elemID: this.inputElem?.id, status: true };
				if (!compareValues(entryFormError, errorInput)) {
					this.props?.onInputError(errorInput);
				}
			} else {
				this.setState({
					curState: INPUT_STATE.VALID,
					isError: false,
					errorMsg: '',
				});
				const errorInput = null;
				if (!compareValues(entryFormError, errorInput)) {
					this.props?.onInputError(errorInput);
				}
				// showing comma separated value for Number Field
				let changestateflag = false,
					statevalue = '';
				const getval = (value: any, maxfragment: any) => {
					return Number(value).toLocaleString(this.locale, {
						useGrouping: true,
						maximumFractionDigits: maxfragment,
					});
				};
				if (!isChangeEvent) {
					if (!isNaN(value) && this.thousandSeparator) {
						switch (this.inputType) {
							case INPUT_TYPE.NUMBER:
								statevalue = getval(value, 0);
								changestateflag = true;
								break;
							case INPUT_TYPE.DECIMAL:
								statevalue = getval(value, 2);
								changestateflag = true;
								break;
							case INPUT_TYPE.DECIMAL_4_PLACES:
								statevalue = getval(value, 4);
								changestateflag = true;
								break;
							case INPUT_TYPE.DECIMAL_3_PLACES:
								statevalue = getval(value, 3);
								changestateflag = true;
								break;
							case INPUT_TYPE.DECIMAL_2_PLACES:
								statevalue = getval(value, 3);
								changestateflag = true;
								break;
							case INPUT_TYPE.DECIMAL_1_PLACE:
								statevalue = getval(value, 1);
								changestateflag = true;
								break;
							case INPUT_TYPE.PERCENT:
								statevalue = getval(value, 2);
								changestateflag = true;
								break;
							default:
								statevalue = '0';
						}
					} else {
						if (this.inputType === INPUT_TYPE.PERCENT && !isNaN(value)) {
							statevalue = getval(value, 2);
							changestateflag = true;
						} else if (
							this.inputType !== INPUT_TYPE.TEXT_AREA &&
							isNaN(value)
						) {
							statevalue = '0';
							changestateflag = true;
						} else {
							if (this.props?.onBlur) {
								// emit value to parent
								this.props.onBlur(value);
							}
						}
					}

					if (changestateflag) {
						this.setState(
							{
								value:
									statevalue.indexOf('-') === 0
										? statevalue.replace('-', '(') + ')'
										: statevalue,
							},
							() => {
								if (this.props?.onBlur) {
									// emit value to parent
									this.props.onBlur(value);
								}
							}
						);
					}
				}
			}
		} else if (isChangeEvent) {
			this.setState({
				curState: INPUT_STATE.FOCUSED,
				isError: false,
			});
		} else {
			this.setState({
				curState: INPUT_STATE.NONE,
				isError: false,
				closepop: true,
			});
		}
	}

	removeComma(val: string, isRoundOff?: boolean) {
		if (
			(this.inputType === INPUT_TYPE.NUMBER ||
				this.inputType === INPUT_TYPE.DECIMAL ||
				// this.inputType === INPUT_TYPE.DECIMAL_4_PLACES || Commented to avoid round off 4 decimal input fields
				this.inputType === INPUT_TYPE.DECIMAL_1_PLACE ||
				this.inputType === INPUT_TYPE.PERCENT) &&
			this.thousandSeparator
		) {
			return isRoundOff && !isNaN(+val?.split(',').join(''))
				? String(Math.round(+val?.split(',').join('')))
				: val?.split(',').join('');
		}
		return isRoundOff && !isNaN(+val) ? String(+val) : val;
	}
	getStateClass(state: string) {
		let className = '';
		if (state === INPUT_STATE.FOCUSED) {
			className += classes.focused;
		} else if (state === INPUT_STATE.ERROR) {
			className += classes.error;
		} else if (state === INPUT_STATE.VALID) {
			className += classes.valid;
		}
		return className;
	}

	popup() {
		const { errorMsg, isError, curState, closepop } = this.state;
		const anchorEl = document.getElementById(this.uID);
		const isPrevVal =
			curState === INPUT_STATE.FOCUSED &&
			this.prevValue &&
			this.prevValue !== '0'
				? true
				: false;
		const isOpenPopup = isError || isPrevVal;

		return (
			<PopperWrapper
				open={isOpenPopup}
				placement="top"
				disablePortal={true}
				modifiers={{
					preventOverflow: {
						enabled: false,
						boundariesElement: 'scrollParent',
					},
					flip: { enabled: false },
					hide: { enabled: false },
				}}
				anchorEl={anchorEl}
			>
				{isError ? <ErrorPopper>Error : {errorMsg}</ErrorPopper> : ''}
				{isPrevVal ? (
					<EmptyPopper>Previous Value: {this.prevValue}</EmptyPopper>
				) : (
					''
				)}
			</PopperWrapper>
		);
	}
	render() {
		const {
			id,
			placeholder,
			prefix,
			postfix,
			disabled,
			automationId,
		} = this.props;
		const { value, curState } = this.state;
		const activeClass = this.getStateClass(curState);

		return (
			<>
				<div
					data-testid="input-text"
					id={this.uID}
					className={cx('input-group', classes.InputContainer)}
				>
					{this.popup()}
					{this.inputType === INPUT_TYPE.TEXT_AREA ? (
						<textarea
							id={getRandomID()}
							data-automate-id={automationId || ''}
							value={value}
							placeholder={placeholder}
							onChange={this.changeValue.bind(this)}
							onKeyPress={this.handleKeyPress.bind(this)}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							ref={input => {
								this.inputElem = input;
								if (this.props.passInputReference) {
									this.props.passInputReference(input);
								}
							}}
							className={cx('form-control', classes.textbox)}
							disabled={disabled}
							onKeyDown={this.handleEnter.bind(this)}
							onKeyUp={this.handleKeyUp.bind(this)}
						/>
					) : this.props.size === TEXT_SIZE.SMALL ? (
						<input
							id={getRandomID()}
							data-automate-id={automationId || ''}
							type="text"
							value={value}
							placeholder={placeholder}
							onChange={this.changeValue.bind(this)}
							onKeyPress={this.handleKeyPress.bind(this)}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							ref={input => {
								this.inputElem = input;
								// this.props?.passInputReference(input);
								if (this.props.passInputReference) {
									this.props.passInputReference(input);
								}
							}}
							className={cx('form-control')}
							disabled={disabled}
							onKeyDown={this.handleEnter.bind(this)}
							onKeyUp={this.handleKeyUp.bind(this)}
						/>
					) : (
						<input
							id={getRandomID()}
							data-automate-id={automationId || ''}
							type="text"
							value={value}
							placeholder={placeholder}
							onChange={this.changeValue.bind(this)}
							onKeyPress={this.handleKeyPress.bind(this)}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							ref={input => {
								this.inputElem = input;
								if (this.props.passInputReference) {
									this.props.passInputReference(input);
								}
							}}
							className="form-control"
							disabled={disabled}
							onKeyDown={this.handleEnter.bind(this)}
							onKeyUp={this.handleKeyUp.bind(this)}
						/>
					)}
					{/* <span
						className={cx(classes.curstate, activeClass, {
							[classes.left]: prefix,
						})}
					></span> */}
					<span className={classes.prefix}>{prefix}</span>
					{value && !disabled ? (
						<span
							className={classes.cross}
							onClick={this.clearValue.bind(this)}
						>
							<HighlightOffIcon />
						</span>
					) : (
						''
					)}
					{postfix ? (
						<div className="input-group-append">
							<span className="input-group-text">{postfix}</span>
						</div>
					) : (
						''
					)}
				</div>
			</>
		);
	}
}

TextInput.defaultProps = {
	id: '',
	value: '',
	placeholder: '',
	automationId: '',
	locale: DEFAULT_LOCALE,
	type: INPUT_TYPE.TEXT,
	thousandSeparator: true,
	prefix: '',
	postfix: '',
	validation: null,
	disabled: false,
	size: TEXT_SIZE.DEFAULT,
	blnRoundingDefaultFalse: false,
};
TextInput.propTypes = {
	id: PropTypes.any.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	automationId: PropTypes.string,
	locale: PropTypes.string,
	type: PropTypes.string,
	thousandSeparator: PropTypes.bool,
	prefix: PropTypes.string,
	postfix: PropTypes.string,
	validation: PropTypes.object,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyUp: PropTypes.func,
	onTabKeyDown: PropTypes.func,
	onFocus: PropTypes.func,
	onClear: PropTypes.func,
	passInputReference: PropTypes.func,
	t: PropTypes.func,
	disabled: PropTypes.bool,
};
const mapDispatchToProps = (dispatch: any) => {
	return {
		onInputError: (info: any) => dispatch(entryFormError(info)),
	};
};
export default connect(null, mapDispatchToProps)(TextInput);
