import { ClickAwayListener, MuiThemeProvider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { createTheme, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import cx from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import LogoRMI from '../../../assets/images/logo-rmi-lockup.svg';
import { GlobalState, User } from '../../../schemas';
import {
	logout,
	removeSelectedCOAScehma,
	removeSelectedMarketPrice,
} from '../../../store/actions';
import customcss from '../../../styles/components/header.module.scss';

const theme: Theme = createTheme({
	props: {
		MuiIconButton: {
			disableRipple: true,
		},
	},
	overrides: {
		MuiIconButton: {
			root: {
				'&:hover': {
					backgroundColor: 'transparent !important',
				},
			},
		},
	},
});
interface IProp extends RouteComponentProps {}
type Props = IProp;

function Header(props: Props) {
	let location = useLocation();
	const IS_COP = location.pathname.indexOf('/cop') > -1;
	const IS_RM = location.pathname.indexOf('/rm') > -1;
	const IS_COP_RM = IS_COP || IS_RM;
	const [auth] = useState(true);
	const [isMenuOpened, setToggleMenu] = useState(false);

	//state
	const dispatch = useDispatch();
	const userInfo: User = useSelector(
		(state: GlobalState) => state.auth.userInfo
	);

	const isOnline: boolean = useSelector(
		(state: GlobalState) => state.offline.online
	);

	const isCOPDataModified = useSelector(
		(state: GlobalState) => state.coa.isDataModified
	);

	const userInitials = (userInfo: any) => {
		if (typeof userInfo !== 'undefined' && userInfo != null) {
			let firstName = userInfo.fname;
			let lastName = userInfo.lname;
			var fisrtandLastName =
				firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

			return fisrtandLastName;
		}
		return null;
	};

	const onLogout = () => {
		dispatch(logout());
		props.history.push('/');
	};

	const price_update_click = () => {
		dispatch(removeSelectedMarketPrice());
		props.history.push('/priceedit');
	};

	const toggleMenu = (
		event: React.MouseEvent<HTMLParagraphElement, MouseEvent>
	) => {
		const menuOpened = !isMenuOpened;
		setToggleMenu(menuOpened);
	};

	const handleClickAway = () => {
		setToggleMenu(false);
	};

	const onNavigate = (url: string) => {
		if (IS_COP_RM && isCOPDataModified) {
			// toggleModal(true);
			props.history.push(url);
		} else {
			dispatch(removeSelectedCOAScehma());
			props.history.push(url);
		}
	};

	const isLoggedInUserAdmin = () => {
		if (userInfo.role && userInfo.role.toLowerCase() == 'admin') {
			return true;
		}
		return false;
	};

	return (
		<MuiThemeProvider theme={theme}>
			<div data-testid="header">
				<AppBar className={customcss.header} elevation={0}>
					<Toolbar className={customcss.toolBar}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								{location.pathname.length > 14 ? (
									<>
										<img
											src={LogoRMI}
											alt=""
											className={customcss.headerLogo}
											style={{ cursor: 'pointer' }}
											onClick={() => {
												onNavigate('/home/producer');
											}}
										/>
									</>
								) : (
									<img src={LogoRMI} alt="" className={customcss.headerLogo} />
								)}
							</Grid>
							<Grid item xs={4}></Grid>
							{auth && (
								<Grid item xs={4} className={customcss.alignRight}>
									<ClickAwayListener onClickAway={handleClickAway}>
										<div onClick={toggleMenu} className={customcss.profilename}>
											<span
												className={
													customcss.userInitials +
													' ' +
													cx(customcss.onlineindicator, {
														[customcss.offlineindicator]: !isOnline,
													})
												}
											>
												{userInitials(userInfo)}
											</span>
											{userInfo.fname} {userInfo.lname}
											{isMenuOpened ? (
												<span className={customcss.arrowup}></span>
											) : (
												<span className={customcss.arrowdown}></span>
											)}
											{isMenuOpened ? (
												<div className={customcss.profiledropdown}>
													<div>{userInfo.name}</div>
													<div>{userInfo.mail}</div>
													{isLoggedInUserAdmin() && (
														<div onClick={price_update_click}>
															Enter Pricing
														</div>
													)}
													<div onClick={onLogout}>LOG OUT</div>
												</div>
											) : (
												''
											)}
										</div>
									</ClickAwayListener>
								</Grid>
							)}
						</Grid>
					</Toolbar>
				</AppBar>
			</div>
		</MuiThemeProvider>
	);
}
export default withRouter(Header);
