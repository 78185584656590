import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { Component } from 'react';
import { errorLog } from '../../common/services';
import classes from '../../styles/layout/login.module.scss';
declare var window: any;
interface Props {
	children?: React.ReactChildren;
}
interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component {
	state: Readonly<State> = {
		hasError: false,
	};

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: any, info: any) {
		/* You can also change the `hasError` state to this method also
          this.setState({hasError: true})
     /* no need to use `getDerivedStateFromError */
		/* This information will be send into error reporting service */
		/* this.props.customErrorReportingService(error, info) */
		errorLog.log(error, info);
	}

	onReload = () => {
		window.location.href = '/';
	};

	render() {
		if (this.state.hasError) {
			return (
				<>
					<Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justifyContent="center"
						style={{ minHeight: '100vh' }}
					>
						<Grid item xs={6}>
							<div className={classes.authtext}>
								Something went wrong.Please try again.
								<Box display="flex" m={1} p={3} alignItems="center">
									<div className={classes.backbtn}>
										<button
											className={classes.errorbtn}
											onClick={() => this.onReload()}
										>
											HOME
										</button>
									</div>
								</Box>
							</div>
						</Grid>
					</Grid>
				</>
			);
		} else {
			return this.props.children;
		}
	}
}

export default ErrorBoundary;
