import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.jpg';
import { AUTH_CONFIG } from '../../common/configs';
import { ENVOIRMENT } from '../../common/constants';
import { getEnvoirment } from '../../common/utils';
import { GlobalState } from '../../schemas';
import classes from '../../styles/layout/login.module.scss';

interface Iprop {}
type Props = Iprop;
const Login = (props: Props) => {
	const dispatch = useDispatch();
	const isOnline: boolean = useSelector(
		(state: GlobalState) => state.offline.online
	);
	const onLogin = (e: React.MouseEvent) => {
		const ENV = getEnvoirment();
		let REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_LOCAL;
		if (ENV === ENVOIRMENT.PROD) {
			REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_PROD;
		} else if (ENV === ENVOIRMENT.QA) {
			REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_QA;
		} else if (ENV === ENVOIRMENT.DEV) {
			REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_DEV;
		}

		const url = `${AUTH_CONFIG.AUTHORIZATION}?client_id=${AUTH_CONFIG.CLIENT_ID}&response_type=${AUTH_CONFIG.RESPONSE_TYPE}&scope=${AUTH_CONFIG.SCOPE}&redirect_uri=${REDIRECT_URI}`;
		window.open(url, '_self');
	};
	return (
		<React.Fragment>
			<Grid container spacing={0} direction="row" alignItems="center">
				<Grid item xs={5} lg={5}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						m={1}
						p={1}
					>
						<Box display="flex" m={1} p={1}>
							<img className={classes.logo} src={logo} alt="logo" />
						</Box>
						<Box display="flex" m={1} p={1}>
							<h1 className={classes.title}>Risk Margin Insights</h1>
						</Box>
						<Box display="flex" m={1} p={1}>
							<div className={classes.submitbtn}>
								<button className={classes.orangebtn} onClick={onLogin}>
									Land O'Lakes Login
								</button>
							</div>
						</Box>
					</Box>
					<div className={classes.globebg}></div>
				</Grid>
				<Grid item xs={7} lg={7}>
					<div className={[classes.bgimage, classes.loginmore].join(' ')}></div>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default Login;
